<template>
    <div>
        <div class="top" style="z-index: 1000;">
            <div class="top-main">
                <ul>
                    <li>欢迎来到网站！</li>
                    <router-link tag="li" to="/myorder">
                        <div class="order"></div><span>我的订单</span>
                    </router-link>
                    <router-link tag="li" to="/mycollection">
                        <div class="collection"></div><span>我的收藏</span>
                    </router-link>
                    <li>
                        <span v-show="token">{{nickName}}</span>
                        <!-- <span v-if="token">shaihsaisndkaskjhdjk</span> -->
                        <router-link v-show='!token' tag="span" to="/login">登录</router-link>
                    </li>
                    <!-- <li>登录</li> -->
                    <li v-show="token" @click="out=true" style="height: 100%; line-height: 15px; border: 0px;">
                        <i class="el-icon-switch-button" style="color: #FF0000; width: 16px;
                        height: 18px; font-weight: bold;"></i>
                    </li>
                    <li v-show="token"></li>

                    <router-link tag="li" v-if="!token" to="/register">注册</router-link>
                </ul>
            </div>
        </div>
        <div class="tab" style="z-index: 1000;">
            <div class="tab-main">
                <router-link tag="div" class="logo" to="/"></router-link>
                <div class="find">
                    <div class="findimg">
                        <!-- <img src="../../assets/find.png" alt=""> -->
                    </div>
                    <!-- <input ref="find" type="text" v-model="keyword"  placeholder="请输入商品名称搜索"> -->
                    <el-form>
                        <el-form-item>
                            <el-input v-model="keyword" onkeypress="if(event.keyCode == 13) return false;"
                                @keyup.enter.native="onSubmit()" placeholder="请输入商品名称搜索"></el-input>
                        </el-form-item>
                    </el-form>

                </div>
                <div class="buycar" @click="getbuycart">
                    <div class="car"><img src="../../assets/car.png" alt="" sizes="" srcset=""></div>
                    <div class="cartext">购物车</div>
                </div>
            </div>
        </div>
        <!-- 购物券 -->
        <div class="coupon" style="z-index: 99999;" v-show="couponbox">
            <div class="close" @click="showOrHideMsg"></div>
            <img src="../../assets/shopp.png" style="width: 100%;height: 100%;" @click="couponVisible=true" alt="">
        </div>

        <!-- **************************中间部分******************************** -->
        <div class="main">
            <router-view>

            </router-view>
        </div>

        <!-- ******************************底部******************************** -->
        <div class="footer">
            <div class="download">
                <!-- 安卓 -->
                <div>
                    <div class="downloadimg">
                        <img src="../../assets/anzhuo.png" alt="" style="width:100%;height:100%">
                    </div>
                    <div class="text">安卓APP下载二维码</div>
                </div>

                <!-- 苹果 -->
                <div>
                    <div class="downloadimg">
                        <img src="../../assets/app.png" alt="" style="width:100%;height:100%">
                    </div>
                    <div class="text">IOSAPP下载二维码</div>
                </div>

                <!-- 微信小程序 -->
                <div>
                    <div class="downloadimg">
                        <img src="../../assets/xiaochengxu.jpg" alt="" style="width:100%;height:100%">
                    </div>
                    <div class="text">小程序二维码</div>
                </div>
            </div>

            <div class="category">
                <ul>
                    <li>种子</li>
                    <li>种苗</li>
                    <li>蔬菜</li>
                    <li>水果</li>
                    <li>农资</li>
                    <li>其他</li>
                </ul>
            </div>

            <!-- 备案号 -->
            <div class="record">
                <span>湖北紫园农业科技有限公司</span>
                <span>鄂ICP备2020020465号-1</span>
            </div>
        </div>

        <!-- 领取优惠券 -->
        <el-dialog :visible.sync="couponVisible" class="coupon1">
            <div class="cous">
                <div class="coupons-close" @click="couponVisible=false"></div>

                <div class="reject-title">购物优惠券领取</div>
                <div class="reject-line"></div>
                <div class="coupon-pice">
                    <div class="coupon-num" style="display: flex;" v-for="item in couponlist" :key="item.couponRuleId">
                        <div style="width: 100%; height: 100%;display: flex;">
                            <div class="coupon-left">
                                <img v-show="item.isTake==0" src="../../assets/blue.png" alt="">
                                <img v-show="item.isTake==1" src="../../assets/gray.png" alt="">
                            </div>
                            <div class="coupon-main" v-show="item.isTake==0">
                                <span>￥</span>{{item.couponAmount}}<span>元</span>
                            </div>
                            <div class="coupon-main" v-show="item.isTake==1"
                                style="background-color: rgb(204,204,204);">
                                <span>￥</span>{{item.couponAmount}}<span>元</span>
                            </div>
                            <div class="coupon-right">
                                <div class="coupon-name">
                                    {{item.couponRuleName}}
                                </div>

                                <div class="coupon-last">
                                    <div class="coupon-lastl">满{{item.fullAmount}}减{{item.couponAmount}}</div>
                                    <div class="coupon-lastr" v-show="item.isTake==0"
                                        @click="takeCoupon(item.couponRuleId,item.isTake)">
                                        立即领取
                                    </div>
                                    <div class="coupon-lastr" v-show="item.isTake==1" style="background-color: #999;">
                                        已领取
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="out" class="del">
            <div class="delbox">
                <div class="deltext">退出登录</div>
                <div class="delbtn">
                    <span @click="out = false">取消</span>
                    <span @click="(out = false), getout()">确定</span>
                </div>
            </div>
        </el-dialog>
    </div>

</template>
<script>
    export default {
        data() {
            return {

                couponVisible: false, //领取优惠券

                token: localStorage.getItem("token"),
                uid: localStorage.getItem("uid"),
                nickName: localStorage.getItem("nickName"),


                isShow: false,

                couponlist: [],//优惠券列表
                couponRuleId: '',//优惠券id


                keyword: '',//搜索关键字
                out: false,

                couponbox: true,
            };
        },
        methods: {
            showOrHideMsg() {
                this.couponbox = false
            },
            getbuycart() {
                if (!this.token) {
                    this.$message('请先登录');
                    this.$router.push('/login')
                } else {
                    this.$router.push('/buycart')
                }
            },
            // 领券列表
            getcouponRule() {

                this.$api.post({
                    path: "/user/couponRule",
                    params: {},
                    success: (data) => {
                        console.log(data, "领券列表")
                        this.couponlist = data.data.couponRule

                        console.log(data.data.couponRule, "11")

                        if (data.code == 0) {
                            this.$message({
                                message: data.msg,
                                type: 'warning'
                            });
                            window.localStorage.clear()
                            this.$router.replace('/indexDetails')
                        }
                    }
                })
            },

            // 领取领券
            takeCoupon(id, istake) {
                console.log(id, 111)
                console.log(istake, 111)
                if (this.token) {
                    if (istake == 0) {
                        this.$api.post({
                            path: '/user/takeCoupon',
                            params: {
                                couponRuleId: id,
                                uid: this.uid
                            },
                            success: (data) => {
                                console.log(data),
                                    this.$message({
                                        message: data.msg,
                                        type: 'success'
                                    });

                                this.getcouponRule()
                                if (data.code == 0) {
                                    this.$message({
                                        message: data.msg,
                                        type: 'warning'
                                    });
                                    window.localStorage.clear()
                                    this.$router.push('/indexDetails')
                                }
                            }
                        })
                    }

                } else {
                    this.$message('请登录');
                }
                // this.getcouponRule()
            },

            // 搜索
            onSubmit() {
                console.log(this.$route.path)
                // this.$router.push(`/find`)
                if (this.$route.path != `/find/${this.keyword}`) {
                    this.$router.push(`/find/${this.keyword}`)
                }

                this.getfind()

            },
            getfind() {
                this.$api.post({
                    path: '/goods/searchGoods',
                    params: {
                        keyword: this.keyword,
                        pageNum: 1,
                        pageSize: 10
                    },
                    success: (data) => {
                        console.log(data)
                        localStorage.setItem('findgoodslist', JSON.stringify(data.data.Goods))
                        this.$router.go(0)
                    }
                })
            },

            getout() {
                this.$api.post({
                    path: '/myorder/loginOut',
                    params: {
                        uid: this.uid
                    },
                    success: (data) => {
                        console.log(data)
                        if (data.code == 1) {
                            this.$message({
                                showClose: true,
                                message: '退出成功',
                                type: 'success'
                            })
                            window.localStorage.clear()

                            this.$router.replace('/indexDetails')
                            this.$router.go('/indexDetails');
                        } else if (data.code == 0 && data.msg == '会话已过期') {
                            this.$router.replace('/indexDetails')
                            this.$router.go('/indexDetails');
                            localStorage.removeItem('token')
                            localStorage.removeItem('uid')
                            localStorage.removeItem('nickName')
                            localStorage.removeItem('avatar')
                        }
                    }
                })
            }
        },

        mounted() {
            // this.getshow()
            this.getcouponRule()
        },

        created() {
            // this.getfind()
        },

    };
</script>

<style lang="scss">
    * {
        cursor: default
    }

    .top {
        width: 100%;
        height: 40px;
        background: #f2f2f2;
        position: fixed;
        top: 0;
        left: 0;

    }



    .cous {
        width: 600px;
        height: 570px;
        position: relative;
        border-radius: 10px;
        background-color: white;
        margin: 0 auto;
        padding-top: 30px;
    }

    .coupons-close {
        width: 34px;
        height: 34px;
        background-image: url(../../assets/coupons-close.png);
        background-size: 100%;
        position: absolute;
        top: -50px;
        right: 0px;

    }

    .reject-title {
        font-size: 20px;
        font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
        font-weight: bold;
        text-align: center;
        color: #1a1a1a;
    }

    .reject-line {
        width: 30px;
        height: 6px;
        /* opacity: 1; */
        background: #2f69d2;
        margin: 10px auto;
        margin-bottom: 23px;
    }

    .coupon-pice {
        height: 505px;
        padding: 0 109px;
        /* display: flex; */
        /* flex-direction: column; */
        overflow-y: scroll;
    }

    /* 滚动条整体部分 */
    .coupon-pice::-webkit-scrollbar {
        width: 4px;
        height: 100%;
    }

    /* 轨道背景色 */
    .coupon-pice::-webkit-scrollbar-track {

        background-color: white;

    }

    /* 滚动条的样式 */
    .coupon-pice::-webkit-scrollbar-thumb {

        width: 4px;
        height: 100px;
        background: #2f69d2;
        border-radius: 1px;
    }

    .coupon-num {
        width: 380px;
        height: 88px;
        /* background-color: thistle; */
        margin-bottom: 20px;
    }

    .coupon-main {
        width: 105px;
        height: 100%;
        /* padding: 34px 21px 34px 11px; */
        /* padding-left: 8px; */
        background-color: #2f69d2;
        font-size: 24px;
        color: white;
        font-weight: 600;
        line-height: 88px;
        text-align: center;
    }

    .coupon-main span:first-child {
        font-size: 24px;
    }

    .coupon-main span:last-child {
        font-size: 14px;
        font-weight: 400;
    }

    .coupon-left {
        width: 15px;
        height: 100%;
        /* background-image: url(../../assets/blue.png); */
        background-size: 100%;
    }

    .coupon-left img {
        width: 100%;
        height: 100%;
    }

    .coupon-right {
        width: 235px;
        height: 68px;
        padding: 10px 10px 10px 15px;
        background: #f2f2f2;
    }

    .coupon-name {
        width: 100%;
        height: 18px;
        font-size: 16px;
        font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
        font-weight: bold;
        text-align: left;
        color: #1a1a1a;
        margin-bottom: 25px;
    }

    .coupon-last {
        width: 100%;
        height: 26px;
        display: flex;
    }

    .coupon-lastl {
        width: 70%;
        height: 100%;
        font-size: 14px;
        font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
        font-weight: bold;
        text-align: left;
        color: #ff0000;
        line-height: 37px;
        /* background-color: white; */
    }

    .el-dialog__headerbtn .el-dialog__close {
        color: transparent;
    }

    .coupon-lastr {
        width: 68px;
        height: 26px;
        background: #2f69d2;
        border-radius: 13px;
        font-size: 12px;
        font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
        font-weight: bold;
        text-align: center;
        line-height: 26px;
        color: #ffffff;
    }

    .el-dialog {
        background-color: transparent;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);

    }

    .determine {
        width: 110px;
        height: 55px;
        position: absolute;
        bottom: 85px;
        left: 100px;
    }

    .integral .el-dialog__headerbtn {
        top: 92px;
        right: 332px;
        z-index: 999;
        color: white;
    }

    .coupon1 .el-dialog__headerbtn {
        top: 19px;
        right: 172px;
        z-index: 999;
        /* color: white; */
    }

    .determine div:first-child {
        width: 100%;
        font-size: 26px;
        font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
        margin-bottom: 10px;
    }

    .determine div:last-child {
        width: 100%;
        /* height: 17px; */
        font-size: 18px;
        font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
    }

    .determine div:last-child i {
        display: inline-block;
        margin: 0 8px 0 5px;
        color: #FFF20D;
    }


    .determinebtn {
        width: 145px;
        height: 40px;
        background-image: url(../../assets/definebtn.png);
        background-size: 100%;
        position: absolute;
        left: 80px;
        bottom: 15px;

    }

    .coupon {
        width: 141px;
        height: 142px;
        background-image: url(../../assets/shopp.png);
        background-size: 100%;
        position: fixed;
        right: 155px;
        top: 510px;
        /* border: 1px solid thistle; */
    }

    .close {
        position: absolute;
        width: 22px;
        height: 22px;
        right: -11px;
        top: -11px;
        background-image: url(../../assets/close.png);
        background-size: 100%;
    }

    .top-main {
        width: 1200px;
        height: 100%;
        margin: 0 auto;
        /* background-color: thistle; */
        line-height: 40px;
    }

    .top li {
        list-style: none;
        /* height: 14px; */
        float: left;
        font-size: 14px;
        font-family: PingFang SC Medium, PingFang SC Medium-Medium;
        font-weight: 500;
        /* text-align: left; */
        color: #000000;
    }

    .top li div {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-right: 7px;
    }

    .top li:nth-child(1) {
        margin-right: 700px;
    }

    .top li:nth-child(2),
    .top li:nth-child(3) {
        margin-right: 30px;
    }

    .top li:nth-child(4) {
        padding-right: 20px;


    }

    .top li:nth-child(5) {
        height: 15px;
        border-right: 1px solid black;
        margin-top: 13px;
    }

    .top li:nth-child(6) {
        padding-left: 20px;
        /* border-right: 1px solid black; */

    }



    .tab {
        width: 100%;
        /* height: 80px; */
        border-bottom: 2px solid #2f69d2;
        /* padding-top: 18px;/ */
        background-color: white;
        margin-bottom: 10px;
        position: fixed;
        left: 0;
        top: 40px;

    }

    .tab-main {
        width: 1200px;
        height: 45px;
        margin: 18px auto;
        display: flex;
        /* background-color: thistle; */

    }

    .tab-main .el-input__inner {
        line-height: 21px;
    }

    .logo {
        /* display: inline-block; */
        width: 385px;
        height: 100%;
        background-image: url(../../assets/logo.png);
        background-size: 100%;
        margin-right: 300px;
    }

    .find {
        /* display: inline-block; */
        width: 400px;
        /* height: 40px; */
        border: 1px solid #cccccc;
        border-radius: 20px;
        margin-right: 35px;
        padding: 11px 0;
        display: flex;
    }

    .find .el-form-item__content {
        line-height: 20px;
    }

    .findimg {
        width: 18px;
        height: 18px;
        margin-left: 30px;
        /* display: inline-block; */
        margin-right: 17px;
        /* margin-top: 11px; */
        background-image: url(../../assets/find.png);
        background-size: 100%;
    }

    /* .findimg img{
        width: 18px;
        height: 18px;
        margin: 11px auto;
    } */
    .find input {
        height: 100%;
        background: none;
        outline: none;
        border: 0px;
    }

    .find input::-webkit-input-placeholder {
        /* placeholder颜色  */
        color: #cccccc;
        /* placeholder字体大小  */
        font-size: 14px;
        /* placeholder位置  */
        /* text-align: right; */
    }

    .buycar {
        width: 75px;
        height: 100%;
        /* display: inline-block; */
        display: flex;
    }

    .car {
        /* display: inline-block; */
        width: 20px;
        height: 100%;
        margin-right: 10px;
    }

    .car img {
        width: 20px;
        height: 20px;
        margin: 13px auto;
    }

    .cartext {
        width: 45px;
        height: 100%;
        /* display: inline-block; */
        line-height: 45px;
        font-size: 14px;
        font-family: PingFang SC Medium, PingFang SC Medium-Medium;
        font-weight: 500;
        /* text-align: left; */
        color: #1a1a1a;
    }

    .main {
        width: 1200px;
        margin: 0 auto;
    }

    .footer {
        width: 100%;
        height: 360px;
        background-color: rgb(234, 240, 250);
        border-top: 4px solid #2f69d2;
    }

    .download {
        width: 540px;
        /* height: ; */
        margin: 40px auto;

        display: flex;
        justify-content: space-between;

        margin-bottom: 72px;
    }

    .download>div {
        width: 140px;
    }

    .downloadimg {
        width: 140px;
        height: 140px;
        /* border: 1px solid black; */
        margin-bottom: 13px;
    }

    .download .text {
        width: 100%;
        font-size: 14px;
        font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
        font-weight: 400;
        text-align: center;
        color: #1a1a1a;
    }

    .category {
        width: 490px;
        height: 14px;
        margin: 0 auto;
        margin-bottom: 12px;
    }

    .category li {
        width: 90px;
        height: 14px;
        list-style: none;
        line-height: 14px;
        float: left;
        font-size: 14px;
        font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
        font-weight: 400;
        text-align: center;
        color: #1a1a1a;
        border-right: 1px solid black;
    }

    .category li:first-child {
        text-align: left;
        width: 60px;
    }

    .category li:last-child {
        text-align: right;
        width: 60px;
        border-right: 1px solid transparent;
    }

    .record {
        width: 1200px;
        height: 50px;
        margin: 0 auto;
        border-top: 1px solid #cccccc;
        /* background-color: thistle; */
        font-size: 14px;
        line-height: 50px;
        font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
        font-weight: 400;
        text-align: center;
        color: #1a1a1a;
    }

    .record span {
        display: inline-block;
        width: 48%;
    }

    .record span:first-child {
        text-align: right;
        padding-right: 10px;
    }

    .record span:last-child {
        text-align: left;
        padding-left: 10px;
    }


    .cou {
        width: 380px;
        height: 88px;
        /* background-color: thistle; */
        margin-bottom: 20px;
        margin-right: 10px;
        display: flex;
    }

    .cou:nth-child(3n) {
        margin-right: 0px;
    }

    .cou-main {
        width: 105px;
        height: 100%;
        /* padding: 34px 21px 34px 11px; */
        /* padding-left: 8px; */
        background-color: #2f69d2;
        font-size: 24px;
        color: white;
        font-weight: 600;
        line-height: 88px;
        text-align: center;
    }

    .cou-main span:first-child {
        font-size: 24px;
    }

    .cou-main span:last-child {
        font-size: 14px;
        font-weight: 400;
    }

    .cou-left {
        width: 15px;
        height: 100%;
        /* background-image: url(../../assets/blue.png); */
        background-size: 100%;
    }

    .cou-left img {
        width: 100%;
        height: 100%;
    }

    .cou-right {
        width: 235px;
        height: 68px;
        padding: 10px 10px 10px 15px;
        background: #f2f2f2;
    }

    .cou-name {
        width: 100%;
        height: 18px;
        font-size: 16px;
        font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
        font-weight: bold;
        text-align: left;
        color: #1a1a1a;
        margin-bottom: 18px;
    }

    /* .cou-last {
        width: 100%;
        height: 26px;
        display: flex;
    } */

    .cou-lastl {
        width: 70%;
        /* height: 100%; */
        font-size: 14px;
        font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
        font-weight: bold;
        text-align: left;
        color: #ff0000;
        /* line-height: 37px; */
        /* background-color: white; */
    }

    .cou-time {
        width: 70%;
        font-size: 14px;
        font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
        font-weight: Normal;
        text-align: left;
        color: #1a1a1a;
    }
</style>