import { render, staticRenderFns } from "./abolish.vue?vue&type=template&id=5ba2ffbe&"
import script from "./abolish.vue?vue&type=script&lang=js&"
export * from "./abolish.vue?vue&type=script&lang=js&"
import style0 from "./abolish.vue?vue&type=style&index=0&id=5ba2ffbe&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.10.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports