<!-- 登录 -->
<template>
  <div class="loginbox">
    <div class="login">
      <div class="login-top">
        <router-link tag="div" class="loginl" to="/login">登录
          <div></div>
        </router-link>
        <!-- <div class="loginl">
                </div> -->
        <router-link tag="div" class="loginr" to="/register">注册 </router-link>
        <!-- <div class="loginr">注册</div> -->
      </div>
      <div class="loginmain">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
          <el-form-item label="手机号" prop="phonenum">
            <el-input v-model="ruleForm.phonenum"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="pass">
            <el-input type="password" v-model="ruleForm.pass"></el-input>
          </el-form-item>

          <router-link tag="p" to="/forget">忘记密码</router-link>
          <!-- <p>忘记密码</p> -->

          <!-- <el-form-item size="large">
            <el-button type="primary" @click="onSubmit">登录</el-button>
          </el-form-item> -->

          <div class="loginbtn" @click="onSubmit">
            登录
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        ruleForm: {
          phonenum: "",
          pass: "",
        },
        rules: {
          phonenum: [
            { required: true, message: "请输入手机号码", trigger: "blur" },
            { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'change' }
          ],
          pass: [{ required: true, message: "请输入密码", trigger: "blur" }],
        },

        address: [],
        provinceName: '',
        cityName: '',
        districtName: '',
        streetName: "",
        token: ''
      };
    },
    methods: {
      onSubmit() {
        console.log("submit!");
        this.$api.post({
          path: "/user/userLogin",
          params: {
            phone: this.ruleForm.phonenum,
            pwd: this.ruleForm.pass
          },
          success: (data) => {
            console.log(data)
            if (data.code == 1) {
              this.$message({
                message: data.msg,
                type: 'success'
              });
              this.provinceName = data.data.provinceName
              this.cityName = data.data.cityName
              this.districtName = data.data.districtName
              this.streetName = data.data.streetName

              this.address.push(this.provinceName, this.cityName, this.districtName, this.streetName)

              console.log(this.address);
              localStorage.setItem("token", data.data.token);
              localStorage.setItem("uid", data.data.uid);
              localStorage.setItem("nickName", data.data.nickName);
              localStorage.setItem("avatar", data.data.avatar);
              localStorage.setItem("registerUrl", data.data.registerUrl)
              localStorage.setItem('shareCode', data.data.shareCode)
              localStorage.setItem("address", this.address.join(""))

              // this.token = data.data.token
              this.$router.push({ path: "/" });
            } else {
              this.$message({
                message: data.msg,
                type: 'error'
              });
            }
          },
        });


      },
    },
  };
</script>



<style lang="scss">
  .loginbox {
    width: 100%;
    height: 457px;
    padding-top: 155px;
  }

  .login {
    width: 350px;
    height: 316px;
    background: #ffffff;
    border-radius: 10px;
    padding: 30px 80px 0px 80px;
    margin: 0 auto;
  }

  .login-top {
    height: 30px;
    width: 200px;
    display: flex;
    /* border: 1px solid black; */
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .loginl {
    width: 50%;
    height: 100%;
    font-size: 18px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    font-weight: bold;
    text-align: center;
    color: #2f69d2;
  }

  .loginl div {
    width: 20px;
    height: 3px;
    border-bottom: 4px solid #2f69d2;
    margin: 0 auto;
  }

  .loginr {
    width: 50%;
    height: 100%;
    font-size: 18px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    /* font-weight: bold; */
    text-align: center;
  }

  .loginmain {
    width: 100%;
    height: 88px;
  }

  .loginmain .el-input__inner {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-radius: 0px;
  }

  .loginmain .el-form-item__label {
    color: #999999;
    padding: 0 20px 0 0;
  }

  .loginmain .el-button {
    width: 240px;
    height: 42px;
    margin-top: 60px;
    /* margin: 0 auto; */
  }

  .loginbox p {
    width: 100%;
    font-size: 14px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: 400;
    text-align: right;
    color: #2f69d2;
  }

  .loginbtn {
    width: 240px;
    height: 42px;
    background: #2f69d2;
    border-radius: 4px;
    font-size: 18px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: bold;
    color: white;
    text-align: center;
    line-height: 42px;
    margin: 55px auto;
  }
</style>