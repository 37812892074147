<template>
  <div class="Pbox">
    <div class="navigation">
      <div class="home"></div>
      <div class="current">当前页面:</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/pmessage">个人中心</el-breadcrumb-item>
        <el-breadcrumb-item>预付订单</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="pmain">
      <div class="pmainL">
        <ul>
          <router-link to="pmessage" tag="li">个人信息</router-link>
          <!-- <router-link to="myorder" tag="li">我的订单</router-link> -->
          <router-link to="myorder" tag="li">我的订单</router-link>
          <router-link to="advanceorder" class="liactive" style="color: white" tag="li">预付订单</router-link>
          <router-link to="mycollection" tag="li">我的收藏</router-link>
          <router-link to="myaddress" tag="li">我的地址</router-link>
          <router-link to="platform" tag="li">平台介绍</router-link>
          <router-link to="install" tag="li">设置</router-link>
        </ul>
      </div>
      <div class="myorderbox">
        <div class="myorder">
          <div class="order-type">
            <div :class="[
                'order-type-item',
                advanceorder.status == '' ? 'active' : '',
              ]" @click="getadvanceType('')">
              全部
            </div>
            <div :class="[
                'order-type-item',
                advanceorder.status == '待支付' ? 'active' : '',
              ]" @click="getadvanceType('待支付')">
              待支付
            </div>
            <div :class="[
                'order-type-item',
                advanceorder.status == '待生产' ? 'active' : '',
              ]" @click="getadvanceType('待生产')">
              待生产
            </div>
            <div :class="[
                'order-type-item',
                advanceorder.status == '待结款' ? 'active' : '',
              ]" @click="getadvanceType('待结款')">
              待结款
            </div>
            <div :class="[
                'order-type-item',
                advanceorder.status == '待发货' ? 'active' : '',
              ]" @click="getadvanceType('待发货')">
              待发货
            </div>
            <div :class="[
                'order-type-item',
                advanceorder.status == '待收货' ? 'active' : '',
              ]" @click="getadvanceType('待收货')">
              待收货
            </div>
            <div :class="[
                'order-type-item',
                advanceorder.status == '待评价' ? 'active' : '',
              ]" @click="getadvanceType('待评价')">
              待评价
            </div>
            <div :class="[
                'order-type-item',
                advanceorder.status == '已完成' ? 'active' : '',
              ]" @click="getadvanceType('已完成')">
              已完成
            </div>
            <div :class="[
                'order-type-item',
                advanceorder.status == '已取消' ? 'active' : '',
              ]" @click="getadvanceType('已取消')">
              已取消
            </div>
          </div>
          <table class="yforderlist">
            <tr style="height: 48px; background-color: #f6f6f6; font-size: 16px">
              <th style="width: 100px">订单编号</th>
              <th style="width: 220px">商品信息</th>
              <th style="width: 70px">单价</th>
              <th style="width: 70px">运费</th>

              <th style="width: 75px">预付金额</th>
              <th style="width: 90px">尾款</th>
              <th style="width: 100px">总金额</th>
              <th style="width: 75px">改价金额</th>
              <th style="width: 80px">状态</th>
              <th style="width: 100px">操作</th>
            </tr>
            <tr>
              <td colspan="10" v-if="advanceList.length == 0" style="border-right: 1px solid #ccc; height: 40px">
                {{ aData }}
              </td>
            </tr>
            <tr v-for="item in advanceList" :key="item.oid">
              <td>{{ item.orderNo }}</td>
              <td style="display: flex; padding-left: 20px">
                <div style="
                    width: 70px;
                    height: 70px;
                    margin-right: 20px;
                    margin-top: 5px;
                  ">
                  <img style="width: 100%; height: 100%" :src="item.orderGoodsList[0].coverImg" alt="" />
                </div>
                <div class="goodsnums" style="
                    height: 100%;
                    line-height: 80px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: 117px;
                    padding-right: 20px;
                  ">
                  {{ item.orderGoodsList[0].goodsName }}
                </div>
              </td>
              <td style="color: red">{{ item.orderGoodsList[0].price }}</td>
              <td>{{ item.orderFreight }}</td>

              <td style="color: red">{{ item.depositAmount + item.orderFreight -item.pointAmount - item.couponAmount }}
              </td>
              <td style="color: red">
                {{ (item.orderAmount - item.depositAmount -item.orderFreight ) | numFilter }}
              </td>
              <td style="color: red">{{ item.payAmount }}</td>
              <td>{{ item.changePrice }}</td>
              <td>
                <div style="color: #2f69d2">{{ item.status }}</div>
                <p v-show="item.refundStatus == 1" @click="getrefundId(item.refundId), (shouhou = true)">
                  申请中
                </p>
                <p v-show="
                    item.refundStatus == 2 &&
                    item.status !== '待生产' &&
                    item.status !== '待发货'
                  " @click="getrefundId(item.refundId)">
                  退货中
                </p>
                <p v-show="item.refundStatus == 3" @click="getrefundId(item.refundId), (shouhous = true)">
                  退款完成
                </p>
                <p v-show="item.refundStatus == 4" @click="getrefundId(item.refundId), (shouhout = true)">
                  退款失败
                </p>
              </td>
              <td>
                <div v-if="item.status == '待生产'">
                  <p 
                   v-if="item.refundStatus == 0 || item.refundStatus == 4"
                  @click="
                  getshouh(item.oid,item.isEditPrice,item.orderGoodsList[0].orderGoodsId)
                        
                    ">
                    售后退款
                  </p>
                  <div @click="getOrderid(item.oid)">查看详情</div>
                </div>
                <div v-if="item.status == '待收货'">
                  <p style="color: #2f69d2" @click="getdelorder(item.oid), (receiving = true)">
                    确认收货
                  </p>
                  <p @click="fapiao = true">申请开票</p>
                  <div @click="getOrderid(item.oid)">查看详情</div>
                </div>
                <div v-if="item.status == '待支付'">
                  <p style="color: #2f69d2"
                    @click="pay(item.oid, item.depositAmount + item.orderFreight - item.pointAmount - item.couponAmount,item.changePrice,item.isEditPrice)">
                    立即支付
                  </p>
                  <p @click="(cancel = true), getdelorder(item.oid)">
                    取消订单
                  </p>
                  <div @click="getOrderid(item.oid)">查看详情</div>
                </div>
                <div v-if="item.status == '待结款'">
                  <p style="color: #2f69d2" @click="pay1(item.oid, item.orderAmount - item.depositAmount)">
                    立即结款
                  </p>
                  <div @click="getOrderid(item.oid)">查看详情</div>
                </div>
                <div v-if="item.status == '待发货'">
                  <p @click="fapiao = true">申请开票</p>
                  <div @click="getOrderid(item.oid)">查看详情</div>
                </div>
                <div v-if="item.status == '待收款'">
                  <p style="color: #2f69d2" @click="getdelorder(item.oid), (receiving = true)">
                    确认收货
                  </p>
                  <div @click="fapiao = true">申请开票</div>
                  <p @click="getOrderid(item.oid)">查看详情</p>
                </div>
                <div v-if="item.status == '待评价'">
                  <p style="color: #2f69d2" @click="getdelorder(item.oid), (pinglun = true)">
                    去评价
                  </p>
                  <p @click="fapiao = true">申请开票</p>
                  <div @click="getOrderid(item.oid)">查看详情</div>
                </div>
                <div v-if="item.status == '已完成'">
                  <p @click="fapiao = true">申请开票</p>
                  <p @click="getdelorder(item.oid),delorder = true">删除订单</p>
                  <div @click="getOrderid(item.oid)">查看详情</div>
                </div>
                <div v-if="item.status == '已取消'">
                  <p @click="getdelorder(item.oid),delorder = true">删除订单</p>
                  <div @click="getOrderid(item.oid)">查看详情</div>
                </div>
                <div v-if="item.status == '已关闭'">
                  <p @click="getdelorder(item.oid),delorder = true">删除订单</p>
                  <div @click="getOrderid(item.oid)">查看详情</div>
                </div>
              </td>
            </tr>
          </table>
          <div class="orderpage" style="width: 100%; height: 100%">
            <div style="margin: 0 auto; display: flex">
              <!-- <div class="home-orderpage"  @click="goFirst">首页</div> -->
              <el-pagination background layout="prev, pager, next" :total="atotal">
              </el-pagination>
              
              <!-- <div class="last-orderpage" @click="goLast">尾页</div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 订单删除 -->
    <el-dialog :visible.sync="delorder" class="del">
      <div class="delbox">
        <div class="deltext">确定要删除吗？</div>
        <div class="delbtn">
          <span @click="delorder = false">取消</span>
          <span @click="getdelete">删除</span>
        </div>
      </div>
    </el-dialog>

    <!-- 订单取消 -->
    <el-dialog :visible.sync="cancel" class="del">
      <div class="delbox">
        <div class="deltext">确定要取消吗？</div>
        <div class="delbtn">
          <span @click="cancel = false">取消</span>
          <span @click="(cancel = false), getcancel()">确定</span>
        </div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="receiving" class="del">
      <div class="delbox">
        <div class="deltext">确定收货？</div>
        <div class="delbtn">
          <span @click="receiving = false">取消</span>
          <span @click="(receiving = false), getconfirm()">确定</span>
        </div>
      </div>
    </el-dialog>

    <!-- 售后退款 -->
    <el-dialog :visible.sync="refund" class="refundbox">
      <div class="refund">
        <div class="refundclose" @click="refund = false"></div>
        <div class="reject-title">售后退款</div>
        <div class="reject-line"></div>
        <textarea name="" id="" cols="50" rows="7" v-model="refund_reason" placeholder="请输入退款原因"
          style="margin-bottom: 5px"></textarea>
        <div class="img_box">
          <div class="img_size" v-for="(item, index) of imgurl" :key="index" v-show="imgurl.length != 0">
            <img v-if="imgurl.length != 0" :src="item.src" />
            <div class="remove_logo" @click="fileDel">×</div>
          </div>
          <el-upload action="" list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove"
            accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG" :http-request="uploadImgs" :show-file-list="false">
            <i class="el-icon-camera-solid"></i>
          </el-upload>
        </div>
        <div class="refundbtn" @click="applyRefund(), (refund = false)">
          提交
        </div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="pinglun" class="refundbox">
      <div class="refund" style="width: 365px; height: 320px">
        <div class="refundclose" @click="pinglun = false"></div>
        <div class="reject-title">评价</div>
        <div class="reject-line"></div>
        <textarea name="" id="" cols="50" rows="7" v-model="content" placeholder="请输入商品评价"
          style="margin-bottom: 5px"></textarea>
        <div class="img_box">
          <div class="img_size" v-for="(item, index) of imgurl" :key="index" v-show="imgurl.length != 0">
            <img v-if="imgurl.length != 0" :src="item.src" />
            <div class="remove_logo" @click="fileDel">×</div>
          </div>
          <el-upload action="" list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove"
            accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG" :http-request="uploadImgs" :show-file-list="false">
            <i class="el-icon-camera-solid"></i>
          </el-upload>
        </div>
        <div class="refundbtn" @click="addEvaluation()">
          提交
        </div>
      </div>
    </el-dialog>

    <!-- 售后中 -->
    <el-dialog :visible.sync="shouhou" class="refundbox">
      <div class="refund" style="width: 600px">
        <div class="refundclose" @click="shouhou = false"></div>
        <div class="reject-title">查看售后</div>
        <div class="reject-line" style="margin-bottom: 37px"></div>

        <p style="
            font-size: 20px;
            font-family: Source Han Sans CN Normal,
              Source Han Sans CN Normal-Normal;
            font-weight: bold;
            text-align: left;
            color: #2f69d2;
            margin-bottom: 24px;
          ">
          退款审核中
        </p>

        <div style="width: 100%; height: 14px; margin-bottom: 20px">
          <img src="../../assets/division.png" alt="" style="width: 100%; height: 100%" />
        </div>

        <div style="
            width: 560px;
            padding: 20px;
            background: #f6f6f6;
            border-radius: 4px;
            font-size: 14px;
            font-family: Source Han Sans CN Regular,
              Source Han Sans CN Regular-Regular;
            font-weight: 400;
            text-align: left;
            color: #666666;
          ">
          {{ refundReason }}
        </div>
      </div>
    </el-dialog>

    <!-- 审核成功 -->
    <el-dialog :visible.sync="shouhous" class="refundbox">
      <div class="refund" style="width: 600px">
        <div class="refundclose" @click="shouhous = false"></div>
        <div class="reject-title">查看售后</div>
        <div class="reject-line" style="margin-bottom: 37px"></div>

        <p style="
            font-size: 20px;
            font-family: Source Han Sans CN Normal,
              Source Han Sans CN Normal-Normal;
            font-weight: bold;
            text-align: left;
            color: #2f69d2;
            margin-bottom: 24px;
          ">
          退款成功
        </p>

        <div style="width: 100%; height: 14px; margin-bottom: 20px">
          <img src="../../assets/division.png" alt="" style="width: 100%; height: 100%" />
        </div>

        <div style="
            width: 560px;
            padding: 20px;
            background: #f6f6f6;
            border-radius: 4px;
            font-size: 14px;
            font-family: Source Han Sans CN Regular,
              Source Han Sans CN Regular-Regular;
            font-weight: 400;
            text-align: left;
            color: #666666;
          ">
          {{ refundReason }}
        </div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="shouhout" class="refundbox">
      <div class="refund" style="width: 600px">
        <div class="refundclose" @click="shouhout = false"></div>
        <div class="reject-title">查看售后</div>
        <div class="reject-line" style="margin-bottom: 37px"></div>

        <p style="
            font-size: 20px;
            font-family: Source Han Sans CN Normal,
              Source Han Sans CN Normal-Normal;
            font-weight: bold;
            text-align: left;
            color: #fe0000;
            margin-bottom: 24px;
          ">
          退款失败
        </p>

        <div style="
            width: 100%;
            font-size: 14px;
            font-family: Source Han Sans CN Regular,
              Source Han Sans CN Regular-Regular;
            font-weight: 400;
            text-align: left;
            margin-bottom: 24px;
            color: #666666;
          ">
          {{ rejectReason }}
        </div>

        <div style="width: 100%; height: 14px; margin-bottom: 20px">
          <img src="../../assets/division.png" alt="" style="width: 100%; height: 100%" />
        </div>

        <div style="
            width: 560px;
            padding: 20px;
            background: #f6f6f6;
            border-radius: 4px;
            font-size: 14px;
            font-family: Source Han Sans CN Regular,
              Source Han Sans CN Regular-Regular;
            font-weight: 400;
            text-align: left;
            color: #666666;
          ">
          {{ refundReason }}
        </div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="fapiao" class="refundbox">
      <div class="refund" style="padding: 20px">
        <div class="refundclose" @click="pinglun = false"></div>
        <div class="reject-title">开具发票</div>
        <div class="reject-line" style="margin-bottom: 20px"></div>
        <el-form ref="forms" :model="forms" label-width="80px">
          <el-form-item label="发票类型">
            <el-input v-model="forms.type"></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-radio-group v-model="forms.resource" @change="agreeChange">
              <el-radio :label="1">个人或事业单位</el-radio>
              <el-radio :label="2">企业</el-radio>
            </el-radio-group>
          </el-form-item>

          <div style="width: 100%" v-show="btnstatus">
            <el-form-item label="发票抬头">
              <el-input v-model="forms.head"></el-input>
            </el-form-item>
          </div>
          <div style="width: 100%" v-show="!btnstatus">
            <el-form-item label="发票抬头">
              <el-input v-model="forms.head"></el-input>
            </el-form-item>

            <el-form-item label="税号">
              <el-input v-model="forms.duty" placeholder="纳税人识别号"></el-input>
            </el-form-item>
            <el-form-item label="开户银行">
              <el-input v-model="forms.bank" placeholder="选填"></el-input>
            </el-form-item>
            <el-form-item label="银行账号">
              <el-input v-model="forms.bankno" placeholder="选填"></el-input>
            </el-form-item>
            <el-form-item label="企业地址">
              <el-input v-model="forms.address" placeholder="选填"></el-input>
            </el-form-item>
            <el-form-item label="企业电话">
              <el-input v-model="forms.phone" placeholder="选填"></el-input>
            </el-form-item>
          </div>
        </el-form>
        <div class="refundbtn" style="margin-right:80px">
          提交
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        uid: localStorage.getItem("uid"),
        nickName: localStorage.getItem("nickName"),
        address: localStorage.getItem("address"),
        avatar: localStorage.getItem("avatar"),
        // 分销推广
        extension: false,

        // 添加新地址
        addition: false,

        // 删除订单
        delorder: false,

        // 取消订单
        cancel: false,

        // 确认收货
        receiving: false,

        // 发票
        fapiao: false,
        orderGoodsId: "",
        refund_reason: "",

        forms: {
          type: "增值税电子普通发票",
          resource: 1,
          head: "你好",
          duty: "",
          bank: "",
          bankno: "",
          address: "",
          phone: "",
        },
        btnstatus: true,
        // 售后退款
        refund: false,

        pinglun: false,

        order: {
          status: "",
        },
        form: {
          desc: "",
        },

        oid: "",

        ruleForm: {
          name: "",
          phone: "",
          adderss: "",
        },

        rules: {
          name: [
            { required: true, message: "请输入收货人姓名", trigger: "blur" },
            // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'change' }
          ],
          phone: [
            { required: true, message: "请输入电话号码", trigger: "blur" },
            { min: 11, max: 11, message: "请输入正确号码", trigger: "blur" },
          ],
          adderss: [
            { required: true, message: "请输入详细地址", trigger: "blur" },
            // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'change' }
          ],
        },
        // 预付列表
        advanceList: [],
        advanceorder: {
          status: "",
        },
        atotal: 0,
        noData: "",
        aData: "",

        dialogImageUrl: "",
        dialogVisible: false,
        posterURL: "",
        fileLists: [],
        imgurl: [],
        filename: "",

        // refund_reason: '',
        content: "",
        refundId: "",

        shouhou: false,
        shouhous: false,
        shouhout: false,

        refundReason: "", //申请原因
        rejectReason: "", //反馈
      };
    },
    created() {
      // this.getCityCode();
      // this.getCollectList()
    },
    methods: {
      deleteorder() {
        this.delorder = true;
      },
      isdefaultaddress() {
        this.isdefault = !this.isdefault;
      },
      agreeChange: function (val) {
        let that = this;
        that.btnstatus = (val == 1) ? true : false;
        console.log(val)
      },
      // 删除图片
      handleRemove(file, fileList) {
        this.form.logo = "";
        console.log(file, fileList);
      },
      //查看大图
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.imgDialogVisible = true;
        console.log(file);
      },

      //图片上传成功

      /*========================预付订单=======================*/
      getadvance() {
        this.$api.post({
          path: "/myorder/getOrderList",
          params: {
            uid: this.uid,
            order_type: 3,
            status: this.advanceorder.status,
          },
          success: (data) => {
            console.log(data, "11111");
            if (data.data.list.length == 0) {
              this.aData = "暂无数据";
              this.advanceList = data.data.list;
              this.atotal = 0;
            } else {
              console.log(data);
              this.advanceList = data.data.list;
              this.atotal = data.data.total;
            }
          },
        });
      },
      getadvanceType(v) {
        this.advanceorder.status = v;
        console.log(v);
        this.getadvance();
      },

      getdelorder(v) {

        this.oid = v;

      },

      // 删除订单
      getdelete() {
        this.$api.post({
          path: "/myorder/operationOrder",
          params: {
            type: 2,
            oid: this.oid,
          },
          success: (data) => {
            console.log(data);
            this.delorder = false;
            this.getadvance();
          },
        });
      },

      // 取消订单
      getcancel() {
        this.$api.post({
          path: "/myorder/operationOrder",
          params: {
            type: 3,
            oid: this.oid,
          },
          success: (data) => {
            console.log(data);
            this.cancel = false;
            this.getadvance();
          },
        });
        // this.getOrderList()
      },

      // 确认收货
      getconfirm() {
        this.$api.post({
          path: "/myorder/operationOrder",
          params: {
            type: 1,
            oid: this.oid,
          },
          success: (data) => {
            console.log(data);
            this.receiving = false;
            this.getadvance();
          },
        });
      },
      pay(oid, actualtotal, changePrice, isEditPrice) {
        if (isEditPrice == 0) {
          this.$router.push(`/payment/${oid}/${actualtotal}`);
        } else {
          this.$router.push(`/payment/${oid}/${changePrice}`);
        }

      },
      pay1(oid, actualtotal) {
        this.$router.push(`/payment/${oid}/${actualtotal}`);
      },
      getOrderid(oid) {
        this.$router.push(`/abolish/${oid}`);
      },

      fileDel(index) {
        this.imgurl.splice(index, 1);
      },
      // 上传展示图
      uploadImgs(file) {
        if (this.imgurl.length <= 9) {
          console.log(file.file, "file");

          this.$uploadFiles('http://47.111.70.233:8762/mfxapi/multifileupload', file.file).then(res => {
            res.data.forEach((e) => {
              this.imgurl.push({
                src: e.url,
              });
            });
            console.log(this.imgurl);
            console.log(res.data, "res2");
            this.fileLists.push({
              path: res.data.url,
            });
          });
        } else {
          this.$message.error("不能超过九张哦！");
        }
      },
      getorderGoodsId(v) {
        this.orderGoodsId = v;
      },

      // 售后退款
      getshouh(v, isEditPrice, orderGoodsId) {
        if (isEditPrice == 1) {
          this.$message({
            message: '改价订单不允许退款',
            type: 'warning'
          });
        } else {
          this.refund = true
          this.getdelorder(v)
          this.getorderGoodsId(orderGoodsId)
        }

      },
      applyRefund() {
        this.$api.post({
          path: "/myorder/applyRefund",
          params: {
            oid: this.oid,
            order_goods_ids: this.orderGoodsId,
            refund_reason: this.refund_reason,
            orderRefundImgList: this.imgurl,
          },
          success: (data) => {
            console.log(data);
            // this.getOrderDetails()
            this.getadvance();
          },
        });
      },

      addEvaluation() {
        this.$api.post({
          path: "/myorder/addEvaluation",
          params: {
            oid: this.oid,
            content: this.content,
            lstEvalImg: this.imgurl,
            uid: this.uid,
          },
          success: (data) => {

            if (data.code == 1) {
              this.$message({
                message: data.msg,
                type: 'success'
              });
              this.pinglun = false
              this.getadvance();
              this.content = '',
                this.imgurl = []
            } else {
              this.$message({
                message: data.msg,
                type: 'warning'
              });
            }
            console.log(data);


          },
        });
      },

      getrefundId(v) {
        this.refundId = v;
        this.getadvance();
      },

      // 退款详情
      getRefundDetails() {
        this.$api.post({
          path: "/myorder/getRefundDetails",
          params: {
            refundId: this.refundId,
          },
          success: (data) => {
            console.log(data);
            this.refundReason = data.data.refundReason;
            this.rejectReason = data.data.rejectReason;
          },
        });
      },
    },
    filters: {
      numFilter(value) {
        // 截取当前数据到小数点后两位
        let realVal = parseFloat(value).toFixed(2);
        return realVal;
      },
    },
    mounted() {
      // this.getAddresList()
      // this.getPointList()
      // this.getZiyuanDesc()
      // this.getOrderList()
      this.getadvance();
    },
  };
</script>
<style>
  .el-breadcrumb__item:last-child .el-breadcrumb__inner {
    color: #2f69d2;
  }

  .Pbox {
    width: 100%;
    margin: 130px auto;
  }

  .pmain {
    width: 100%;
    display: flex;
  }

  .pmainL {
    width: 180px;
    margin-right: 10px;
  }

  .pmainL ul {
    width: 180px;
  }

  .pmainL li {
    list-style: none;
    height: 48px;
    width: 65px;
    padding: 0px 57px;
    font-size: 16px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    /* font-weight: bold; */
    text-align: left;
    color: #1a1a1a;
    line-height: 48px;
  }

  .liactive {
    background-color: #2f69d2;
    font-weight: bold;
    color: #ffffff;
  }

  .myorderbox {
    width: 1010px;
  }

  .yforderlist {
    width: 1010px;
  }

  .yforderlist {
    /* width: 1480px; */
    border: 1px solid rgba(204, 204, 204, 0.3);
    /* margin-top: 10px; */
    border-spacing: 0;
    margin-bottom: 28px;
    /*去掉单元格间隙*/
  }

  .yforderlist th {
    font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
    font-weight: 500;
    text-align: center;
    color: #1a1a1a;
    border-bottom: 1px solid rgba(204, 204, 204, 0.3);
  }

  .yforderlist td {
    border-right: 1px solid rgba(204, 204, 204, 0.3);
    border-bottom: 1px solid rgba(204, 204, 204, 0.3);
    font-size: 14px;
    text-align: center;
  }
</style>