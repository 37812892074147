// 1. 导入 Vue
import Vue from "vue";

// 2. 导入vue-router
import VueRouter from 'vue-router';

// 3. 注册 Vue.use()
Vue.use(VueRouter);

// 4. 导入单页面组件

// 主页头尾
import index from "./components/index/index.vue"

// 主页详情
import indexDetails from "./components/index/indexDetails.vue"

// 搜索
import find from "./components/find/find.vue"

// 轮播图详情
import lunbo from "./components/lunbo/lunbo.vue"

// 购物车
import buycart from "./components/buycart/buycart.vue"

// 追溯详情
import trace from "./components/trace/trace.vue"

// 商品分类列表
import goodsList from "./components/goodsList/goodsList.vue"

import allgoodsList from "./components/goodsList/allgoodsList.vue"

// 商品详情
import goodsDetails from "./components/goodsDetails/goodsDetails.vue"

// 活动商品列表
import activity from "./components/activityList/activityList.vue"

// 确认订单
import confirmorder from "./components/confirmorder/confirmorder.vue"

// 支付方式
import payment from "./components/payment/payment.vue"

// 我的优惠券
import mycoupon from "./components/mycoupon/mycoupon.vue"

//普通和拼团订单详情
import waiting from "./components/waitinggroup/waitinggroup.vue"

// 预付订单详情
import abolish from "./components/advanceorder/abolish.vue"

// 登录、注册头尾
import logins from "./components/login/logins.vue"

// 登录
import login from "./components/login/login.vue"

// 注册
import register from "./components/login/register.vue"

// 忘记密码
import forget from "./components/login/forget.vue"

/*================================个人中心==============================*/
import pmessage from './components/mine/pmessage.vue'

import advanceorder from './components/mine/advanceorder.vue'

import install from './components/mine/install.vue'

import myaddress from './components/mine/myaddress.vue'

import mycollection from './components/mine/mycollection.vue'

import myorder from './components/mine/myorder.vue'

import platform from './components/mine/platform.vue'

import revise from './components/mine/revise.vue'

const routes = [{
    path: "/",
    component: index,
    children: [{
        path: "",
        redirect: "/indexDetails"
    }, {
        path: "/indexDetails",
        component: indexDetails
    }, {
        // path:'/find',
        path: "/find/:val?",
        component: find
    }, {
        path: "/buycart",
        component: buycart
    }, {
        path: "/trace/:text?",
        component: trace
    }, {
        path: "/goodsList/:val?",
        component: goodsList
    },{
        path:'/allgoodsList/:cateId?',
        component:allgoodsList
    }, {
        path: "/goodsDetails/:id?",
        component: goodsDetails
    }, {
        path: "/activity/:type?",
        component: activity
    }, {
        path: "/confirmorder",
        component: confirmorder
    }, {
        path: "/payment/:oid/:actualtotal?",
        component: payment
    }, 
    {
        path: "/pmessage",
        component: pmessage
    }, {
        path: "/advanceorder",
        component: advanceorder
    }, {
        path: "/install",
        component: install
    }, {
        path: "/myaddress",
        component: myaddress
    }, {
        path: "/mycollection",
        component: mycollection
    }, {
        path: "/myorder",
        component: myorder
    }, {
        path: '/platform',
        component: platform
    }
    , {
        path: "/mycoupon",
        component: mycoupon
    }, {
        path: "/waiting/:oid?",
        component: waiting
    },{
        path: "/abolish/:oid?",
        component: abolish
    }, {
        path: "/lunbo/:id?",
        component: lunbo
    },{
        path:'/revise',
        component:revise
    }]
}, {
    path: "/logins",
    component: logins,
    children: [{
        path: "/login",
        component: login
    }, {
        path: "/register",
        component: register
    }, {
        path: "/forget",
        component: forget
    }]
}]

// 挂载路由导航守卫
// router.beforeEach((to, from, next) => {
//     if (to.path === '/login') return next()
//     // 从sessionStorage中获取保存的token值
//     const tokenStr = window.sessionStorage.getItem('token')
//     // 如果token的值不存在，强制跳转到登录页
//     if (!tokenStr) return next('/login')
//     // 存在token，那么就放行
//     next()
//   })

// 6. 创建vue-router的实例化对象
const router = new VueRouter({
    mode: 'hash',
    routes
})



// 7. 暴露router对象
export default router;