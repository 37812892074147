<template>
  <!-- 商品详情 -->
  <div class="goodsDetails">
    <div class="navigation">
      <div class="home"></div>
      <div class="current">当前页面:</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>蔬菜</el-breadcrumb-item>
        <el-breadcrumb-item>商品详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="goods-details">
      <div class="goodsimg" ref="swiperTop">
        <div style="width: 400px;height: 400px; margin-bottom: 10px; overflow: hidden;">
          <div class="bigimg">
            <!-- <img :src="ImgUrl" style="width: 100%; height: 100%" /> -->
            <el-image style="width: 100%; height: 100%" :src="ImgUrl" :preview-src-list="goodsAdvs">
            </el-image>
          </div>
        </div>
        <div class="simg">
          <div class="left" @click="toLeft">
            <i class="el-icon-arrow-left"></i>
          </div>
          <!-- <div class="imglist">
            <div ref="imgscroll" class="imgscroll">
              <div class="img" v-for="(img, index) in imgUrl" :key="index" @click="ImgUrl = img.url">
                <img :src="img.url" />
              </div>
            </div>
          </div> -->

          <div class="imglist">
            <div class="img" v-for="(img,k) in goodsAdv" :key="img.goodsAdvId" @click='getIndexs(img.goodsAdvImg,k)'>
              <img :src="img.goodsAdvImg">
            </div>
          </div>

          <div class="right">
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>

      <!-- 普通商品详情 -->
      <div class="goods-right" v-show="goodsDetail.isPre == 0 && goodsDetail.goodsMode == 2">
        <div style="width: 100%;  margin-bottom: 20px;">
          <div class="goods-Name">
            {{ goodsDetail.goodsName }}
          </div>
          <div class="stock">
            <div></div>
            <div>
              运费：<span>{{ goodsDetail.freight }}</span> 元
              <div>满 {{ goodsDetail.freeNum }} 免邮</div>
            </div>
            <div>
              库存<span>{{ goodsDetail.stock }}</span>
            </div>
            <div>
              销量 <span>{{ goodsDetail.saleNum }}</span>
            </div>
          </div>
          <div class="goodsType">
            <div v-for="(item, index) in goodsPrice" :key="index">
              <div class="pice">{{ item.price }}</div>
              <div class="number">{{ item.beginNum }}-{{ item.endNum }}个</div>
            </div>
          </div>

          <div class="goodsNum">
            数量
            <div class="count">
              <el-input-number size="small" v-model="num" :min="1"></el-input-number>
            </div>
          </div>
        </div>

        <div class="buy">
          <div class="addcar" @click="getaddGoodsCart">加入购物车</div>
          <div class="buys" @click="getMyOrde(0,0,0)">立即购买</div>
        </div>
      </div>

      <!-- 拼团商品详情 -->
      <div class="goodsRight" v-show="goodsDetail.goodsMode == 1 && goodsDetail.isPre == 0">
        <div style="width: 100%;  margin-bottom: 20px;">
          <div class="goods-Name">
            {{ goodsDetail.goodsName }}
          </div>
          <div class="stocks">
            <div>￥{{goodsDetail.priceMin}}<span>起</span></div>
            <div></div>
            <div>
              运费：<span>{{ goodsDetail.freight }}</span> 元
              <div>满 {{ goodsDetail.freeNum }} 免邮</div>
            </div>
            <div>
              库存<span>{{ goodsDetail.stock }}</span>
            </div>
            <div>
              销售 <span>{{ goodsDetail.saleNum }}</span>
            </div>
          </div>
          <div class="groups">
            <div class="groups-title">
              <span>拼团信息</span>
              <span>可直接参与</span>
            </div>
            <div class="groups-img">
              <div class="groups-user">
                <div v-for="item in goodGrouping" :key="item.group_id">
                  <img :src="item.avatar" alt="" />
                </div>
              </div>
              <div class="groups-btn" @click="groupVisible = true">查看全部</div>
            </div>
          </div>

          <div class="pinNam">
            数量
            <div class="count">
              <el-input-number size="small" v-model="num" :min="1"></el-input-number>
            </div>
          </div>
        </div>


        <div class="pinbuy">
          <div class="alonebuy" @click="getMyOrde(0,0,0)">
            <p>￥{{ goodsGroup.alonePrice }}</p>
            <p>单独购买</p>
          </div>
          <div class="pinbuys" @click="getMyOrde(1,0,0)">
            <p>￥{{ goodsGroup.groupPrice }}</p>
            <p>发起拼团</p>
          </div>
        </div>
      </div>

      <!-- 预付详情 -->
      <div class="goods-right" v-show="goodsDetail.isPre == 1 && goodsDetail.goodsMode == 2">
        <div style="width: 100%; margin-bottom: 20px;">
          <div class="goods-Name">
            {{ goodsDetail.goodsName }}
          </div>
          <div class="stock">
            <div></div>
            <div>
              运费：<span>{{ goodsDetail.freight }}</span> 元
              <div>满 {{ goodsDetail.freeNum }} 免邮</div>
            </div>
            <div>
              销量 <span>{{ goodsDetail.saleNum }}</span>
            </div>
          </div>
          <div class="goodsType">
            <div v-for="(item, index) in goodsPrice" :key="index">
              <div class="pice">{{ item.price }}</div>
              <div class="number">{{ item.beginNum }}-{{ item.endNum }}个</div>
            </div>
          </div>

          <div class="goodsNum">
            数量
            <div class="count">
              <el-input-number size="small" v-model="num" :min="1"></el-input-number>
            </div>
          </div>
        </div>


        <div class="advancebuy" @click="getMyOrde(0,0,0)">支付{{ goodsDetail.prePercent }}%预付购买</div>
      </div>
    </div>

    <div class="collectionbox" >
      <div class="collections" @click="addCollect(2)" style=" width: 65px; 
      height: 14px; line-height: 14px;" v-if="type == 1">
        <i class="el-icon-star-on"></i>已收藏
      </div>
      <div class="collections" @click="addCollect(1)" style=" width: 65px; 
      height: 14px; line-height: 14px;" v-if="type == 0">
        <i class="el-icon-star-off"></i>收藏
      </div>
    </div>

    <div class="comment">
      <div class="commenttop">
        <div :class="['comtitle',order.type=='1' ? 'comactive' : '']" @click='getorder(1)'>
          商品介绍
        </div>
        <div class="comtitle" :class="['comtitle',order.type=='2' ? 'comactive' : '']" @click='getorder(2)'>
          商品评论
        </div>
      </div>
      <div class="commentsbox" style="width: 100%; ">
        <div v-if="order.type==1" class="introduce" v-html="goodsDetail.goodsDesc" style="margin:0 auto"></div>

        <div class="comments" v-if="order.type==2">
          <div class="commentsNum">
            评论<span>({{ evals.length }})</span>
          </div>

          <div class="commentsText" v-for="item in evals" :key="item.evalId">
            <div class="commentText">
              <div class="img-name">
                <div class="userimg">
                  <img :src="item.avatar" alt="" />
                </div>
                <div class="user-name">{{ item.nickName }}</div>
              </div>

              <div class="userText">
                {{ item.content }}
              </div>

              <div class="commentImg">
                <div v-for="img in item.lstEvalImg" :key="img.evalId" @click='pinglunimg=true'>
                  <img :src="img.src" alt="" />
                </div>
              </div>
              <div class="commentTime">{{ item.createTime | timeFiler}}</div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <el-dialog :visible.sync="groupVisible" class="groups1">
      <div class="pinGroup">
        <div class="coupons-close" @click="groupVisible=false"></div>

        <div class="reject-title">可拼团信息</div>
        <div class="reject-line"></div>
        <div class="pinList">
          <div class="pinuser" v-for="item in goodGrouping" :key="item.group_id">
            <div class="pinuser-img">
              <img :src="item.avatar" alt="" />
            </div>
            <div class="pinuser-name">{{ item.nick_name }}</div>

            <!-- 拼团时间和人数 -->
            <div class="pinTime">
              <p>
                还差<span>{{ item.group_sy_num }}</span>人成团
              </p>
              <!-- <p>剩余<span>{{new Date(item.deadline).getTime()}}</span></p> -->
              <p>剩余<span>{{ item.djs }}</span></p>
              <!-- <p>剩余<span>{{time.djs}}</span></p> -->
            </div>
            <!-- <div class="addgroup" @click="getpintuan(item.group_id),getMyOrde(1,0,item.group_id)">参与拼团</div> -->
            <div class="addgroup" @click="getpintuan(item.group_id)">参与拼团</div>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="pinglunimg" class="groups1">

    </el-dialog>
  </div>
</template>

<script>
  // import bigimg from './bigimg.vue'
  function InitTime(endtime) {
    // var dd, hh, mm, ss = null;
    var hh, mm, ss = null;
    var time = parseInt(endtime) - new Date().getTime();
    if (time <= 0) {
      return '结束'
    } else {
      // dd = Math.floor(time / 60 / 60 / 24);
      hh = Math.floor((time / 60 / 60) % 24);
      mm = Math.floor((time / 60) % 60);
      ss = Math.floor(time % 60);
      // var str = dd + "天" + hh + "小时" + mm + "分" + ss + "秒";
      var str = hh + ":" + mm + ":" + ss;
      return str;

      // console.log(str);
    }
  }
  export default {
    data() {

      return {
        num: 1,
        //大图片默认显示第一张
        ImgUrl: '',
        token: localStorage.getItem("token"),
        goodsAdv: [], //商品轮播图
        goodsDetail: {}, //商品详情
        goodsPrice: [], //价格区间
        goodsGroup: {}, //成团价格
        goodGrouping: [], //平团信息
        goodsAdvs: [],

        evals: [],

        countdown: '',//拼团到期时间

        // 参团列表
        groupVisible: false,

        value: "",

        goodRight: { three: false },
        uid: localStorage.getItem("uid"),
        type: 2,
        // isSelected:false
        is_group: '',
        is_cart: '',
        order: {
          type: 1
        },

        time: [],
        list: [],
        routentime: '',
        flag: false,

        pintuan: '',
        timer: '',
        pinglunimg: false
      };
    },

    methods: {
      //点击小图片时将图片路径赋值给大图
      // 普通商品
      goodsDetails() {
        this.$api.post({
          path: "/goods/goodsDetail",
          params: {
            goodsId: this.$route.params.id,
          },
          success: (data) => {
            console.log(data);
            this.goodsAdv = data.data.goodsAdv;
            this.goodsDetail = data.data.goodsDetail;
            this.goodsPrice = data.data.goodsPrice;
            this.ImgUrl = data.data.goodsAdv[0].goodsAdvImg
            this.goodsAdvs = [data.data.goodsAdv[0].goodsAdvImg]
            this.type = data.data.isCollect
            if (
              this.goodsDetail.goodsMode == 1 &&
              this.goodsDetail.isPre == 0
            ) {
              this.goodsGroup = data.data.goodsGroup;
              this.countdown = data.data.goodsGroup.createTime
              this.goodGrouping = data.data.goodGrouping;

              // this.goodGrouping.forEach((e) => {
              //   this.time.push( new Date(e.deadline).getTime())
              // })
              // console.log(this.time, '截至时间')


            }


            // this.gettime()
            console.log(this.goodsAdv, "商品轮播图")
            // console.log(this.goodsDetail, "商品详情")
            // console.log(this.goodsPrice, "价格区间")
            // console.log(this.goodGrouping, "拼团信息")

            var ssss = this.goodGrouping
            ssss.map((obj, index) => {
              console.log(index);
              this.$set(
                obj, "djs", InitTime(obj.time)
              );
            })
            this.list = ssss;

          },
        });
        // }
      },
      getIndexs(imgUrl) {
        this.ImgUrl = imgUrl;
        this.goodsAdvs = [imgUrl]
        // console.log(index)
      },
      getorder(v) {
        this.order.type = v
      },
      getIndex() {
        this.ImgUrl = this.goodsAdv.goodsAdv[0];
      },
      addCollect(type) {
        console.log(type);
        let istype = type;
        console.log(istype);
        this.$api.post({
          path: "/myorder/addCollect",
          params: {
            uid: this.uid,
            goods_id: this.$route.params.id,
            type: type,
          },
          success: (data) => {
            if (data.code == 1) {
              this.goodsDetails()
            }
            if (!this.token) {
              this.$message({
                showClose: true,
                message: '请先登录',
                type: 'warning'
              });
            } 
            console.log(data);
          },
        });
      },
      // 商品评价
      goodsEval() {
        this.$api.post({
          path: "/goods/goodsEval",
          params: {
            goodsId: this.$route.params.id,
          },
          success: (data) => {
            console.log(data);
            this.evals = data.data.eval;
          },
        });
      },
      toLeft() {
        this.$refs.imgscroll.style.transform = "translateX(-80px)";
      },
      // 加入购物车
      getaddGoodsCart() {
        if (this.token == '' || !this.token) {
          this.$message({
            message: '请先登录',
            type: 'warning'
          });
          this.$router.push("/login")
        } else {
          if (this.goodsDetail.stock == 0 || (this.num > this.goodsDetail.stock)) {
            this.$message({
              message: '库存不足，请选择其他商品',
              type: 'warning'
            });
          } else {
            this.$api.post({
              path: "/myorder/addGoodsCart",
              params: {
                uid: localStorage.getItem("uid"),
                goods_id: this.$route.params.id,
                cart_num: this.num,
              },
              success: (data) => {
                if (data.code == 1) {
                  this.$message({
                    message: data.msg,
                    type: "success",
                  });
                }
                console.log(data);
                console.log(this.uid);

                if (data.code == 0) {
                  this.$message({
                    message: data.msg,
                    type: 'warning'
                  });
                  window.localStorage.clear()
                  this.$router.push('/indexDetails')
                } else if (data.code == 0 && data.msg == '会话已过期') {
                  this.$router.replace('/indexDetails')
                  this.$router.go('/indexDetails');
                  localStorage.removeItem('token')
                  localStorage.removeItem('uid')
                  localStorage.removeItem('nickName')
                  localStorage.removeItem('avatar')
                }
              },
            });
          }
        }
      },

      // 能否参与拼团
      getpintuan(id) {
        this.$api.post({
          path: '/myorder/getOrderByGroupId',
          params: {
            group_id: id,
            uid: this.uid
          }, success: (data) => {
            console.log(data)
            if (data.data == 0) {
              this.$message({
                message: '您已参团',
                type: 'warning'
              });
              return false
            }
            else if (data.data == 1) {
              this.getMyOrde(1, 0, id)
            }
            this.pintuan = data.data
            if (data.code == 0) {
              this.$message({
                message: data.msg,
                type: 'warning'
              });
              window.localStorage.clear()
              this.$router.push('/indexDetails')
            }
          }
        })
      },


      // 立即购买
      getMyOrde(v, n, id) {
        if (this.token == '' || !this.token) {
          this.$message({
            message: '请先登录',
            type: 'warning'
          });
          this.$router.push("/login")
        } else if (this.pintuan == '0') {
          this.$message({
            message: '不能参与自己发起的拼团',
            type: 'warning'
          });
        } else {
          if (this.goodsDetail.stock == 0 || this.num >= this.goodsDetail.stock) {
            this.$message({
              message: '库存不足，请选择其他商品',
              type: 'warning'
            });
          } else {
            let params = {}
            params.goods = []
            params.uid = localStorage.getItem("uid")
            params.is_group = v
            params.is_cart = n
            params.group_id = id
            params.goods = [{ "goods_id": this.$route.params.id, "begin_num": this.num }]
            console.log(params)
            sessionStorage.setItem("myOrder", JSON.stringify(params))
            this.$router.push("/confirmorder")
          }
        }
      },





    },
    // created() {

    //   // this.goodGrouping.map((obj, index) => {
    //   //   this.$set(
    //   //     obj, "djs", InitTime(obj.time)
    //   //   );
    //   // })
    //   // this.list = this.goodGrouping;
    // },
    mounted() {
      // this.countTime()
      // let time = setInterval(() => {
      //   if (this.flag == true) {
      //     clearInterval(time)
      //   }
      //   this.timeDown()
      // }, 500)
      this.timer = setInterval(() => {
        for (var key in this.list) {
          var aaa = new Date(this.list[key]["deadline"]).getTime();
          var bbb = new Date().getTime();
          var rightTime = aaa - bbb;
          if (rightTime > 0) {
            // var dd = Math.floor(rightTime / 1000 / 60 / 60 / 24);
            var hh = Math.floor((rightTime / 1000 / 60 / 60) % 24);
            var mm = Math.floor((rightTime / 1000 / 60) % 60);
            var ss = Math.floor((rightTime / 1000) % 60);
            // this.list[key]["djs"] = dd + "天" + hh + "时" + mm + "分" + ss + '秒';

            if (hh < 10) {
              hh = `0${hh}`
            }
            if (mm < 10) {
              mm = `0${mm}`
            }
            if (ss < 10) {
              ss = `0${ss}`
            }
            this.list[key]["djs"] = hh + ":" + mm + ":" + ss;

          } else {
            this.list[key]["djs"] = '00' + ":" + '00' + ":" + '00';
          }


          // console.log(aaa);
          // console.log(bbb);
          // console.log(rightTime);
          // console.log(this.list[key]["djs"])
        }
      }, 1000);
    },

    props: {
      // endTime: {
      //   type: ''
      // }
    },

    // 时间过滤
    filters: {
      timeFiler(value) {
        let times = /\d{4}-\d{1,2}-\d{1,2}/g.exec(value)
        return times[0]
      },
    },
    // 收藏
    created() {
      this.goodsDetails();
      this.goodsEval();

      // this.$api.post({
      //   path: "/myorder/addCollect",
      //   params: {
      //     uid: this.uid,
      //     goods_id: this.$route.params.id,
      //     type: 2,
      //   },
      //   success: (data) => {
      //     console.log(data);
      //     console.log(470);

      //     if (data.data == 0) {
      //       this.type = 2;
      //       console.log("未收藏");
      //     }
      //     if (data.data == 1) {
      //       this.$api.post({
      //         path: "/myorder/addCollect",
      //         params: {
      //           uid: this.uid,
      //           goods_id: this.$route.params.id,
      //           type: 1,
      //         },
      //         success: (data) => {
      //           console.log(data);
      //           this.type = 1;
      //         },
      //       });
      //     }
      //   },
      // });
    },
    beforeDestroy() {
      if (this.timers) {
        clearInterval(this.timers); //关闭
      }
    },
    computed: {},
  };
</script>

<style scoped>
  .goodsDetails {
    width: 1200px;
    margin: 130px auto;
  }

  .navigation {
    width: 100%;
    height: 20px;
    font-size: 14px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    font-weight: 500;
    display: flex;
    margin-bottom: 17px;
    margin-left: 5px;
  }

  .home {
    width: 16px;
    height: 16px;
    /* background-color: thistle; */
    background-image: url(../../assets/home.png);
    background-size: 100%;
    margin-right: 10px;
  }

  .current {
    color: #666666;
    margin-right: 10px;
  }

  .navigation div:last-child {
    color: #2F69D2;
  }

  .el-breadcrumb {
    line-height: 20px;
  }

  .el-breadcrumb-item:last-child {
    color: #2F69D2;
  }

  .goods-details {
    width: 100%;
    /* height: 490px; */
    /* background-color: thistle; */
    display: flex;
    margin-bottom: 20px;
  }

  .goodsimg {
    width: 400px;
    height: 100%;
    margin-right: 20px;
  }

  .bigimg {
    width: 400px;
    height: 400px;
    margin-bottom: 10px;
  }

  .simg {
    width: 100%;
    height: 80px;
    display: flex;
  }

  .simg .img {
    width: 80px;
    height: 80px;
    margin-right: 3px;
  }

  .simg .img img {
    width: 100%;
    height: 100%;
  }

  .simg .left,
  .simg .right {
    width: 32px;
    height: 100%;
    font-size: 10px;
    color: #cccccc;
    text-align: center;
    line-height: 80px;
    background: #2f69d2;
  }

  .simg .imglist {
    width: 336px;
    height: 100%;
    /* display: flex; */
    overflow: hidden;
  }

  .simg .imglist .imgscroll {
    height: 100%;
    transition: all 0.3s;
  }

  .simg .imglist .img {
    display: inline-block;
    width: 80px;
  }

  .goods-right {
    width: 100%;
    /* height: 470px; */
    padding-top: 20px;
  }

  .goods-Name {
    width: 100%;
    font-size: 18px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: bold;
    color: #1a1a1a;
    margin-bottom: 30px;
  }

  .stock {
    width: 100%;
    height: 25px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .stock>div {
    height: 100%;
    font-size: 14px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: 400;
    /* text-align: right; */
    color: #666666;
    line-height: 25px;
  }

  .stock>div:first-child {
    width: 81px;
    /* background-color: lightblue; */
    background-image: url(../../assets/authority_.png);
    background-size: 100%;
  }

  .stock>div:nth-child(2) {
    display: flex;
  }

  .stock>div:nth-child(2) div {
    width: 80px;
    height: 18px;
    border-radius: 2px;
    background-color: rgb(255, 102, 102);
    font-size: 12px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: 400;
    text-align: center;
    line-height: 18px;
    color: #ffffff;
    margin-left: 13px;
    margin-top: 3px;
  }

  .goodsType {
    width: 100%;
    /* height: 55px; */
    /* background: #ffffff; */
    border: 2px solid #cccccc;
    border-radius: 10px;
    border: 2px dashed #cccccc;
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-around; */
    margin-bottom: 70px;
  }

  .goodsType>div {
    width: 20%;
    height: 55px;
    /* margin-right: 15px; */
    margin-bottom: 25px;
  }

  .goodsType>div .pice {
    width: 100%;
    font-size: 24px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    font-weight: bold;
    text-align: center;
    color: #ff0000;
    margin-bottom: 8px;
  }

  .goodsType>div .number {
    width: 100%;
    font-size: 14px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: 400;
    text-align: center;
    color: #666666;
  }

  .goodsNum {
    width: 215px;
    height: 30px;
    display: flex;
    font-size: 16px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: 400;
    /* text-align: left; */
    color: #1a1a1a;
    line-height: 30px;
    /* margin-bottom: 50px; */
  }

  .goodsNum div {
    margin-left: auto;
  }

  .goodsNum .el-input__inner {
    border-radius: 20px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .count .el-input__inner {
    border-radius: 20px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .goodsNum .el-input-number--small {
    /* border-radius: 30px; */
    width: 100px;
  }

  .goodsNum .el-input-number__decrease {
    border-radius: 20px 0px 0px 20px;
  }

  .goodsNum .el-input-number__increase {
    border-radius: 0px 20px 20px 0px;
  }

  .count .el-input-number__decrease {
    border-radius: 20px 0px 0px 20px;
  }

  .count .el-input-number__increase {
    border-radius: 0px 20px 20px 0px;
  }

  .buy {
    width: 370px;
    height: 56px;
    display: flex;
    justify-content: space-between;
  }

  .buy div {
    width: 180px;
    height: 56px;
    font-size: 18px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    line-height: 56px;
  }

  .addcar {
    background-color: #ffbf00;
  }

  .buys {
    background-color: #ff0000;
  }

  .collectionbox {
    width: 65px;
    height: 14px;
    margin-left: 300px;
    margin-bottom: 27px;
  }

  .collections {
    width: 65px;
    height: 14px;

    color: #2f69d2;
  }

  .comment {
    width: 100%;
    border: 1px solid #f2f2f2;
  }

  .commenttop {
    width: 100%;
    height: 68px;
    background: #f6f6f6;
    display: flex;
  }

  .comtitle {
    width: 160px;
    height: 100%;
    text-align: center;
    line-height: 68px;
    font-size: 18px;
    font-weight: normal;
  }

  .comactive {
    background-color: white;
    border-top: 4px solid #2f69d2;
  }

  .el-tabs--border-card>.el-tabs__header {
    height: 68px;
  }

  .el-tabs--border-card>.el-tabs__header {
    border: 0;
  }

  .el-tabs--border-card>.el-tabs__header .el-tabs__item {
    width: 158px;
    height: 68px;
    line-height: 68px;
    text-align: center;
    font-size: 18px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: bold;
    border: 0;
    color: black;
  }

  .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
    border-top: 4px solid #2f69d2;
  }

  .comments {
    width: 1160px;
    padding: 40px 20px;
  }

  .introduce {
    width: 1160px;
    padding: 40px 20px;
  }

  .commentsNum {
    height: 20px;
    padding-left: 12px;
    border-left: 8px solid #2f69d2;
    font-size: 20px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    font-weight: bold;
    /* text-align: left; */
    color: #1a1a1a;
    line-height: 20px;
    margin-bottom: 30px;
  }

  .commentsNum span {
    display: inline-block;
    font-size: 16px;
    color: #999999;
    font-weight: 400;
    margin-left: 10px;
  }

  .commentsText {
    width: 100%;
    margin-bottom: 30px;
  }

  .commentText {
    width: 100%;
  }

  .img-name {
    width: 100%;
    display: flex;
    height: 36px;
    margin-bottom: 10px;
  }

  .userimg {
    width: 36px;
    height: 36px;
    border: 50%;
    background-color: thistle;
    border-radius: 50%;
    margin-right: 12px;
  }

  .userimg img {
    width: 100%;
    height: 100%;
    /* border: 50%; */
    border-radius: 50%;
  }

  .user-name {
    height: 36px;
    font-size: 18px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: bold;
    /* text-align: left; */
    color: #1a1a1a;
    line-height: 36px;
  }

  .userText {
    width: 685px;
    font-size: 14px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: 400;
    /* text-align: left; */
    color: #333333;
    /* line-height: 14px; */
    margin-bottom: 14px;
  }

  .commentImg {
    width: 250px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
  }

  .commentImg div {
    width: 80px;
    height: 80px;
    margin-right: 5px;
    margin-bottom: 5px;
    /* background-color: thistle; */
  }

  .commentImg div img {
    width: 100%;
    height: 100%;
  }

  .commentImg div:nth-child(3n) {
    margin-right: 0;
  }

  .commentTime {
    font-size: 14px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: 400;
    /* text-align: right; */
    color: #999999;
  }

  /* ====================拼团商品详情====================== */
  .goodsRight {
    width: 660px;
    height: 470px;
    padding-top: 20px;
  }

  .goods-Name {
    width: 575px;
    font-size: 18px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: bold;
    color: #1a1a1a;
    margin-bottom: 30px;
  }

  .stocks {
    width: 100%;
    height: 25px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 120px;
  }

  .stocks>div {
    height: 100%;
    font-size: 14px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: 400;
    /* text-align: right; */
    color: #666666;
    line-height: 25px;
  }

  .stocks>div:first-child {
    width: 100px;
    /* background-color: lightblue; */
    font-size: 24px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    font-weight: bold;
    text-align: center;
    color: #ff0000;
  }

  .stocks>div:first-child span {
    font-size: 14px;
    color: #333333;
    font-weight: 400;
    display: inline-block;
    margin-left: 5px;
  }

  .stocks>div:nth-child(2) {
    width: 80px;
    /* background-color: lightblue; */
    background-image: url(../../assets/authority_.png);
    background-size: 100%;
  }

  .stocks>div:nth-child(3) {
    display: flex;
  }

  .stocks>div:nth-child(3) div {
    width: 90px;
    height: 18px;
    border-radius: 2px;
    background-color: rgb(255, 102, 102);
    font-size: 12px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: 400;
    text-align: center;
    line-height: 18px;
    color: #ffffff;
    margin-left: 13px;
    margin-top: 3px;
  }

  .groups {
    width: 460px;
    height: 75px;
    padding: 20px;
    background: #f6f6f6;
    border-radius: 10px;
    margin-bottom: 27px;
  }

  .groups-title {
    width: 445px;
    height: 20px;
    padding-left: 8px;
    border-left: 6px solid #2f69d2;
    margin-bottom: 12px;
  }

  .groups-title span:first-child {
    height: 100%;
    font-size: 18px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    font-weight: bold;
    text-align: left;
    line-height: 20px;
    color: #1a1a1a;
    display: inline-block;
    margin-right: 10px;
  }

  .groups-title span:last-child {
    display: inline-block;
    height: 100%;
    font-size: 14px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    font-weight: 500;
    text-align: left;
    line-height: 20px;
    color: #999999;
  }

  .groups-img {
    width: 100%;
    height: 48px;
    display: flex;
    /* background-color: thistle; */
  }

  .groups-user {
    width: 378px;
    height: 100%;
    margin-right: 2px;
    position: relative;
    display: flex;
    flex-direction: columnl;
    justify-content: flex-start;
    /* padding: 0 10rpx; */
    box-sizing: border-box;
  }

  .groups-user div {
    width: 48px;
    height: 48px;
    background-color: thistle;
    border-radius: 50px;
    /* border: 1px solid black; */
  }

  .groups-user div:nth-child(1) {
    /* transform:translateX(-20px); */
    z-index: 5;
  }

  .groups-user div:nth-child(2) {
    transform: translateX(-20px);
    z-index: 4;
  }

  .groups-user div:nth-child(3) {
    transform: translateX(-40px);
    z-index: 3;
  }

  .groups-user div:nth-child(4) {
    transform: translateX(-60px);
    z-index: 2;
  }

  .groups-user div:nth-child(5) {
    transform: translateX(-80px);
    z-index: 1;
  }

  /* .groups-user div:nth-child(3){
        transform:translateX(-90px);
    } */

  .groups-user div img {
    width: 100%;
    height: 100%;
    border-radius: 50px;
    display: flex;
  }

  .groups-btn {
    width: 80px;
    height: 30px;
    background: #ff0000;
    border-radius: 4px;
    margin-top: 9px;
    font-size: 16px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: 400;
    text-align: center;
    line-height: 30px;
    color: #ffffff;
  }



  .pinNam {
    width: 215px;
    height: 30px;
    display: flex;
    font-size: 16px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: 400;
    /* text-align: left; */
    color: #1a1a1a;
    line-height: 30px;
    margin-bottom: 23px;
  }

  .pinNam div {
    margin-left: auto;
  }

  .el-input-number--small {
    border-radius: 15px;
    width: 100px;
  }

  .el-input-number__decrease,
  .el-input-number__increase {
    /* border-radius: 15px 0 0 15px; */
    border: 0;
  }

  .pinbuy {
    width: 370px;
    height: 56px;
    display: flex;
    justify-content: space-between;
  }

  .pinbuy div {
    width: 180px;
    height: 46px;
    font-size: 18px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    padding: 8px 0;
    /* line-height: 56px; */
  }

  .pinbuy div p {
    width: 100%;
    color: white;
    /* font-weight: 500; */
    text-align: center;
  }

  .alonebuy {
    background-color: #ffbf00;
  }

  .pinbuys {
    background-color: #ff0000;
  }

  /* 参团列表弹框 */
  .el-dialog {
    background-color: transparent;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }

  .pinGroup {
    width: 600px;
    height: 570px;
    position: relative;
    border-radius: 10px;
    background-color: white;
    margin: 0 auto;
    padding-top: 30px;
  }

  .groups-close {
    width: 34px;
    height: 34px;
    background-image: url(../../assets/coupons-close.png);
    background-size: 100%;
    position: absolute;
    top: -50px;
    right: 0px;
  }

  .reject-title {
    font-size: 20px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    font-weight: bold;
    text-align: center;
    color: #1a1a1a;
  }

  .reject-line {
    width: 30px;
    height: 6px;
    /* opacity: 1; */
    background: #2f69d2;
    margin: 10px auto;
    margin-bottom: 23px;
  }

  .groups1 .el-dialog__headerbtn {
    top: 19px;
    right: 172px;
    z-index: 999;
    /* color: white; */
  }

  .pinList {
    height: 505px;
    padding: 0 60px;
    overflow-y: scroll;
  }

  /* 滚动条整体部分 */
  .pinList::-webkit-scrollbar {
    width: 4px;
    height: 100%;
  }

  /* 轨道背景色 */
  .pinList::-webkit-scrollbar-track {
    background-color: white;
  }

  /* 滚动条的样式 */
  .pinList::-webkit-scrollbar-thumb {
    width: 4px;
    height: 100px;
    background: #2f69d2;
    border-radius: 1px;
  }

  .pinuser {
    width: 100%;
    height: 48px;
    margin-bottom: 20px;
    display: flex;
  }

  .pinuser:last-child {
    margin-bottom: 0;
  }

  .pinuser-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: thistle;
    margin-right: 12px;
  }

  .pinuser-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .pinuser-name {
    width: 110px;
    height: 48px;
    font-size: 16px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: 400;
    text-align: left;
    color: #1a1a1a;
    line-height: 48px;
    margin-right: 60px;
  }

  .pinTime {
    width: 155px;
    height: 100%;
    text-align: right;
    /* margin-right: 14px; */
  }

  .pinTime p {
    font-size: 14px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: 400;
    /* text-align: center; */
    color: #1a1a1a;
  }

  .pinTime p:first-child {
    margin-bottom: 9px;
  }

  .pinTime p:first-child span {
    color: #2f69d2;
    display: inline-block;
    margin: 0 3px;
  }

  .pinTime p:last-child span {
    display: inline-block;
    margin-left: 3px;
  }

  .addgroup {
    width: 80px;
    height: 30px;
    background: #ff0000;
    border-radius: 4px;
    font-size: 16px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: 400;
    text-align: center;
    line-height: 30px;
    color: #ffffff;
    margin-top: 9px;
    margin-left: auto;
  }

  /* 预付款详情 */
  .goods-right {
    width: 80%;
    /* height: 470px; */
    padding-top: 20px;
  }

  .goods-Name {
    width: 100%;
    font-size: 18px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: bold;
    color: #1a1a1a;
    margin-bottom: 30px;
  }

  .stock {
    width: 100%;
    height: 25px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .stock>div {
    height: 100%;
    font-size: 14px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: 400;
    /* text-align: right; */
    color: #666666;
    line-height: 25px;
  }

  .stock>div:first-child {
    width: 81px;
    /* background-color: lightblue; */
    background-image: url(../../assets/authority_.png);
    background-size: 100%;
  }

  .stock>div:nth-child(2) {
    display: flex;
  }

  .stock>div:nth-child(2) div {
    width: 90px;
    height: 18px;
    border-radius: 2px;
    background-color: rgb(255, 102, 102);
    font-size: 12px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: 400;
    text-align: center;
    line-height: 18px;
    color: #ffffff;
    margin-left: 13px;
    margin-top: 3px;
  }



  .goodsNum {
    width: 215px;
    height: 30px;
    display: flex;
    font-size: 16px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: 400;
    /* text-align: left; */
    color: #1a1a1a;
    line-height: 30px;
    /* margin-bottom: 106px; */
  }

  .goodsNum div {
    margin-left: auto;
  }
</style>