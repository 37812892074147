<template>
  <div class="indexDetails">
    <div class="nav" style="z-index: 1001">
      <div class="mine" @click="getpmessage">
        <i class="el-icon-user"></i>
      </div>
      <div class="buycart" @click="getbuycart">
        <el-badge :value="cartList.length" class="item" type="primary">
          <div class="buycart-img"></div>
        </el-badge>
      </div>
      <div class="jifen" @click="gettrue()">
        <el-tooltip
          class="item"
          effect="dark"
          content="签到领积分"
          placement="left-end"
        >
          <div class="jifen-img"></div>
        </el-tooltip>
      </div>
    </div>
    <div class="index">
      <div class="index-left">
        <ul>
          <li @click="getall(0)">全部分类</li>
          <li
            v-for="(item, k) in catelist"
            :key="item.cateId"
            @click="open(k, item.cateId)"
            :class="{ checked: index == k }"
          >
            <div class="imgbox">
              <img :src="item.cateImg" style="border-radius: 50%" alt="" />
            </div>
            <div class="classification">{{ item.cateName }}</div>
            <div class="arrow"><i class="el-icon-arrow-right"></i></div>
            <div class="open" v-show="index == k" style="z-index: 999">
              <p class="openp" style="margin-bottom: 20px">
                {{ item.cateName }}
              </p>
              <div class="cateList">
                <p
                  class="spancateList"
                  style="height: 24px; margin-right: 20px; margin-bottom: 10px"
                  v-for="ite in secondCate"
                  :key="ite.cateId"
                  @click="getall(ite.cateId)"
                >
                  {{ ite.cateName }}
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <!-- 轮播图 -->
      <div class="block">
        <el-carousel height="288px">
          <el-carousel-item v-for="item in advlist" :key="item.advId">
            <div
              style="width: 100%; height: 100%; z-index: 10"
              @click="adv(item.advType, item.advId)"
            >
              <img
                style="width: 100%; height: 100%; z-index: 10"
                :src="item.advImg"
              />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="index-right">
        <!-- 最新下单 -->
        <div class="new">
          <div class="newtop">
            <div class="newimg"></div>
          </div>
          <div class="newtext">
            <!-- <div>
                            <div ref="pdScroll">
                                <p v-for="(item,index) in newOrderlist" :key="index"><span
                                        class="username">{{item.nick_name}}</span>购买了<span
                                        class="goodsname">{{item.goods_name}}</span><span
                                        class="price">￥{{item.pay_amount}}元</span></p>
                            </div>
                        </div> -->

            <ul id="con1" ref="con1" :class="{ anim: animate == true }">
              <li v-for="(item, index) in newOrderlist" :key="index">
                <span class="username">{{ item.nick_name }}</span
                >购买了<span class="goodsname">{{ item.goods_name }}</span
                ><span class="price">￥{{ item.pay_amount }}元</span>
              </li>
            </ul>
          </div>
        </div>

        <!-- 积分抵消 -->
        <div class="new">
          <div class="newtop">
            <div class="jfimg"></div>
          </div>
          <div class="newtext">
            <!-- <div>
                            <div ref="jfScroll">
                                <p v-for="(item,index) in kjpointlist" :key="index"><span
                                        class="username">{{item.nick_name}}</span>购买了<span
                                        class="goodsname">{{item.goods_name}}</span>
                                    {{item.point}}积分抵扣
                                    <span class="price">￥{{item.point_amount}}元</span></p>
                            </div>
                        </div> -->
            <ul id="con2" ref="con2" :class="{ anim: animate == true }">
              <li v-for="(item, index) in kjpointlist" :key="index">
                <span class="username">{{ item.nick_name }}</span
                >购买了<span class="goodsname">{{ item.goods_name }}</span>
                {{ item.point }}积分抵扣
                <span class="price">￥{{ item.point_amount }}元</span>
              </li>
            </ul>
          </div>
        </div>

        <!-- 查询 -->
        <div class="query">
          <input
            type="text"
            v-model="traceCode"
            class="querytext"
            placeholder="请输入溯源码查询"
          />
          <!-- <router-link tag="div" v-mode="traceCode" class="querybtn" to="trace">查询</router-link> -->
          <div class="querybtn" @click="gettraceCode(traceCode)">查询</div>
        </div>
      </div>
    </div>

    <!-- 活动专场 -->
    <div class="activity">
      <div class="activity-top">
        <div class="activity-name"></div>
        <div class="all" @click="gethuodong(1)">查看全部</div>
        <!-- <router-link class="all" tag="div" to="/activity">查看全部</router-link> -->
      </div>
      <div class="goods">
        <div
          class="goods-type"
          v-for="item in huodongList"
          :key="item.goodsId"
          @click="goodsDetail(item.goodsId)"
        >
          <div class="goods-img">
            <img :src="item.coverImg" alt="" />
          </div>
          <!-- <p class="goods-name">青菜秧子 500g</p> -->
          <p class="goods-name">{{ item.goodsName }}</p>
          <p class="goods-pice">
            ￥{{ item.priceMin }}<span style="font-size: 16px"> 起</span>
          </p>
        </div>
      </div>
    </div>

    <!-- 拼团 -->
    <div class="pintuan">
      <div class="pintuan-top">
        <div class="pintuan-name"></div>
        <div class="pintuan-all" @click="yufuList(1)">查看全部</div>
      </div>

      <div class="qintuan-goods">
        <div
          class="goods-type"
          v-for="item in pintuanList"
          :key="item.goodsId"
          @click="goodsDetail(item.goodsId)"
        >
          <div class="goods-img">
            <img :src="item.coverImg" alt="" />
          </div>
          <!-- <p class="goods-name">青菜秧子 500g</p> -->
          <p class="goods-name">{{ item.goodsName }}</p>
          <p class="goods-pice">
            ￥{{ item.priceMin
            }}<span style="font-size: 16px; color: black"> 起</span>
          </p>
        </div>
      </div>
    </div>

    <!-- 预付商品 -->
    <div class="activity" style="margin-bottom: 40px">
      <div class="activity-top">
        <div class="advance-name"></div>
        <div class="all" @click="yufuList(2)">查看全部</div>
        <!-- <router-link tag="div" to="/goodsList" class="all">查看全部</router-link> -->
      </div>
      <div class="goods">
        <div
          class="goods-type"
          v-for="item in preGoodslist"
          :key="item.goodsId"
          @click="goodsDetail(item.goodsId)"
        >
          <div class="goods-img">
            <img :src="item.coverImg" alt="" />
          </div>
          <!-- <p class="goods-name">青菜秧子 500g</p> -->
          <p class="goods-name">{{ item.goodsName }}</p>
          <p class="goods-pice">
            ￥{{ item.priceMin }}<span style="font-size: 16px"> 起</span>
          </p>
        </div>
      </div>
    </div>

    <!-- 热门商品 -->
    <div class="hot">
      <div class="hot-top"></div>
      <el-tabs @tab-click="aa">
        <el-tab-pane
          v-for="(item, index) in hotcatelist"
          :name="index + ''"
          :label="item.cateName"
          :val="item.cateId"
          :key="item.cateId"
        >
          <div class="hot-cate">
            <div
              class="hot-goods"
              v-for="ite in hotgoodslist"
              :key="ite.goodsId"
              @click="goodsDetail(ite.goodsId)"
            >
              <div class="hot-img">
                <img :src="ite.coverImg" alt="" />
              </div>
              <div class="hot-name">
                {{ ite.goodsName }}
              </div>
              <div class="hot-pice">
                ￥{{ ite.priceMin
                }}<span style="font-size: 16px; color: black"> 起</span>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="page">
        <div style="height: 100%; margin: 0 auto; display: flex">
          <div class="home-page" @click="goFirst">首页</div>
          <el-pagination
            background
            layout="total,prev, pager, next"
            :total="rowsCount"
            :current-page.sync="account.page"
            @current-change="handleCurrentChange"
            :page-size="10"
          >
          </el-pagination>
          <div class="last-page" @click="goLast">尾页</div>
        </div>
      </div>
    </div>

    <!-- 积分领取 -->
    <el-dialog :visible.sync="addVisible" class="integral">
      <div class="qiandao">
        <div class="qiandaobtn" @click="addVisible = false"></div>
        <div class="determine">
          <div>恭喜你！</div>
          <div>获得<i>55</i>积分</div>
        </div>
        <div class="determinebtn" @click="checkIn"></div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      addVisible: false, //积分领取
      animate: false,
      catelist: [], //左侧分类一级分类
      advlist: [], //轮播图
      newOrderlist: [], //最新下单
      huodongList: [], //活动专场
      pintuanList: [], //平团
      preGoodslist: [], //预付商品
      hotcatelist: [], //热门商品分类
      kjpointlist: [], //抵扣积分
      index: -1, //显示的全部分类 的索引

      traceCode: "", //溯源码

      token: localStorage.getItem("token"),
      uid: localStorage.getItem("uid"),

      activeName: "0", // 默认选中

      account: {
        page: 1,
        pageSize: 10,
      },
      listType: 1, //预付列表
      hotgoodslist: [],
      cartList: "",
      rowsCount: 0,
      pageSize: 1,
      order: {
        type: 0,
      },

      secondCate: [],
      cateI: "110",
    };
  },
  methods: {
    gettrue() {
      if (this.token) {
        this.addVisible = true;
      }
    },
    // 左侧分类，轮播图
    getCode() {
      this.$api.post({
        path: "/goods/homeData",
        params: {
          pageNum: 1,
          pageSize: 5,
        },
        success: (data) => {
          console.log(data, "首页");
          this.catelist = data.data.Cate;
          this.advlist = data.data.Adv; //轮播图
          this.newOrderlist = data.data.NewOrder; //最新下单
          this.kjpointlist = data.data.KJPoint; //抵扣积分
          this.huodongList = data.data.HuodongGoods; //活动专场
          this.pintuanList = data.data.PintuanGoods; //拼团
          this.preGoodslist = data.data.PreGoods; //预付商品
          this.hotcatelist = data.data.HotCate; //热门商品分类

          console.log(this.hotcatelist, "热门商品");

          // let initial = {
          //   $attrs: {
          //     val: this.hotcatelist[0].cateId,
          //   },
          // };
            if(this.hotcatelist.length>0){
                this.cateI = this.hotcatelist[0].cateId
                this.getHotgoods();
            }

          if (data.code == 0 && data.msg == "会话已过期") {
            this.$router.replace("/indexDetails");
            this.$router.go("/indexDetails");
            localStorage.removeItem("token");
            localStorage.removeItem("uid");
            localStorage.removeItem("nickName");
            localStorage.removeItem("avatar");
          }
        },
      });
    },

    //轮播图类型为Html详情传值
    open(k, id) {
      this.index = k;
      // if(this.index == k){
      //     this.index = -1
      // }
      this.$api.post({
        path: "/goods/secondCate",
        params: {
          cateId: id,
          pageNum: 1,
          pageSize: 20,
        },
        success: (data) => {
          console.log(data, "二级");
          this.secondCate = data.data.Cate;

          if (data.code == 0 && data.msg == "会话已过期") {
            this.$router.replace("/indexDetails");
            this.$router.go("/indexDetails");
            localStorage.removeItem("token");
            localStorage.removeItem("uid");
            localStorage.removeItem("nickName");
            localStorage.removeItem("avatar");
          }
        },
      });
    },
    // 轮播图点击跳转判断
    adv(type, id) {
      console.log(id, 1111);
      if (type == 3) {
        this.$router.push(`/lunbo/${id}`); //html内容
      } else if (type == 4) {
        this.$router.push(`/goodsDetails/${id}`); //商品详情
      } else if (type == 2) {
        this.$api.post({
          path: "/goods/advHtmlContent",
          params: {
            advId: id,
          },
          success: (data) => {
            console.log(data);
            window.location.href = `${data.data.advContent}`; //链接
          },
        });
      }
    },

    // 溯源码页面跳转
    gettraceCode(text) {
      console.log(this.traceCode);
      console.log(text, 222);

      if (this.traceCode == "") {
        this.$message("请输入溯源码");
      } else {
        this.$router.push(`/trace/${text}`);
      }
    },

    // 签到送积分
    checkIn() {
      this.$api.post({
        path: "/user/checkIn",
        params: {},
        success: (data) => {
          if (!this.token) {
            this.$message("请先登录");
          } else {
            if (data.code == 1) {
              this.$message({
                message: data.msg,
                type: "success",
              });
              this.addVisible = false;
            } else {
              this.$message({
                message: data.msg,
                type: "warning",
              });
            }
          }
          console.log(data);
        },
      });
    },
    getbuycart() {
      if (!this.token) {
        this.$message("请先登录");
        this.$router.push("/login");
      } else {
        this.$router.push("/buycart");
      }
    },
    getpmessage() {
      if (!this.token) {
        this.$message("请先登录");
        this.$router.push("/login");
      } else {
        this.$router.push("/pmessage");
      }
    },

    // 商品详情
    goodsDetail(id) {
      console.log(id);
      this.$router.push(`/goodsDetails/${id}`);
    },

    aa(tab) {
      console.log(tab);
      this.cateI = tab.$attrs.val;
      console.log(this.cateI);
      this.account.page = 1;
      this.getHotgoods();
    },
    // 首页热卖
    getHotgoods() {
      //   console.log(tab);
      let cateId = this.cateI;
      //   console.log(cateId);
      this.$api.post({
        path: "/goods/homeHotGoods",
        params: {
          cateId,
          pageNum: this.account.page,
          pageSize: 10,
        },
        success: (data) => {
          console.log(data, "热卖商品");
          this.hotgoodslist = data.data.HotGoods;
          console.log(this.hotgoodslist);
          this.rowsCount = data.data.rowsCount;
          console.log(data.data.rowsCount);
          console.log(this.pageSize);
          // console.log(cateId);
        },
      });
    },
    goFirst() {
      this.account.page = 1;
      this.getHotgoods();
    },
    goLast() {
      this.account.page = Math.ceil(this.rowsCount / 10);
      this.getHotgoods();
    },
    handleCurrentChange(val) {
      // console.log(val);
      this.account.pageNum = val;
      this.getHotgoods();
    },

    // 跳转预付商品列表
    yufuList(val) {
      console.log(val);
      this.$router.push(`/goodsList/${val}`);
    },

    // 活动，拼团列表
    gethuodong(type) {
      this.$router.push(`/activity/${type}`);
    },
    // 跳转全部分类
    getall(cateId) {
      this.$router.push(`/allgoodsList/${cateId}`);
    },
    // 购物车商品个数长度
    getcartList() {
      this.$api.post({
        path: "/myorder/cartList",
        params: {
          uid: this.uid,
        },
        success: (data) => {
          console.log(data);
          this.cartList = data.data;
          console.log(data.data);
        },
      });
    },

    // 新下单和积分抵扣轮播
    scroll() {
      // let con1 = this.$refs.con1;
      // let con2 = this.$refs.con2;
      // con1.style.marginTop = '-30px';
      this.animate = !this.animate;
      var that = this; // 在异步函数中会出现this的偏移问题，此处一定要先保存好this的指向
      setTimeout(function () {
        that.newOrderlist.push(that.newOrderlist[0]);
        that.newOrderlist.shift();
        // con1.style.marginTop = '0px';

        that.kjpointlist.push(that.kjpointlist[0]);
        that.kjpointlist.shift();
        // con2.style.marginTop = '0px';
        that.animate = !that.animate; // 这个地方如果不把animate 取反会出现消息回滚的现象，此时把ul 元素的过渡属性取消掉就可以完美实现无缝滚动的效果了
      }, 500);
    },
  },
  created() {
    setInterval(this.scroll, 1000);
  },
  mounted() {
    this.getCode();
    this.getcartList();
    // this.getHotgoods()
    this.pdsc = 0;
    // console.log(this.$refs.pdScroll);
    // this.getorder()
  },
};
</script>
<style lang="scss">
.indexDetails {
  width: 1200px;
  margin: 140px auto;
}

.nav {
  width: 60px;
  height: 1040px;
  background: #f2f2f2;
  /* position: fixed; */
  position: fixed;
  right: 0px;
  top: 40px;
}

.mine {
  width: 60px;
  height: 60px;
  background: #2f69d2;
  font-size: 24px;
  color: white;
  text-align: center;
  line-height: 60px;
  margin-top: 230px;
}

.buycart {
  padding: 17px;
  margin-top: 40px;
}

.buycart-img {
  width: 26px;
  height: 26px;
  background-image: url(../../assets/car.png);
  background-size: 100%;
}

.jifen {
  /* width: 60px;
        height: 60px; */
  padding: 18px;
}

.jifen-img {
  width: 24px;
  height: 22px;
  background-image: url(../../assets/qiandao.png);
  background-size: 100%;
}

/* .top li:nth-child(4)::after{
        
    } */
.order {
  background-image: url(../../assets/order.png);
  background-size: 100%;
}

.collection {
  background-image: url(../../assets/collection.png);
  background-size: 100%;
}

.index {
  width: 100%;
  height: 288px;
  display: flex;
  margin-bottom: 10px;
  /* background-color: thistle; */
}

.index-left {
  width: 160px;
  height: 288px;
  margin-right: 20px;
}

.index-left .checked {
  background-color: #fff;
  color: #000;
}

.index-left li {
  position: relative;
}

.index-left .open {
  position: absolute;
  padding: 0 12px 12px 12px;
  right: -225px;
  width: 200px;
  /* height: 50px; */
  background-color: white;
  /* border: 1px solid black; */
}

.openp {
  height: 15px;
  width: 100%;
  font-size: 16px;
  font-family: PingFang SC Medium, PingFang SC Medium-Medium;
  font-weight: bold;
  /* text-align: left; */
}

.cateList {
  width: 100%;
  font-size: 14px;
  font-family: PingFang SC Medium, PingFang SC Medium-Medium;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  // border: 1px solid red;
}
.spancateList {
  // border: 1px solid red;
  font-size: 14px;
  display: flex;
  align-content: center;
  align-items: center;
}

.hot >>> .el-tabs {
  z-index: -999;
}

.hot >>> .el-tabs--top {
  z-index: -999;
}

.index-left li {
  list-style: none;
  width: 100%;
  height: 48px;
  border-bottom: 1px dashed white;
  background-color: #2f69d2;
  padding: 0 0 0 10px;
  display: flex;
  line-height: 48px;
  font-size: 16px;
  font-family: PingFang SC Medium, PingFang SC Medium-Medium;
  font-weight: 500;
  text-align: left;
  color: #ffffff;
}

.imgbox {
  width: 32px;
  height: 32px;
  /* background-color: thistle; */
  margin-top: 8px;
  margin-right: 10px;
}

.imgbox img {
  width: 32px;
  height: 32px;
}

.classification {
  width: 65px;
  height: 100%;
  line-height: 48px;
}

.arrow {
  width: 25px;
  /* height: 13px; */
  height: 100%;
  margin-left: auto;
  text-align: right;
  margin-right: 12px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.block {
  width: 700px;
  height: 100%;
}

.el-carousel__item:nth-child(2n) {
  background-color: transparent;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: transparent;
}

.index-right {
  width: 320px;
  height: 100%;
  background: #ffffff;
  /* border: 1px solid #2f69d2; */
  /* background-color: thistle; */
  margin-left: 10px;
}

.new {
  width: 320px;
  height: 114px;
  background: #ffffff;
  border: 1px solid #2f69d2;
  margin-bottom: 10px;
}

.newtop {
  width: 100%;
  /* height: 36px; */
  background: #2f69d2;
  /* padding:8px 10px ; */
  padding: 8px 0;

  /* line-height: 36px; */
}

.newimg {
  height: 20px;
  width: 62px;
  /* background-color: thistle; */
  /* margin-top: 8px; */
  margin-left: 10px;
  background-image: url(../../assets/new.png);
  background-size: 100%;
}

.jfimg {
  height: 18px;
  width: 62px;
  /* background-color: thistle; */
  /* margin-top: 8px; */
  margin-left: 10px;
  background-image: url(../../assets/jifen.png);
  background-size: 100%;
}

.newtext {
  width: 295px;
  height: 53px;
  padding: 10px;
  font-size: 12px;
  font-family: PingFang SC Regular, PingFang SC Regular-Regular;
  font-weight: 400;
  /* text-align: left; */
  color: #666666;
  line-height: 26px;
  overflow: hidden;
  transition: all 0.5s;
  /* line-height: 20px; */
}

.newtext > ul {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

li {
  list-style: none;
}

.anim {
  transition: all 0.5s;
}

.qiandao {
  width: 305px;
  height: 400px;
  /* border: 1px solid thistle; */
  position: relative;
  background-image: url(../../assets/sign.png);
  background-size: 100%;
  margin: 0 auto;
}

.qiandaobtn {
  width: 24px;
  height: 24px;
  background-color: transparent;
  position: absolute;
  top: 28px;
  right: 17px;
}

.newtext p {
  text-align: left;
}

.index-right .username,
.index-right .goodsname {
  font-size: 12px;
  font-weight: normal;
  color: #dc9126;
}

.index-right .price {
  color: red;
}

.query {
  width: 320px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #2f69d2;
  display: flex;
}

.querytext {
  width: 240px;
  height: 36px;
  background: none;
  outline: none;
  border: 0px;
}

.querybtn {
  width: 80px;
  height: 34px;
  background: #2f69d2;
  border: 1px solid #2f69d2;
  font-size: 16px;
  font-family: PingFang SC Medium, PingFang SC Medium-Medium;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
}

.querytext::-webkit-input-placeholder {
  /* placeholder颜色  */
  color: #cccccc;
  /* placeholder字体大小  */
  font-size: 12px;
  /* placeholder位置  */
  /* text-align: right; */
}

.activity {
  width: 1200px;
  height: 340px;
  background: #2f69d2;
  border-radius: 10px;
  padding-top: 25px;
  margin-bottom: 35px;
  /* position: relative; */
}

/* .coupon{
        width: 141px;
        height: 142px;
        background-image: url(../../assets/shopp.png);
        background-size: 100%;
        position: absolute;
        right: -175px;
        top: 70px;
        
    } */
/* .close{
        position: absolute;
        width: 22px;
        height: 22px;
        right: -11px;
        top: -11px;
        background-image: url(../../assets/close.png);
        background-size: 100%;
    } */
.activity-top {
  width: 1160px;
  height: 20px;
  margin: 0 auto;
  /* background-color: thistle; */
  display: flex;
  margin-bottom: 20px;
}

.activity-name {
  width: 170px;
  height: 100%;
  /* background-color: white; */
  background-image: url(../../assets/activity.png);
  background-size: 100%;
}

/* 预付商品 */
.advance-name {
  width: 202px;
  height: 100%;
  /* background-color: white; */
  background-image: url(../../assets/advance.png);
  background-size: 100%;
}

.all {
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC Medium, PingFang SC Medium-Medium;
  font-weight: 500;
  /* text-align: right; */
  color: #eeeeee;
  margin-left: auto;
  line-height: 20px;
}

.goods {
  width: 1160px;
  margin: 0 auto;
  display: flex;
  overflow: hidden;
  /* justify-content: center; */
  /* justify-content: space-between; */
}

.goods-type {
  width: 180px;
  margin-right: 15px;
}

.goods-type:last-child {
  margin-right: 0;
}

.goods-img {
  width: 180px;
  height: 180px;
  margin-bottom: 17px;
  background-color: thistle;
}

.goods-img img {
  width: 180px;
  height: 180px;
}

.goods-name {
  font-size: 18px;
  font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
  font-weight: 500;
  text-align: left;
  color: #ffffff;
  margin-bottom: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.goods-pice {
  font-size: 20px;
  font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
}

.pintuan {
  width: 1200px;
  height: 300px;
  /* background-color: thistle; */

  margin-bottom: 40px;
}

.pintuan-top {
  width: 1160px;
  height: 20px;
  display: flex;
  margin-bottom: 20px;
}

.pintuan-name {
  width: 233px;
  height: 20px;
  background-image: url(../../assets/pintuan.png);
  background-size: 100%;
}

.pintuan-all {
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC Medium, PingFang SC Medium-Medium;
  font-weight: 500;
  text-align: right;
  color: #333333;
  line-height: 20px;
  margin-left: auto;
}

.qintuan-goods {
  width: 1160px;
  display: flex;
  overflow: hidden;
  /* justify-content: center; */
  /* justify-content: space-between; */
}

.qintuan-goods .goods-name {
  font-size: 18px;
  font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
  font-weight: 500;
  text-align: left;
  color: #333;
  /* line-height: 40px; */
}

.qintuan-goods .goods-pice {
  font-size: 20px;
  font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
  font-weight: 400;
  text-align: left;
  color: #ff0000;
  /* line-height: 40px; */
}

.qintuan-goods .goods-pice span {
  font-size: 14px;
  color: black;
}

.advancebuy {
  width: 180px;
  height: 56px;
  background: #ff0000;
  color: white;
  font-size: 18px;
  font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
  font-weight: bold;
  text-align: center;
  line-height: 56px;
}

.hot {
  width: 1200px;
}

.hot .el-tabs__item {
  font-size: 18px;
  color: #999999;
}

.hot .el-tabs__item.is-active {
  font-size: 20px;
  color: black;
  font-weight: 700;
}

.hot .el-tabs__active-bar {
  width: 48px;
  height: 6px;
  background-color: #2f69d2;
  opacity: 0.8;
  bottom: 7px;
  z-index: -1px;
}

.hot-top {
  margin: 0 auto;
  width: 336px;
  height: 24px;
  background-image: url(../../assets/remai.png);
  background-size: 100%;
  margin-top: 18px;
}

.el-tabs__nav-wrap::after {
  background-color: transparent;
}

.hot-cate {
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
}

.hot-goods {
  width: 224px;
  height: 340px;
  background: #ffffff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
  border-radius: 10px;
  margin-right: 20px;
}

.hot-goods:nth-child(5n) {
  margin-right: 0px;
}

.hot-img {
  width: 224px;
  height: 224px;
  margin-bottom: 20px;
  background-color: thistle;
  border-radius: 10px 10px 0 0;
}

.hot-img img {
  width: 100%;
  height: 100%;
  background-color: thistle;
  border-radius: 10px 10px 0 0;
}

.hot-name {
  width: 185px;
  font-size: 16px;
  font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
  font-weight: 600;
  text-align: left;
  color: #333;
  line-height: 26px;
  margin: 0 auto;
  margin-bottom: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hot-pice {
  width: 185px;
  margin: 0 auto;
  font-size: 20px;
  font-family: Source Han Sans CN Bold, Source Han Sans CN Bold-Bold;
  font-weight: 700;
  text-align: left;
  color: #ff271e;
  /* line-height: 40px; */
}

/* =========================热门商品========================= */
.hotcatelist {
  width: 100%;
  height: 40px;
  display: flex;
}

.hotcatelist > div {
  font-size: 20px;
  /* width: 3%; */
  height: 100%;
  text-align: center;
  margin-right: 40px;
}

.hotp {
  width: 100%;
  height: 7px;
  background-color: #2f69d2;
  opacity: 0.8;
}

.hotpt {
  font-size: 20px;
  font-weight: 700;
  line-height: 12px;
}
</style>