<template>
    <div class="Pbox">
        <div class="navigation">
            <div class="home"></div>
            <div class="current">当前页面:</div>
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item to="/pmessage">个人中心</el-breadcrumb-item>
                <el-breadcrumb-item>设置</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="pmain">
            <div class="pmainL">
                <ul>
                    <router-link to="pmessage" tag="li">个人信息</router-link>
                    <!-- <router-link to="myorder" tag="li">我的订单</router-link> -->
                    <router-link to="myorder" tag="li">我的订单</router-link>
                    <router-link to="advanceorder" tag="li">预付订单</router-link>
                    <router-link to="mycollection" tag="li">我的收藏</router-link>
                    <router-link to="myaddress" tag="li">我的地址</router-link>
                    <router-link to="platform" tag="li">平台介绍</router-link>
                    <router-link to="install" class="liactive" style="color: white;" tag="li">设置</router-link>
                </ul>
            </div>

            <div class="install">
                <div class="modify-num">
                    <div class="modifytitle">修改手机号码</div>
                    <div class="old-num">
                        原手机号 <input type="text" v-model="modifyphon.oldphone" placeholder="请输入原手机号码">
                    </div>
                    <div class="old-num">
                        新手机号 <input type="text" v-model="modifyphon.newphone" placeholder="请输入新手机号码">
                    </div>
                    <div class="yzCode">
                        验证码 <input type="text" v-model="modifyphon.code" placeholder="请输入验证码">
                        <div class="yzCodebtn" @click="getCode(4)">
                            <span v-show="phoneShow">获取验证码</span>
                            <span v-show="!phoneShow" class="count">{{ count }} s</span>
                        </div>
                    </div>
                    <div class="preservation" @click="updateUserPhone">保存</div>
                </div>

                <div class="modify-pass">
                    <div class="modifytitle">修改密码</div>
                    <div class="old-num">
                        手机号 <input type="text" v-model="modifypass.phone" placeholder="请输入手机号码">
                    </div>
                    <div class="yzCode">
                        验证码 <input type="text" v-model="modifypass.code" placeholder="请输入验证码"
                                      style="padding: 0 18px;">
                        <div class="yzCodebtn" @click="getCode(2)">
                            <span v-show="passShow">获取验证码</span>
                            <span v-show="!passShow" class="count">{{ count }} s</span>
                        </div>
                    </div>
                    <div class="old-num">
                        新密码 <input type="password" v-model="modifypass.newpass" placeholder="请输入新密码">
                    </div>
                    <div class="new-pass">
                        确认密码 <input type="password" v-model="modifypass.pass" placeholder="请输入相同密码">
                    </div>
                    <div class="preservation" @click="updateUserPwd">保存</div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            uid: localStorage.getItem("uid"),
            nickName: localStorage.getItem("nickName"),
            address: localStorage.getItem("address"),
            avatar: localStorage.getItem("avatar"),
            // 分销推广
            extension: false,

            // 添加新地址
            addition: false,

            // 删除订单
            delorder: false,

            // 售后退款
            refund: false,

            order: {
                status: ''
            },
            form: {
                desc: ''
            },


            provinceIndex: '',
            provinceName: '',
            provinceList: [],

            cityIndex: '',
            cityName: '',
            cityList: [],

            districtIndex: '',
            districtName: '',
            districtList: [],

            streetIndex: '',
            streetName: '',
            streetList: [],

            ruleForm: {
                name: '',
                phone: '',
                adderss: ''

            },


            rules: {
                name: [
                    {required: true, message: '请输入收货人姓名', trigger: 'change'},
                    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'change' }
                ],
                phone: [
                    {required: true, message: '请输入电话号码', trigger: 'change'},
                    {min: 11, max: 11, message: '请输入正确号码', trigger: 'change'}
                ],
                adderss: [
                    {required: true, message: '请输入详细地址', trigger: 'change'},
                    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'change' }
                ],

            },

            addresList: [],//地址列表
            area_name: [],//省市区街道
            isdefault: false,//默认地址

            pointList: [],//积分列表
            point: 0,//总积分
            pointtotal: 0,//积分总数
            setVal3: '',//平台介绍


            collectList: [],//收藏列表
            collecttotal: 0,//收藏总数


            // 获取验证码
            phoneShow: true,
            passShow: true,
            count: '',
            timer: null,
            sms_id: '',


            // 修改手机号
            modifyphon: {

                oldphone: '',
                newphone: '',
                code: '',
            },

            // 修改密码
            modifypass: {
                phone: "",
                pass: '',
                newpass: '',
                code: ''
            },
            // 我的订单
            orderList: [],
            ordertotal: 0,//订单中个数
            // 预付列表
            advanceList: [],
            advanceorde: {
                status: ''
            },
            atotal: 0,
            noData: '',
            aData: ''
        }

    },
    created() {
        // this.getCityCode();
        // this.getCollectList()
    },
    methods: {
        deleteorder() {
            this.delorder = true
        },
        isdefaultaddress() {
            this.isdefault = !this.isdefault;
        },
        /*======================修改手机号===================*/
        // 获取验证码
        getCode(v) {
            if (v == 4 && this.modifyphon.newphone == '') {
                this.$message('请填写新电话号码');
                return
            } else if (v == 2 && this.modifypass.phone == '') {
                this.$message('请填写电话号码');
                return;
            } else {
                if (v == 4) {
                    this.$api.post({
                        path: "/user/getCode",
                        params: {
                            phone: this.modifyphon.newphone,
                            type: v, //1用户注册 2用户忘记密码, 3用户换绑原手机, 4用户手机号
                        },
                        success: (data) => {
                            if (data.code == 1) {
                                this.$message({
                                    message: '正在发送请稍等',
                                    type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: data.msg,
                                    type: 'warning'
                                });
                            }
                            console.log(data)
                            this.sms_id = data.data

                        },
                    });
                } else if (v == 2) {
                    this.$api.post({
                        path: "/user/getCode",
                        params: {
                            phone: this.modifypass.phone,
                            type: v, //1用户注册 2用户忘记密码, 3用户换绑原手机, 4用户手机号
                        },
                        success: (data) => {
                            console.log(data)
                            if (this.modifypass.phone == '') {
                                this.$message({
                                    showClose: true,
                                    message: '请输入电话号码',
                                    type: 'error'
                                });
                            }
                            // else{

                            // }
                            this.sms_id = data.data
                            if (data.code == 1) {
                                this.$message({
                                    message: data.msg,
                                    type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: data.msg,
                                    type: 'warning'
                                });
                            }

                        },
                    });
                }
            }
            if (!this.timer) {
                this.count = 60;
                if (v == 4) {
                    this.phoneShow = false;
                } else if (v == 2) {
                    this.passShow = false;
                }
                this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= 60) {
                        this.count--;
                    } else {
                        this.show = true;
                        clearInterval(this.timer);
                        this.timer = null;
                        this.passShow = true
                        this.phoneShow = true;
                    }
                }, 1000)
            }
        },
        // 修改手机号
        updateUserPhone() {
            if (this.modifyphon.oldphone == "") {
                this.$message('请填写原手机号');
            } else if (this.modifyphon.newphone == "") {
                this.$message('请填写新手机号');
            } else if (this.modifyphon.code == "") {
                this.$message('请填写验证码');
            } else {
                this.$api.post({
                    path: '/myorder/updateUserPhone',
                    params: {
                        uid: this.uid,
                        phone: this.modifyphon.oldphone,
                        new_phone: this.modifyphon.newphone,
                        sms_id: this.sms_id,
                        sms_code: this.modifyphon.code
                    },
                    success: (data) => {
                        console.log(data)
                        if (data.code == 1) {
                            this.$message({
                                message: data.msg,
                                type: 'success'
                            });

                            this.modifyphon.oldphone = ""
                            this.modifyphon.newphone = ""
                            this.modifyphon.code = ""
                            // this.modifyphon.pass = ""
                        }
                    }
                })
            }

        },
        // 修改密码
        updateUserPwd() {
            if (this.modifypass.phone == '') {
                this.$message('请填写手机号');
            } else if (this.modifypass.newpass == '') {
                this.$message('请填写新密码');
            } else if (this.modifypass.code == '') {
                this.$message('请填写验证码');
            } else if (this.modifypass.newpass != this.modifypass.pass) {
                this.$message('请输入相同密码');
            } else {
                this.$api.post({
                    path: '/myorder/updateUserPwd',
                    params: {
                        uid: this.uid,
                        phone: this.modifypass.phone,
                        pwd: this.modifypass.newpass,
                        sms_id: this.sms_id,
                        sms_code: this.modifypass.code
                    },
                    success: (data) => {
                        console.log(data)
                        if (data.code == 1) {
                            this.$message({
                                message: data.msg,
                                type: 'success'
                            });
                            this.modifypass.phone = ""
                            this.modifypass.newpass = ""
                            this.modifypass.code = ""
                            this.modifypass.pass = ""
                        }
                    }
                })
            }

        }
    },


    mounted() {
        // this.getAddresList()
        // this.getPointList()
        // this.getZiyuanDesc()
        // this.getOrderList()
    },
}
</script>
<style>
.Pbox {
    width: 100%;
    margin: 130px auto;
}

.pmain {
    width: 100%;
    display: flex;
}

.pmainL {
    width: 180px;
    height: 400px;
    border: 1px solid #2f69d2;
    margin-right: 10px;
}

.pmainL ul {
    width: 180px;
}

.pmainL li {
    list-style: none;
    height: 48px;
    width: 65px;
    padding: 0px 57px;
    font-size: 16px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    /* font-weight: bold; */
    text-align: left;
    color: #1a1a1a;
    line-height: 48px;
}

.liactive {
    background-color: #2f69d2;
    font-weight: bold;
    color: #ffffff;
}

.install {
    width: 600px;
}

.el-breadcrumb__item:last-child .el-breadcrumb__inner {
    color: #2f69d2;
}

.modify-num {
    width: 560px;
    height: 180px;
    background: #f6f6f6;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 10px;
}

.modifytitle {
    width: 100%;
    height: 20px;
    padding-left: 11px;
    font-size: 18px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    font-weight: bold;
    /* text-align: right; */
    color: #1a1a1a;
    line-height: 20px;
    border-left: 8px solid #2f69d2;
    margin-bottom: 20px;

}

.old-num {
    width: 100%;
    height: 30px;
    font-size: 14px;
    font-family: PingFang SC Medium, PingFang SC Medium-Medium;
    font-weight: bold;
    text-align: left;
    color: #000000;
    margin-bottom: 5px;
}

.old-num input {
    outline-style: none;
    /* border: 1px solid #ccc;  */
    border-radius: 3px;
    padding: 0 14px;
    /* width: 620px; */
    font-size: 14px;
    background: #f6f6f6;
    font-family: PingFang SC Medium, PingFang SC Medium-Medium;
    font-weight: bold;
    /* text-align: left; */
    color: #999999;

}

.yzCode {
    width: 100%;
    height: 30px;
    font-size: 14px;
    font-family: PingFang SC Medium, PingFang SC Medium-Medium;
    font-weight: bold;
    text-align: left;
    line-height: 30px;
    color: #000000;
    margin-bottom: 10px;
    display: flex;
}

.yzCode input {
    outline-style: none;
    /* border: 1px solid #ccc;  */
    border-radius: 3px;
    padding: 0 14px;
    /* width: 620px; */
    font-size: 14px;
    background: #f6f6f6;
    background: #f6f6f6;
    font-family: PingFang SC Medium, PingFang SC Medium-Medium;
    font-weight: bold;
    /* text-align: left; */
    color: #999999;
    margin-left: 18px;
}

.yzCodebtn {
    width: 80px;
    height: 26px;
    background: #2f69d2;
    border-radius: 4px;
    font-size: 14px;
    font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
    font-weight: Normal;
    text-align: center;
    color: #ffffff;
    line-height: 26px;
}

.preservation {
    width: 60px;
    height: 30px;
    background: #2f69d2;
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFang SC Medium, PingFang SC Medium-Medium;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    line-height: 30px;
}

.modify-pass {
    width: 560px;
    height: 220px;
    padding: 20px;
    background: #f6f6f6;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
}

.modify-pass input {
    outline-style: none;
    /* border: 1px solid #ccc;  */
    border-radius: 3px;
    padding: 0 14px;
    /* width: 620px; */
    font-size: 14px;
    background: #f6f6f6;
    font-family: PingFang SC Medium, PingFang SC Medium-Medium;
    font-weight: bold;
    /* text-align: left; */
    color: #999999;
    margin-left: 32px;
}

.new-pass {
    width: 100%;
    height: 30px;
    font-size: 14px;
    font-family: PingFang SC Medium, PingFang SC Medium-Medium;
    font-weight: bold;
    text-align: left;
    color: #000000;
    margin-bottom: 5px;
}

.new-pass input {
    outline-style: none;
    /* border: 1px solid #ccc;  */
    border-radius: 3px;
    padding: 0 0px;
    /* width: 620px; */
    font-size: 14px;
    background: #f6f6f6;
    font-family: PingFang SC Medium, PingFang SC Medium-Medium;
    font-weight: bold;
    /* text-align: left; */
    color: #999999;

}
</style>