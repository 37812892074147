<template>
  <div class="Pbox">
    <div class="navigation">
      <div class="home"></div>
      <div class="current">当前位置:</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/pmessage">个人中心</el-breadcrumb-item>
        <el-breadcrumb-item to="/pmessage">个人信息</el-breadcrumb-item>
        <el-breadcrumb-item>修改信息</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="pmain">
      <div class="pmainL">
        <ul>
          <router-link to="pmessage" class="liactive" style="color: white" tag="li">个人信息</router-link>
          <router-link to="myorder" tag="li">我的订单</router-link>
          <router-link to="advanceorder" tag="li">预付订单</router-link>
          <router-link to="mycollection" tag="li">我的收藏</router-link>
          <router-link to="myaddress" tag="li">我的地址</router-link>
          <router-link to="platform" tag="li">平台介绍</router-link>
          <router-link to="install" tag="li">设置</router-link>
        </ul>
      </div>
      <div class="paminR">
        <div class="revisebox">
          <div class="title">修改信息</div>
          <div class="userimgbox" style="margin-bottom: 30px;">
            <div class="userimage">
              <img v-show="imageUrl==[]" :src="avatar" alt="" />
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
            </div>

            <!-- <el-upload class="avatar-uploader" action="" accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG"
              :http-request="uploadImgs" :show-file-list="false">
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload> -->
            <el-upload class="upload-demo" action="" accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG" :http-request="uploadImgs"
              :show-file-list="false">
              <el-button size="small" style="margin-top: 10px;">修改</el-button>
              <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
            </el-upload>
          </div>
          <div class="userName" style="margin-bottom: 30px;font-size: 14px">
            用户名 <span style="display: inline-block;margin-left: 20px;font-size: 14px;color: #999;">{{nickName}}</span>
            <br>
            <input type="text" placeholder="请输入要修改的用户名" v-model="name" name="" id="" style="height: 40px;margin-top: 10px;width: 50%; border: 1px solid #ddd;padding-left: 10px;">
          </div>

          <div class="btns" @click="getrevise">保存</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        uid: localStorage.getItem("uid"),
        nickName: localStorage.getItem("nickName"),
        address: localStorage.getItem("address"),
        avatar: localStorage.getItem("avatar"),
        // 分销推广
        extension: false,

        // 添加新地址
        addition: false,

        // 删除订单
        delorder: false,

        // 售后退款
        refund: false,

        order: {
          status: "",
        },
        form: {
          desc: "",
        },

        ruleForm: {
          name: "",
          phone: "",
          adderss: "",
        },

        rules: {
          name: [
            { required: true, message: "请输入收货人姓名", trigger: "blur" },
            // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'change' }
          ],
          phone: [
            { required: true, message: "请输入电话号码", trigger: "blur" },
            { min: 11, max: 11, message: "请输入正确号码", trigger: "blur" },
          ],
          adderss: [
            { required: true, message: "请输入详细地址", trigger: "blur" },
            // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'change' }
          ],
        },

        name: '',

        pointList: [], //积分列表
        point: 0, //总积分
        pointtotal: 0, //积分总数
        setVal3: "", //平台介绍，
        imageUrl: '',
        imgurl: ''
      };
    },
    created() {
      // this.getCityCode();
      // this.getCollectList()
    },
    methods: {
      deleteorder() {
        this.delorder = true;
      },
      isdefaultaddress() {
        this.isdefault = !this.isdefault;
      },

      uploadImgs(file) {
        this.$uploadFiles('http://47.111.70.233:8762/mfxapi/multifileupload', file.file).then(res => {
          this.imageUrl = res.data[0].url
          this.imgurl = res.data[0].relative_url
          console.log(res.data, this.imageUrl, 'res2')
        })

      },
      /*================================修改信息=================================*/
      getrevise() {
        if(this.name == ''){
          this.name = this.nick_name
        }
        // if(this.imgurl == ''){
        //   // this.imgurl
        // }
        this.$api.post({
          path: '/myorder/updateUserInfo',
          params: {
            uid: this.uid,
            nick_name: this.name,
            avatar: this.imgurl
          }, success: (data) => {
            console.log(data)
            if (data.data == 1) {
              this.$message({
                message: '修改成功',
                type: 'success'
              });

              if(this.name != ''){
                localStorage.setItem("nickName", this.name);
              }
              if(this.imgurl != ''){
                localStorage.setItem("avatar", this.imageUrl);
              }
              
              
              this.$router.push('/pmessage')
            }
          }
        })
      },
    },

    mounted() {
      // this.getAddresList()
      // this.getPointList();
      // this.getZiyuanDesc()
      // this.getOrderList();
    },
  };
</script>

<style>
  .Pbox {
    width: 100%;
    margin: 130px auto;
  }

  .pmain {
    width: 100%;
    display: flex;
  }

  .el-breadcrumb__item:last-child .el-breadcrumb__inner {
    color: #2f69d2;
  }

  .pmainL {
    width: 180px;
    margin-right: 10px;
  }

  .pmainL ul {
    width: 180px;
  }

  .btns {
    width: 60px;
    height: 30px;
    background: #2f69d2;
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFang SC Medium, PingFang SC Medium-Medium;
    font-weight: bold;
    text-align: center;
    line-height: 30px;
    color: white;
  }

  .pmainL li {
    list-style: none;
    height: 48px;
    width: 65px;
    padding: 0px 57px;
    font-size: 16px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    /* font-weight: bold; */
    text-align: left;
    color: #1a1a1a;
    line-height: 48px;
  }

  .liactive {
    background-color: #2f69d2;
    font-weight: bold;
    color: #ffffff;
  }

  .paminR {
    width: 650px;
  }

  .revisebox {
    width: 540px;
    /* height: 200px; */
    background: rgb(246, 246, 246);
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }

  .title {
    width: 85px;
    height: 20px;
    font-size: 18px;
    line-height: 20px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    font-weight: bold;
    text-align: right;
    color: #1a1a1a;
    border-left: 6px solid #2f69d2;
    margin-bottom: 20px;
  }

  .userimgbox {
    width: 100%;
    height: 48px;
    display: flex;
  }

  .userimgbox .el-button--small,
  .el-button--small.is-round {
    padding: 4px 5px;
  }

  .userimgbox .el-button {
    font-size: 12px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    font-weight: 500;
    text-align: center;
    color: #2F69D2;
    border: 1px solid #2f69d2;
  }

  .userimage {
    width: 48px;
    height: 48px;
    background: #000000;
    border-radius: 50%;
    margin-right: 22px;
  }

  .userimage img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .xg {
    width: 36px;
    height: 20px;
    color: #2f69d2;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    border: 1px solid #2f69d2;
    border-radius: 2px;
    margin-top: 15px;
  }
</style>