<template>
  <div class="forgetbox">
    <div class="forget">
      <p class="forget-top">忘记密码</p>
      <div class="ftop"></div>

      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
        <el-form-item label="手机号" prop="phonenum">
          <el-input v-model="ruleForm.phonenum"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="verify" class="yzm">
          <el-input v-model="ruleForm.verify"></el-input>
          <div class="codebtn">
            <span v-show="show" @click="getCode">获取验证码</span>
            <span v-show="!show" class="count">{{ count }} s</span>
          </div>
        </el-form-item>
        <el-form-item label="密码" prop="pass">
          <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
        </el-form-item>

        <router-link tag="p" to="/login" class="flogin">去登录</router-link>

        <!-- <p>去登录</p> -->
        <!-- <el-button type="primary" class="submit" @click="submitForm()">登录</el-button> -->
        <!-- <el-form-item size="large" class="forgetbtn">
          <el-button type="primary" @click="submitForm()">登录</el-button>
        </el-form-item> -->

        <div class="forgetbtn" @click="submitForm()">登录</div>
      </el-form>
    </div>
  </div>
</template>


<script>
  export default {
    data() {
      var validatePass = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请输入密码"));
        } else {
          if (this.ruleForm.checkPass !== "") {
            this.$refs.ruleForm.validateField("checkPass");
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请再次输入密码"));
        } else if (value !== this.ruleForm.pass) {
          callback(new Error("两次输入密码不一致!"));
        } else {
          callback();
        }
      };
      return {
        imageUrl: "", //头像

        //获取验证码
        show: true,
        count: "",
        timer: null,

        ruleForm: {
          phonenum: "",
          verify: "",
          pass: "",
          checkPass: "",
        },
        sms_id: '',//验证码id

        rules: {
          phonenum: [
            { required: true, message: "请输入电话号码", trigger: "blur" },
            { min: 11, max: 11, message: "请输入正确的手机号", trigger: "blur" },
          ],
          verify: [
            { required: true, message: "请输入验证码", trigger: "blur" },
            // { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'change' }
          ],
          pass: [{ validator: validatePass, trigger: "blur" }],
          checkPass: [{ validator: validatePass2, trigger: "blur" }],
        },
        value: [],
      };
    },
    methods: {
      // 获取验证码
      getCode() {
        this.$api.post({
          path: "/user/getCode",
          params: {
            phone: this.ruleForm.phonenum,
            type: "2", //忘记密码
          },
          success: (data) => {
            console.log(data)
            this.sms_id = data.data
            if (data.code == -1) {
              this.$message({
                message: data.msg,
                type: 'warning'
              });
            }
          },
        });
        if (!this.timer) {
          this.count = 60;
          this.show = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= 60) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      },
      submitForm() {
        this.$api.post({
          path: "/user/forgetPwd",
          params: {
            sms_id: this.sms_id,
            sms_code: this.ruleForm.verify,
            phone: this.ruleForm.phonenum,
            pwd: this.ruleForm.pass
          },
          success: (data) => {
            console.log(data)
            if (this.ruleForm.pass === this.ruleForm.checkPass) {
              this.$message({
                message: data.msg,
                type: 'success'
              });
              this.$router.push({ path: "/login" });
            } 
            // if (data.code == 1) {

            // }
          },
        })
      }
    },
  };
</script>

<style lang="scss">
  .forgetbox {
    width: 100%;
    height: 457px;
    padding-top: 100px;
  }

  .forget {
    width: 311px;
    height: 400px;
    background: #ffffff;
    border-radius: 10px;
    padding: 30px 75px 0px 74px;
    margin: 0 auto;
  }

  .forget-top {
    font-size: 18px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    font-weight: bold;
    text-align: center;
    color: #2f69d2;
    letter-spacing: 4px;
    margin-bottom: 6px;
  }

  .ftop {
    width: 20px;
    height: 4px;
    background: #2f69d2;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .yzm .el-input {
    width: 80%;
  }

  .forget .el-form-item {
    margin-bottom: 10px;
  }

  /* .forget .el-form-item__label{
        text-align: left;
    } */
  .forget .el-input__inner {
    /* border: 0; */
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid #dcdfe6;
    border-radius: 0px;
  }

  .forget .el-form-item__content {
    display: flex;
  }

  .forget .submit.el-button {
    padding: 12px 115px;
    margin-top: 20px;
    margin-left: 25px;
  }

  .forget .el-button--primary {
    background-color: #2f69d2;
    border-color: #2f69d2;
    font-weight: bold;
    font-size: 18px;
    font-family: Source Han Sans CN Regular
  }

  .codebtn {
    width: 68px;
    height: 26px;
    background: #2f69d2;
    border-radius: 2px;
    font-size: 12px;
    font-family: SimHei Regular, SimHei Regular-Regular;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    line-height: 26px;
    margin-top: 10px;
  }

  .flogin {
    width: 100%;
    height: 14px;
    font-size: 14px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: 400;
    text-align: right;
    color: #2f69d2;
    margin-bottom: 40px;
    /* font-size: 14px;
    margin-bottom: 40px; */
  }

  .forgetbtn {
    width: 240px;
    height: 42px;
    background: #2f69d2;
    border-radius: 4px;
    font-size: 18px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: Regular;
    line-height: 42px;
    text-align: center;
    color: white;
    margin: 0 auto;
  }
</style>