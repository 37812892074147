<!-- 登录注册的头和尾 -->
<template>
    <div>
        <div class="logintop">
            <div class="login-logo">
                <router-link to="/" tag="div" class="logo-l"></router-link>
                <!-- <div class="logo-l"></div> -->
                <div class="logo-r"></div>
            </div>
        </div>
        <div class="login-main">
            <router-view>

            </router-view>
        </div>
        <div class="footer">
            <div class="download">

                <!-- 安卓 -->
                <div>
                    <div class="downloadimg">
                        <img src="../../assets/anzhuo.png" alt="" style="width:100%;height:100%">
                    </div>
                    <div class="text">安卓APP下载二维码</div>
                </div>

                <!-- 苹果 -->
                <div>
                    <div class="downloadimg">
                        <img src="../../assets/app.png" alt="" style="width:100%;height:100%">
                    </div>
                    <div class="text">IOSAPP下载二维码</div>
                </div>

                <!-- 微信小程序 -->
                <div>
                    <div class="downloadimg">
                        <img src="../../assets/xiaochengxu.jpg" alt="" style="width:100%;height:100%">
                    </div>
                    <div class="text">小程序二维码</div>
                </div>
            </div>

            <div class="category">
                <ul>
                    <li>种子</li>
                    <li>种苗</li>
                    <li>蔬菜</li>
                    <li>水果</li>
                    <li>农资</li>
                    <li>其他</li>
                </ul>
            </div>

            <!-- 备案号 -->
            <div class="record">
                <span>湖北紫园农业科技有限公司</span>
                <span>鄂ICP备2020020465号-1</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {

            }
        },
    }
</script>


<style lang="scss">
    .logintop {
        width: 100%;
        height: 66px;
        padding-top: 22px;
        background-color: white;
        border-bottom: 2px solid #2f69d2;
    }

    .login-logo {
        width: 1200px;
        height: 44px;
        margin: 0 auto;
        display: flex;
    }

    .logo-l {
        width: 157px;
        height: 100%;
        background-image: url(../../assets/logo.png);
        background-size: 384px 44px;
    }

    .logo-r {
        width: 195px;
        height: 100%;
        background-image: url(../../assets/logo.png);
        background-size: 384px 44px;
        background-position: 195px 0px;
        margin-left: auto;
    }

    .login-main {
        width: 100%;
        height: 630px;
        background: #000000;
        background-image: url(../../assets/loginbg.png);
        background-size: 100% 100%;
    }

    .footer {
        width: 100%;
        height: 360px;
        background-color: rgb(234, 240, 250);
        border-top: 4px solid #2f69d2;
    }

    .download {
        width: 540px;
        /* height: ; */
        margin: 40px auto;

        display: flex;
        justify-content: space-between;

        margin-bottom: 72px;
    }

    .download>div {
        width: 140px;
    }

    .downloadimg {
        width: 140px;
        height: 140px;
        /* border: 1px solid black; */
        margin-bottom: 13px;
    }

    .download .text {
        width: 100%;
        font-size: 14px;
        font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
        font-weight: 400;
        text-align: center;
        color: #1a1a1a;
    }

    .category {
        width: 490px;
        height: 14px;
        margin: 0 auto;
        margin-bottom: 12px;
    }

    .category li {
        width: 90px;
        height: 14px;
        list-style: none;
        line-height: 14px;
        float: left;
        font-size: 14px;
        font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
        font-weight: 400;
        text-align: center;
        color: #1a1a1a;
        border-right: 1px solid black;
    }

    .category li:first-child {
        text-align: left;
        width: 60px;
    }

    .category li:last-child {
        text-align: right;
        width: 60px;
        border-right: 1px solid transparent;
    }

    .record {
        width: 1200px;
        height: 50px;
        margin: 0 auto;
        border-top: 1px solid #cccccc;
        /* background-color: thistle; */
        font-size: 14px;
        line-height: 50px;
        font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
        font-weight: 400;
        text-align: center;
        color: #1a1a1a;
    }

    .record span {
        display: inline-block;
        width: 48%;
    }

    .record span:first-child {
        text-align: right;
        padding-right: 10px;
    }

    .record span:last-child {
        text-align: left;
        padding-left: 10px;
    }
</style>