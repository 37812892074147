<template>
    <div class="registerbox">
        <div class="register">
            <div class="login-top">
                <router-link tag="div" class="loginr" to="/login">登录</router-link>
                <!-- <div class="loginr">登录</div> -->
                <router-link tag="div" class="loginl" to="/register">
                    注册
                    <div></div>
                </router-link>
                <!-- <div class="loginl">注册
                    <div></div>
                </div> -->
            </div>
            <div class="regmain">
                <div class="uimg">
                    <!-- <el-upload class="avatar-uploader" action="https://www.mocky.io/v2/5185415ba171ea3a00704eed/posts/"
                        :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                        <img v-if="imageUrl" :src="imageUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload> -->
                    <el-upload class="avatar-uploader" action="" accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG"
                               :http-request="uploadImgs" :show-file-list="false">
                        <img v-if="imageUrl" :src="imageUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
                    <el-form-item label="昵称" prop="name">
                        <el-input v-model="ruleForm.name"></el-input>
                    </el-form-item>
                    <el-form-item label="地区" class="areas" size="mini">
                        <el-select v-model="provinceIndex" v-on:change="
                getCityCode(provinceIndex, 2);
                (cityIndex = ''),
                  (districtIndex = ''),
                  (streetIndex = ''),
                  changeLocationValue(provinceIndex);
              " placeholder="省">
                            <el-option v-for="item in provinceList" :key="item.areaId" :label="item.areaName"
                                       :value="item.areaId">
                            </el-option>
                        </el-select>

                        <el-select v-model="cityIndex" v-on:change="
                getCityCode(cityIndex, 3);
                (districtIndex = ''),
                  (streetIndex = ''),
                  changeLocationValue2(cityIndex);
              " placeholder="市">
                            <el-option v-for="item in cityList" :key="item.areaId" :label="item.areaName"
                                       :value="item.areaId">
                            </el-option>
                        </el-select>

                        <el-select v-model="districtIndex" v-on:change="
                getCityCode(districtIndex, 4);
                (streetIndex = ''), changeLocationValue3(districtIndex);
              " placeholder="区/县">
                            <el-option v-for="item in districtList" :key="item.areaId" :label="item.areaName"
                                       :value="item.areaId">
                            </el-option>
                        </el-select>

                        <el-select v-model="streetIndex" placeholder="街道" @change="changeLocationValue4(streetIndex)">
                            <el-option v-for="item in streetList" :key="item.areaId" :label="item.areaName"
                                       :value="item.areaId">
                            </el-option>
                        </el-select>
                        <!-- 
                        <el-select v-model="provinceIndex" v-on:change="getCityCode(provinceIndex, 2);
            cityIndex = '',districtIndex=''
            " placeholder="请选择省">
                            <el-option v-for="item in provinceList" :key="item.areaId" :label="item.areaName"
                                :value="item.areaId">
                            </el-option>
                        </el-select>

                        <el-select v-model="cityIndex" v-on:change="getCityCode(cityIndex, 3);
            districtIndex=''
            " placeholder="请选择市">
                            <el-option v-for="item in cityList" :key="item.areaId" :label="item.areaName"
                                :value="item.areaId">
                            </el-option>
                        </el-select>

                        <el-select v-model="districtIndex" placeholder="请选择区/县">
                            <el-option v-for="item in districtList" :key="item.areaId" :label="item.areaName"
                                :value="item.areaId">
                            </el-option>
                        </el-select> -->
                    </el-form-item>
                    <el-form-item label="邀请码">
                        <el-input v-model="ruleForm.invite"></el-input>
                    </el-form-item>
                    <el-form-item label="电话号码" prop="phonenum">
                        <el-input v-model="ruleForm.phonenum"></el-input>
                    </el-form-item>
                    <el-form-item label="验证码" prop="verify" class="yzm">
                        <el-input v-model="ruleForm.verify"></el-input>
                        <div class="codebtn">
                            <span v-show="show" @click="getCode">获取验证码</span>
                            <span v-show="!show" class="count">{{ count }} s</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="密码" prop="pass">
                        <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="checkPass">
                        <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-button type="primary" class="submit" @click="getRegister">提交</el-button>
                </el-form>
                <!-- <div class="regbtn">确定</div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else {
                if (this.ruleForm.checkPass !== '') {
                    this.$refs.ruleForm.validateField('checkPass');
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.ruleForm.pass) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            // imageUrl: '', //头像

            provinceIndex: "",
            provinceName: "",
            provinceList: [],
            provinceId: "",

            cityIndex: "",
            cityName: "",
            cityList: [],
            cityId: [],

            districtIndex: "",
            districtName: "",
            districtList: [],
            districtId: [],

            streetIndex: "",
            streetName: "",
            streetList: [],
            streetId: [],

            select: [],
            //获取验证码
            show: true,
            count: '',
            timer: null,

            ruleForm: {
                name: '',  //用户姓名
                invite: '',//邀请码
                phonenum: '',//电话号码
                verify: '', //验证码
                pass: '', //密码
                checkPass: '',//确认密码
                region: '',
                zipCode: '',
                areaName: '',
                cityid: '',
                sms_id: '',//验证码id
            },
            rules: {
                name: [
                    {required: true, message: '请输入昵称', trigger: 'change'},
                    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'change' }
                ],
                phonenum: [
                    {required: true, message: '请输入电话号码', trigger: 'change'},
                    {min: 11, max: 11, message: '请输入正确的手机号', trigger: 'change'}
                ],
                verify: [
                    {required: true, message: '请输入验证码', trigger: 'change'},
                    // { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'change' }
                ],
                pass: [
                    {validator: validatePass, trigger: 'change'}
                ],
                checkPass: [
                    {validator: validatePass2, trigger: 'change'}
                ],

            },
            value: [],
            imageUrl: '',
            imgurl: ''
        }
    },
    created() {
        this.getCityCode();
    },
    methods: {
        // 上传头像
        uploadImgs(file) {
            this.$uploadFiles('http://47.111.70.233:8762/mfxapi/multifileupload', file.file).then(res => {
                this.imageUrl = res.data[0].url
                this.imgurl = res.data[0].relative_url
                console.log(res.data, this.imageUrl, 'res2')
            })

        },
        // 联动
        handleChange(value) {
            console.log(value);
        },


        // 获取验证码
        getCode() {
            if (this.ruleForm.phonenum == '') {
                this.$message({
                    showClose: true,
                    message: '请输入手机号',
                    type: 'error'
                });
            } else {
                this.$api.post({
                    path: "/user/getCode",
                    params: {
                        phone: this.ruleForm.phonenum,
                        type: "1", //注册
                    },
                    success: (data) => {
                        console.log(data)
                            this.ruleForm.sms_id = data.data
                    },
                });
                if (!this.timer) {
                    this.count = 60;
                    this.show = false;
                    this.timer = setInterval(() => {
                        if (this.count > 0 && this.count <= 60) {
                            this.count--;
                        } else {
                            this.show = true;
                            clearInterval(this.timer);
                            this.timer = null;
                        }
                    }, 1000)
                }
            }

        },

        // 省 下拉列表值
        changeLocationValue(val) {
            //locations是v-for里面的也是datas里面的值
            let obj = {};
            obj = this.provinceList.find((item) => {
                return item.areaId === val;
            });
            this.provinceName = obj.areaName;
            this.provinceId = obj.areaId
            console.log(obj.areaName)
            console.log(obj.areaId)
        },

        // 市 下拉列表值
        changeLocationValue2(val) {
            //locations是v-for里面的也是datas里面的值
            let obj2 = {};
            obj2 = this.cityList.find((item) => {
                return item.areaId === val;
            });
            this.cityName = obj2.areaName;
            this.cityId = obj2.areaId
            console.log(obj2.areaId)
        },

        // 区/县 下拉列表值
        changeLocationValue3(val) {
            //locations是v-for里面的也是datas里面的值
            let obj3 = {};
            obj3 = this.districtList.find((item) => {
                return item.areaId === val;
            });
            this.districtName = obj3.areaName;
            this.districtId = obj3.areaId
            console.log(obj3.areaName)
            console.log(obj3.areaId)
        },

        // 街道 下拉列表值
        changeLocationValue4(val) {
            //locations是v-for里面的也是datas里面的值
            let obj4 = {};
            obj4 = this.streetList.find((item) => {
                return item.areaId === val;
            });
            this.streetName = obj4.areaName;
            this.streetId = obj4.areaId
            console.log(obj4.areaName)
            console.log(obj4.areaId)
        },

        getCityCode(pid, type) {
            this.$api.post({
                path: `/user/getAreas`,
                params: {
                    pid: pid ? pid : 0
                },
                success: (data) => {
                    if (!type) {
                        this.provinceList = data.data;
                    }
                    console.log(data);
                    console.log(type);
                    switch (type) {
                        case 2:
                            this.cityList = data.data;
                            break;
                        case 3:
                            this.districtList = data.data;
                            break;
                        case 4:
                            this.streetList = data.data;
                            break;
                        default:
                            break;
                    }
                },
            })
            console.log(this.provinceIndex)
        },


        // 注册
        getRegister() {
            this.$api.post({
                path: "/user/userRegister",
                params: {
                    sms_id: this.ruleForm.sms_id,
                    sms_code: this.ruleForm.verify, //验证码
                    share_code: this.ruleForm.invite, //邀请码
                    nick_name: this.ruleForm.name, // 昵称
                    avatar: this.imgurl,
                    province_id: this.provinceId, //省id
                    city_id: this.cityId,//市id
                    district_id: this.districtId,//区id
                    street_id: this.streetId,
                    phone: this.ruleForm.phonenum,
                    pwd: this.ruleForm.pass
                },
                success: (data) => {
                    if (data.code == 1) {
                        this.$message({
                            message: data.msg,
                            type: 'success'
                        });

                        this.$router.push({path: "/login"});
                    } else {
                        this.$message({
                            message: data.msg,
                            type: 'warning'
                        });
                    }
                },

            })
        }
    },
    mounted() {
        // this.getCityCode()
        // this.getCity()
    },
}
</script>

<style>
.registerbox {
    width: 100%;
    height: 305;
    padding-top: 55px;
}

.registerbox .el-select .el-input .el-select__caret {
    color: transparent;
}

.register {
    width: 310px;
    height: 500px;
    padding: 30px 75px 0 75px;
    background: #ffffff;
    border-radius: 10px;
    margin: 0 auto;
}

.login-top {
    height: 30px;
    width: 200px;
    display: flex;
    /* border: 1px solid black; */
    margin: 0 auto;
    margin-bottom: 20px;
}

.loginl {
    width: 50%;
    height: 100%;
    font-size: 18px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    font-weight: bold;
    text-align: center;
    color: #2f69d2;

}

.loginl div {
    width: 20px;
    height: 3px;
    border-bottom: 4px solid #2f69d2;
    margin: 0 auto;
}

.loginr {
    width: 50%;
    height: 100%;
    font-size: 18px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    /* font-weight: bold; */
    text-align: center;
}

.regmain {
    width: 100%;
}

.uimg {
    width: 50px;
    height: 50px;
    margin: 0 auto;
}

.yzm .el-input {
    width: 80%;
}

.avatar-uploader .el-upload {
    width: 48px;
    height: 48px;
    border: 1px dashed #d9d9d9;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    /* line-height: 48px;
    text-align: center; */
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 48px;
    height: 48px;
    line-height: 48px;
    text-align: center;
}

.avatar {
    width: 48px;
    height: 48px;
    display: block;
    border-radius: 50%;
}


.verifybtn {
    width: 68px;
    height: 26px;
    background: #2f69d2;
    border-radius: 2px;
    font-size: 12px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: 400;
    text-align: center;
    line-height: 26px;
    color: #ffffff;
}

.form span {
    display: inline-block;
    width: 70px;
    font-size: 14px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: 400;
    text-align: right;
    color: #999999;
}

.form p {
    height: 20px;
}

.form input {
    width: 150px;
    outline-style: none;
    border-bottom: 1px solid #ccc;
    margin-left: 15px;
    font-size: 14px;
    background: #f6f6f6;
    font-family: PingFang SC Medium, PingFang SC Medium-Medium;
    color: #999999;

}

.form .el-input__inner {
    border: 0px;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
}

.regbtn {
    width: 240px;
    height: 42px;
    background: #2f69d2;
    border-radius: 4px;
    font-size: 18px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    line-height: 42px;
    margin: 25px auto;
}

.regmain .el-form-item {
    margin-bottom: 0px;
}

/* .regmain .el-form-item__label{
    text-align: left;
} */
.regmain .el-input__inner {
    /* border: 0; */
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid #DCDFE6;
    border-radius: 0px;
}

.regmain .el-form-item__content {
    display: flex;
}

.regmain .submit.el-button {
    padding: 12px 142px;
    margin-top: 20px;
}

.codebtn {
    width: 68px;
    height: 26px;
    background: #2f69d2;
    border-radius: 2px;
    font-size: 12px;
    font-family: SimHei Regular, SimHei Regular-Regular;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    line-height: 26px;
    margin-top: 10px;
}

.areas .el-input__inner {
    padding: 0;
}
</style>