<template>
    <div class="findlist">
        当前搜索商品：{{goodsName}}
        <div class="findgoods">
            <div class="find-goods" v-for="item in findgoodslist" :key="item.goodsId"
                @click="goodsDetail(item.goodsId)">
                <div class="find-img">
                    <img :src="item.coverImg" alt="">
                </div>
                <div class="find-name">
                    {{item.goodsName}}
                </div>
                <div class="find-pice">
                    ￥{{item.priceMin}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                findgoodslist: [],
                goodsName: this.$route.params.val
            }
        },
        methods: {
            // thisfind(){
            //     this.findgoodslist = JSON.parse(localStorage.getItem('findgoodslist'))
            // },

            getFind() {
                this.$api.post({
                    path: '/goods/searchGoods',
                    params: {
                        keyword: this.$route.params.val,
                        pageNum: 1,
                        pageSize: 10
                    },
                    success: (data) => {
                        console.log(data)
                        this.findgoodslist = data.data.Goods
                        // this.getfind()

                    }
                })
            },
            // 商品详情
            goodsDetail(id) {
                console.log(id)
                this.$router.push(`/goodsDetails/${id}`)
            },
        },
        created() {
            // console.log(this.$route.params.val)
            // this.getfind()
            this.getFind()
            this.goodsName = this.$route.params.val
            // this.thisfind()
        },

        mounted() {

        },
    }
</script>

<style>
    .findlist {
        width: 1200px;
        margin: 140px auto;
    }

    .findgoods {
        width: 1200px;
        display: flex;
        flex-wrap: wrap;

    }

    .find-goods {
        width: 224px;
        height: 340px;
        background: #ffffff;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
        margin-bottom: 20px;
        border-radius: 10px;
        margin-right: 20px;
    }

    .find-goods:nth-child(5n) {
        margin-right: 0px;
    }

    .find-img {
        width: 224px;
        height: 224px;
        margin-bottom: 20px;
        background-color: thistle;
        border-radius: 10px 10px 0 0;
    }

    .find-img img {
        width: 100%;
        height: 100%;
        background-color: thistle;
        border-radius: 10px 10px 0 0;
    }

    .find-name {
        width: 185px;
        font-size: 16px;
        font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
        font-weight: 600;
        text-align: left;
        color: #333;
        line-height: 26px;
        margin: 0 auto;
        margin-bottom: 16px;
    }

    .find-pice {
        width: 185px;
        margin: 0 auto;
        font-size: 20px;
        font-family: Source Han Sans CN Bold, Source Han Sans CN Bold-Bold;
        font-weight: 700;
        text-align: left;
        color: #ff271e;
        /* line-height: 40px; */
    }
</style>