<template>
  <!-- 商品列表分类 -->

  <div class="goodsList">
    <!-- 面包屑 -->
    <div class="navigation">
      <div class="home"></div>
      <div class="current">当前页面:</div>
      <router-link tag="div" to="/trace">全部商品</router-link>
    </div>

    <div class="goods-sort">
      <div class="sort-type" v-for="(item, k) in goodsList" :key="item.cateId">
        <div class="sortname">{{ item.cateName }}</div>
        <div class="sort">
          <div
            v-for="i in item.subCate"
            :key="i.cateId"
            @click="goodsLists(i.cateId)"
          >
            {{ i.cateName }}
          </div>
        </div>
        <div class="sortbtn" @click="active(k)">
          <div>
            <span v-if="index == k && !imgShow">展开</span
            ><span v-else>收起</span>
          </div>
          <div>
            <img
              style="width: 100%; height: 100%"
              v-if="index == k && !imgShow"
              src="../../assets/xia.png"
              alt=""
            />
            <img
              style="width: 100%; height: 100%"
              v-else
              src="../../assets/shang.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="goods-list">
      <!-- 商品详情 -->
      <div
        class="hot-goods"
        v-for="item in pintuanlist"
        :key="item.goodsId"
        @click="goodsDetail(item.goodsId)"
      >
        <div class="hot-img">
          <img :src="item.coverImg" alt="" />
        </div>
        <div class="hot-name">{{ item.goodsName }}</div>
        <div class="hot-pice">￥{{ item.priceMin }}</div>
      </div>
      <!-- <div class="hot-goods">
        <div class="hot-img">
          <img src="" alt="" />
        </div>
        <div class="hot-name">这里是商品名称这里是 商品名称商...</div>
        <div class="hot-pice">￥1.80</div>
      </div> -->
    </div>

    <div class="page">
      <div style="height: 100%; margin: 0 auto; display: flex">
        <div class="home-page" @click="goFirst">首页</div>
        <el-pagination
          background
          :current-page.sync="list.pageNum"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="list.pageSize"
          layout="total,prev, pager, next"
          :total="pagetotal"
        >
        </el-pagination>

        <div class="last-page" @click="goLast">尾页</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: "",
      goodsList: [],
      pintuanlist: [],
      rowsCount: "",
      index: 0,
      imgShow: false,
      list: {
        pageNum: 1,
        pageSize: 10,
      },
      pagetotal: 0,
      // cateId:this.$route.params.cateId
      ids:0,
    };
  },
  methods: {
    active(k) {
      this.index = k;
      this.imgShow = !this.imgShow;
    },
    goodsDetail(id) {
      console.log(id);
      this.$router.push(`/goodsDetails/${id}`);
    },

    all() {
      this.$api.post({
        path: "/myorder/getCateList",
        params: {},
        success: (data) => {
          // console.log(data);
          this.goodsList = data.data;
        },
      });
    },

    allgoodsLists() {
      this.$api.post({
        path: "/goods/secondCateGoods",
        params: {
          cateId: this.ids,
          pageNum: this.list.pageNum,
          pageSize: 10,
        },
        success: (data) => {
          console.log(data);
          this.pintuanlist = data.data.Goods;
          this.pagetotal = data.data.rowsCount;
          // console.log(this.pagetotal);
          if (data.code == 0 && data.msg == "会话已过期") {
            this.$router.replace("/indexDetails");
            this.$router.go("/indexDetails");
            localStorage.removeItem("token");
            localStorage.removeItem("uid");
            localStorage.removeItem("nickName");
            localStorage.removeItem("avatar");
          }
        },
      });
    },

    // 分页导航
    handleCurrentChange(val) {
      // console.log(val);
      this.list.pageNum = val;
      this.allgoodsLists();
    },
    handleSizeChange(val) {
      this.list.pageSize = val;
      this.allgoodsLists();
    },

    goodsLists(id) {
      // console.log(id);
      this.ids=id
      this.list.pageNum=1
      this.$api.post({
        path: "/goods/secondCateGoods",
        params: {
          cateId: id,
          // pageNum: 1,
          pageNum: this.list.pageNum,
          pageSize: 10,
        },
        success: (data) => {
          // console.log("成");
          console.log(data);
          this.pintuanlist = data.data.Goods;
          this.pagetotal = data.data.rowsCount;
          // console.log(id);
        },
      });
    },
    goFirst() {
      this.list.pageNum = 1;
      this.allgoodsLists();
    },
    goLast() {
      this.list.pageNum = Math.ceil(this.pagetotal / 10);
       this.allgoodsLists();
      console.log(this.list.pageNum);
    },
  },
  mounted() {
    // this.allList();
    this.ids=this.$route.params.cateId
    this.allgoodsLists();
    this.all();
    // console.log(this.$route.params.cateId,'ididdiiddi');
  },
  created() {
    // console.log(this.$route.params.val);
    // this.getlist();
  },
};
</script>

<style>
.goodsList {
  width: 1200px;
  margin: 130px auto;
}

/* 导航栏/面包屑 */
.navigation {
  width: 100%;
  height: 20px;
  font-size: 14px;
  font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
  font-weight: 500;
  display: flex;
  margin-bottom: 17px;
  margin-left: 5px;
}

.home {
  width: 16px;
  height: 16px;
  /* background-color: thistle; */
  background-image: url(../../assets/home.png);
  background-size: 100%;
  margin-right: 10px;
}

.current {
  color: #666666;
  margin-right: 10px;
}

.navigation div:last-child {
  color: #2f69d2;
}

.goods-sort {
  width: 1160px;
  /* height: 190px; */
  padding: 20px;
  background-color: #f6f6f6;
  margin-bottom: 20px;
}

.sort-type {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.sortname {
  width: 65px;
  font-size: 16px;
  font-family: PingFang SC Medium, PingFang SC Medium-Medium;
  font-weight: bold;
  text-align: left;
  color: #1a1a1a;
  /* line-height: 26px; */
}

.sort {
  width: 995px;
  font-size: 14px;
  font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
  font-weight: 500;
  text-align: left;
  color: #999999;
  list-style: 20px;
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  margin-right: 10px;
}

.sort div {
  /* width: 60px; */
  text-align: center;
  margin-left: 20px;
}

.sortbtn {
  width: 50px;
  display: flex;
}

.sortbtn div:first-child {
  font-size: 14px;
  font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
  font-weight: 500;
  text-align: left;
  color: #2f69d2;
  margin-right: 5px;
}

.sortbtn div:last-child {
  width: 10px;
  height: 6px;
  /* background-image: url(../../assets/shang.png); */
  background-size: 100%;
  margin-top: -5px;
  margin-left: 5px;
}

/* 商品列表 */
.goods-list {
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  margin-bottom: 45px;
}

.hot-goods {
  width: 224px;
  height: 340px;
  background: #ffffff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
  border-radius: 10px;
  margin-right: 20px;
}

.hot-goods:nth-child(5n) {
  margin-right: 0px;
}

.hot-img {
  width: 224px;
  height: 224px;
  margin-bottom: 20px;
  background-color: thistle;
  border-radius: 10px 10px 0 0;
}

.hot-img img {
  width: 100%;
  height: 100%;
  background-color: thistle;
  border-radius: 10px 10px 0 0;
}

.hot-name {
  width: 185px;
  font-size: 16px;
  font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
  font-weight: 600;
  text-align: left;
  color: #333;
  line-height: 26px;
  margin: 0 auto;
  margin-bottom: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hot-pice {
  width: 185px;
  margin: 0 auto;
  font-size: 20px;
  font-family: Source Han Sans CN Bold, Source Han Sans CN Bold-Bold;
  font-weight: 700;
  text-align: left;
  color: #ff271e;
  /* line-height: 40px; */
}

.page {
  width: 100%;
  height: 40px;
  margin: 0 auto;
  /* background-color: thistle; */
  display: flex;
  /* border: 1px solid red; */
}

.home-page {
  width: 60px;
  height: 36px;
  background: white;
  border: 1px solid #cccccc;
  font-size: 14px;
  font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
  font-weight: 400;
  text-align: center;
  color: #1a1a1a;
  line-height: 36px;
  margin-right: 10px;
}

.last-page {
  width: 60px;
  height: 36px;
  background: white;
  border: 1px solid #cccccc;
  font-size: 14px;
  font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
  font-weight: 400;
  text-align: center;
  color: #1a1a1a;
  line-height: 36px;
  margin-left: 10px;
}

.el-pagination .btn-prev,
.el-pagination .btn-next {
  width: 36px;
  height: 36px;
  background-color: #cccccc;
}

.el-pager li {
  width: 36px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  background-color: #cccccc;
}

.rl-pager .active {
  background-color: #2f69d2;
}
</style>