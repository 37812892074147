<template>
    <div class="Pbox">
        <div class="navigation">
            <div class="home"></div>
            <div class="current">当前位置:</div>
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item to="/pmessage">个人中心</el-breadcrumb-item>
                <el-breadcrumb-item>平台介绍</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="pmain">
            <div class="pmainL">
                <ul>
                    <router-link to="pmessage" tag="li">个人信息</router-link>
                    <router-link to="myorder" tag="li">我的订单</router-link>
                    <router-link to="advanceorder" tag="li">预付订单</router-link>
                    <router-link to="mycollection" tag="li">我的收藏</router-link>
                    <router-link to="myaddress" tag="li">我的地址</router-link>
                    <router-link to="platform" class="liactive" style="color: white;" tag="li">平台介绍</router-link>
                    <router-link to="install" tag="li">设置</router-link>
                </ul>
            </div>

            <div class="platform">
                <div class="platformtitle">平台介绍</div>
                <div class="platformtext" v-html="setVal3">

                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                uid: localStorage.getItem("uid"),
                nickName: localStorage.getItem("nickName"),
                address: localStorage.getItem("address"),
                avatar: localStorage.getItem("avatar"),
                // 分销推广
                extension: false,

                // 添加新地址
                addition: false,

                // 删除订单
                delorder: false,

                // 售后退款
                refund: false,

                order: {
                    status: ''
                },
                form: {
                    desc: ''
                },


                provinceIndex: '',
                provinceName: '',
                provinceList: [],

                cityIndex: '',
                cityName: '',
                cityList: [],

                districtIndex: '',
                districtName: '',
                districtList: [],

                streetIndex: '',
                streetName: '',
                streetList: [],

                ruleForm: {
                    name: '',
                    phone: '',
                    adderss: ''

                },


                rules: {
                    name: [
                        { required: true, message: '请输入收货人姓名', trigger: 'change' },
                        // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'change' }
                    ],
                    phone: [
                        { required: true, message: '请输入电话号码', trigger: 'change' },
                        { min: 11, max: 11, message: '请输入正确号码', trigger: 'change' }
                    ],
                    adderss: [
                        { required: true, message: '请输入详细地址', trigger: 'change' },
                        // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'change' }
                    ],

                },

                addresList: [],//地址列表
                area_name: [],//省市区街道
                isdefault: false,//默认地址

                pointList: [],//积分列表
                point: 0,//总积分
                pointtotal: 0,//积分总数
                setVal3: '',//平台介绍


                collectList: [],//收藏列表
                collecttotal: 0,//收藏总数


                // 获取验证码
                phoneShow: true,
                passShow: true,
                count: '',
                timer: null,
                sms_id: '',


                // 修改手机号
                modifyphon: {
                    oldphone: '',
                    newphone: '',
                    code: '',
                },

                // 修改密码
                modifypass: {
                    phone: "",
                    pass: '',
                    newpass: '',
                    code: ''
                },
                // 我的订单
                orderList: [],
                ordertotal: 0,//订单中个数
                // 预付列表
                advanceList: [],
                advanceorde: {
                    status: ''
                },
                atotal: 0,
                noData: '',
                aData: ''
            }

        },
        created() {
            this.getCityCode();
            this.getCollectList()
        },
        methods: {
            deleteorder() {
                this.delorder = true
            },
            isdefaultaddress() {
                this.isdefault = !this.isdefault;
            },


            /*==========================我的积分===========================*/
            getPointList() {
                this.$api.post({
                    path: '/myorder/getPointList',
                    params: {
                        uid: this.uid,
                        pageNum: 1,
                        pageSize: 6
                    },
                    success: (data) => {
                        console.log(data)
                        this.pointList = data.data.list
                        this.point = data.data.point
                        this.pointtotal = data.data.total
                    }
                })
            },
            /*====================订单列表=========================*/
            getOrderList() {
                this.$api.post({
                    path: '/myorder/getOrderList',
                    params: {
                        uid: this.uid,
                        order_type: 2,
                        status: this.order.status,
                    }, success: (data) => {
                        console.log(data);
                        if (data.data.list.length == 0) {
                            this.noData = "暂无数据";
                            this.orderList = data.data.list
                            this.ordertotal = 0
                        } else {
                            this.orderList = data.data.list
                            this.ordertotal = data.data.total
                        }

                    }
                })
            },
            getType(v) {
                this.order.status = v;
                console.log(v);
                this.getOrderList()
            },
            /*========================预付订单=======================*/
            getadvance() {
                this.$api.post({
                    path: '/myorder/getOrderList',
                    params: {
                        uid: this.uid,
                        order_type: 3,
                        status: this.advanceorder.status,
                    },
                    success: (data) => {
                        console.log(data)
                        if (data.data.list.length == 0) {
                            this.aData = "暂无数据";
                            this.advanceList = data.data.list
                            this.atotal = 0
                        } else {
                            this.advanceList = data.data.list
                            this.atotal = data.data.total
                        }


                    }

                })
            },
            getadvanceType(v) {
                this.advanceorder.status = v;
                console.log(v);
                this.getadvance()
            },

            /*==========================我的地址===========================*/


            // 地址四级联动
            getCityCode(pid, type) {
                this.$api.post({
                    path: `/user/getAreas`,
                    params: {
                        pid: pid ? pid : 0
                    },
                    success: (data) => {
                        if (!type) {
                            this.provinceList = data.data;
                        }
                        console.log(data);
                        console.log(type);
                        switch (type) {
                            case 2:
                                this.cityList = data.data;
                                break;
                            case 3:
                                this.districtList = data.data;
                                break;
                            case 4:
                                this.streetList = data.data;
                                break;
                            default:
                                break;
                        }
                    },
                })
                console.log(this.provinceIndex)
            },


            // 省 下拉列表值
            changeLocationValue(val) {
                //locations是v-for里面的也是datas里面的值
                let obj = {};
                obj = this.provinceList.find((item) => {
                    return item.areaId === val;
                });
                this.provinceName = obj.areaName;
                console.log(obj.areaName)
            },

            // 市 下拉列表值
            changeLocationValue2(val) {
                //locations是v-for里面的也是datas里面的值
                let obj2 = {};
                obj2 = this.cityList.find((item) => {
                    return item.areaId === val;
                });
                this.cityName = obj2.areaName;
                console.log(obj2.areaName)
            },

            // 区/县 下拉列表值
            changeLocationValue3(val) {
                //locations是v-for里面的也是datas里面的值
                let obj3 = {};
                obj3 = this.districtList.find((item) => {
                    return item.areaId === val;
                });
                this.districtName = obj3.areaName;
                console.log(obj3.areaName)
            },

            // 街道 下拉列表值
            changeLocationValue4(val) {
                //locations是v-for里面的也是datas里面的值
                let obj4 = {};
                obj4 = this.streetList.find((item) => {
                    return item.areaId === val;
                });
                this.streetName = obj4.areaName;
                console.log(obj4.areaName)
            },

            // 地址列表
            getAddresList() {
                this.$api.post({
                    path: '/myorder/getAddresList',
                    params: {
                        pageNum: 1,
                        pageSize: 10,
                        uid: this.uid
                    },
                    success: (data) => {
                        console.log(data, "地址")
                        this.addresList = data.data.list
                    }
                })
            },

            // 添加地址
            getaddAddres() {
                this.area_name.push(this.provinceName, this.cityName, this.districtNamethis, this.streetName)
                this.$api.post({
                    path: '/myorder/addAddress',
                    params: {
                        uid: this.uid,
                        address_id: 0,//新增id传0，编辑传对应id
                        receiver: this.ruleForm.name,
                        receiver_phone: this.ruleForm.phone,
                        area_name: this.area_name.join(""),
                        address_detail: this.ruleForm.adderss,
                        is_default: 0//是否默认：0非默认；1默认。C端用户有多个地址，每个用户只能有一个默认地址
                    },
                    success: (data) => {
                        console.log(data)
                        if (data.code == 1) {
                            this.$message({
                                message: data.msg,
                                type: "success",
                            });
                            this.getAddresList()
                            this.area_name = []
                            this.ruleForm.name = ""
                            this.ruleForm.phone = ""
                            this.ruleForm.adderss = ""
                            // this.$set(this.editForm, 'provinceIndex', '')
                        }
                    }
                })

                this.addition = false
            },
            // 删除地址
            getdelAddress(val) {
                this.$api.post({
                    path: '/myorder/delAddress',
                    params: {
                        address_id: val
                    },
                    success: (data) => {
                        console.log(data)
                        if (data.code == 1) {
                            this.$message({
                                message: data.msg,
                                type: "success",
                            });
                            this.getAddresList()
                        }
                    }
                })
            },
            /*=========================我的收藏=====================*/
            getCollectList() {
                this.$api.post({
                    path: '/myorder/getCollectList',
                    params: {
                        uid: this.uid,
                        pageNum: 1,
                        pageSize: 8
                    },
                    success: (data) => {
                        console.log(data)
                        this.collectList = data.data.list
                        this.collecttotal = data.data.total
                    }
                })
            },

            // 商品详情
            goodsDetail(id) {
                console.log(id)
                this.$router.push(`/goodsDetails/${id}`)
            },
            /*=========================平台介绍=========================*/

            getZiyuanDesc() {
                this.$api.post({
                    path: '/myorder/getZiyuanDesc',
                    params: {},
                    success: (data) => {
                        console.log(data)
                        this.setVal3 = data.data.setVal3
                    }
                })
            },

            /*======================修改手机号===================*/
            // 获取验证码
            getCode(v) {
                if (v == 4) {
                    this.$api.post({
                        path: "/user/getCode",
                        params: {
                            phone: this.modifyphon.newphone,
                            type: v, //1用户注册 2用户忘记密码, 3用户换绑原手机, 4用户手机号
                        },
                        success: (data) => {
                            console.log(data)

                            this.sms_id = data.data

                        },
                    });
                } else if (v == 2) {
                    this.$api.post({
                        path: "/user/getCode",
                        params: {
                            phone: this.modifypass.phone,
                            type: v, //1用户注册 2用户忘记密码, 3用户换绑原手机, 4用户手机号
                        },
                        success: (data) => {
                            console.log(data)
                            this.sms_id = data.data
                            if (data.code == 1) {
                                this.$message({
                                    message: data.msg,
                                    type: 'success'
                                });
                            }

                        },
                    });
                }

                if (!this.timer) {
                    this.count = 60;
                    if (v == 4) {
                        this.phonshow = false;
                    } else if (v == 2) {
                        this.passShow = false;
                    }
                    this.timer = setInterval(() => {
                        if (this.count > 0 && this.count <= 60) {
                            this.count--;
                        } else {
                            this.show = true;
                            clearInterval(this.timer);
                            this.timer = null;
                        }
                    }, 1000)
                }
            },
            // 修改手机号
            updateUserPhone() {
                this.$api.post({
                    path: '/myorder/ updateUserPhone',
                    params: {
                        uid: this.uid,
                        phone: this.modifyphon.oldphone,
                        new_phone: this.modifyphon.newphone,
                        sms_id: this.sms_id,
                        sms_code: this.modifyphon.code
                    },
                    success: (data) => {
                        console.log(data)
                        if (data.code == 1) {
                            this.$message({
                                message: data.msg,
                                type: 'success'
                            });

                            this.modifyphon.oldphone = ""
                            this.modifyphon.newphone = ""
                            this.modifyphon.code = ""
                            // this.modifyphon.pass = ""
                        }
                    }
                })
            },
            // 修改密码
            updateUserPwd() {
                this.$api.post({
                    path: '/myorder/updateUserPwd',
                    params: {
                        uid: this.uid,
                        phone: this.modifypass.phone,
                        pwd: this.modifypass.newpass,
                        sms_id: this.sms_id,
                        sms_code: this.modifypass.code
                    },
                    success: (data) => {
                        console.log(data)
                        if (data.code == 1) {
                            this.$message({
                                message: data.msg,
                                type: 'success'
                            });

                            this.modifypass.phone = ""
                            this.modifypass.newpass = ""
                            this.modifypass.code = ""
                            this.modifypass.pass = ""
                        }
                    }
                })
            }
        },


        mounted() {
            this.getAddresList()
            this.getPointList()
            this.getZiyuanDesc()
            this.getOrderList()
        },
    }
</script>
<style>
    .Pbox {
        width: 100%;
        margin: 130px auto;
    }

    .pmain {
        width: 100%;
        display: flex;
    }

    .pmainL {
        width: 180px;
        margin-right: 10px;
    }

    .pmainL ul {
        width: 180px;
    }

    .el-breadcrumb__item:last-child .el-breadcrumb__inner {
        color: #2f69d2;
    }

    .pmainL li {
        list-style: none;
        height: 48px;
        width: 65px;
        padding: 0px 57px;
        font-size: 16px;
        font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
        /* font-weight: bold; */
        text-align: left;
        color: #1a1a1a;
        line-height: 48px;
    }

    .liactive {
        background-color: #2f69d2;
        font-weight: bold;
        color: #ffffff;
    }

    .platform {
        width: 645px;
    }

    .platformtitle {
        width: 85px;
        height: 20px;
        font-size: 18px;
        font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
        font-weight: bold;
        text-align: right;
        color: #1a1a1a;
        line-height: 20px;
        border-left: 8px solid #2f69d2;
        margin-bottom: 20px;
    }

    .platformtext {
        width: 100%;
    }
</style>