<template>
  <!-- 活动商品列表 -->
  <div class="activityList">
    <!-- 面包屑 -->
    <div class="navigation">
      <div class="home"></div>
      <div class="current">当前页面:</div>
      <!-- <router-link tag="div" to="/trace">商品列表</router-link> -->
      <div>全部商品</div>
    </div>

    <div class="goods-list">
      <div
        class="hot-goods"
        v-for="item in huodonglist"
        :key="item.goodsId"
        @click="goodsDetail(item.goodsId)"
      >
        <div class="hot-img">
          <img :src="item.coverImg" alt="" />
        </div>
        <div class="hot-name">
          {{ item.goodsName }}
        </div>
        <div class="hot-pice">￥{{ item.priceMin }}</div>
      </div>
    </div>
    <div class="page">
      <div style="height: 100%; margin: 0 auto; display: flex">
        <div class="home-page" @click="goFirst">首页</div>
        <el-pagination
          @current-change="handlePageChange"
          background
          @size-change="handleSizeChange"
          :current-page="list.pageNum"
          :page-size="list.pageSize"
          layout="prev, pager, next"
          :total="pagetotal"
        >
        </el-pagination>
        <div class="last-page" @click="goLast">尾页</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      huodonglist: [],
      list: {
        pageSize: 10,
        pageNum: 1,
      },
      pagetotal: 0,
    };
  },
  methods: {
    getdata() {
      if (this.$route.params.type == 1) {
        this.$api.post({
          path: "/goods/homeHuodongGoods",
          params: {
            pageNum: this.list.pageNum,
            pageSize: 10,
          },
          success: (data) => {
            // console.log(data);
            // this.html = data.data.advContent
            this.huodonglist = data.data.HuodongGoods;
            this.pagetotal = data.data.rowsCount;
          },
        });
      }
    },
    goFirst() {
      this.list.pageNum = 1;
      this.getdata();
    },
    goLast() {
      this.list.pageNum = Math.ceil(this.pagetotal / 10);
      this.getdata();
    },
    handlePageChange(val) {
      this.list.pageNum = val;
      this.getdata();
    },
    handleSizeChange(val) {
      this.list.pageSize = val;
      this.getdata();
    },

    // 商品详情
    goodsDetail(id) {
      // console.log(id)
      this.$router.push(`/goodsDetails/${id}`);
    },
  },
  created() {
    // console.log(this.$route.params.type);
    this.getdata();
  },
};
</script>


<style>
/* 版心 */
.activityList {
  margin: 130px auto;
  width: 1200px;
}

/* 导航栏/面包屑 */
.navigation {
  width: 100%;
  height: 20px;
  font-size: 14px;
  font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
  font-weight: 500;
  display: flex;
  margin-bottom: 15px;
  margin-left: 5px;
}

.home {
  width: 16px;
  height: 16px;
  /* background-color: thistle; */
  background-image: url(../../assets/home.png);
  background-size: 100%;
  margin-right: 10px;
}

.current {
  color: #666666;
  margin-right: 10px;
}

.navigation div:last-child {
  color: #2f69d2;
}

/* 商品列表 */
.goods-list {
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  margin-bottom: 45px;
}

.hot-goods {
  width: 224px;
  height: 340px;
  background: #ffffff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
  border-radius: 10px;
  margin-right: 20px;
}

.hot-goods:nth-child(5n) {
  margin-right: 0px;
}

.hot-img {
  width: 224px;
  height: 224px;
  margin-bottom: 20px;
  background-color: thistle;
  border-radius: 10px 10px 0 0;
}

.hot-img img {
  width: 100%;
  height: 100%;
  background-color: thistle;
  border-radius: 10px 10px 0 0;
}

.hot-name {
  width: 185px;
  font-size: 16px;
  font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
  font-weight: 600;
  text-align: left;
  color: #333;
  line-height: 26px;
  margin: 0 auto;
  margin-bottom: 16px;
}

.hot-pice {
  width: 185px;
  margin: 0 auto;
  font-size: 20px;
  font-family: Source Han Sans CN Bold, Source Han Sans CN Bold-Bold;
  font-weight: 700;
  text-align: left;
  color: #ff271e;
  /* line-height: 40px; */
}

.page {
  width: 100%;
  height: 40px;
  margin: 0 auto;
  /* background-color: thistle; */
  display: flex;
}

.home-page {
  width: 60px;
  height: 36px;
  background: white;
  border: 1px solid #cccccc;
  font-size: 14px;
  font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
  font-weight: 400;
  text-align: center;
  color: #1a1a1a;
  line-height: 36px;
  margin-right: 10px;
}

.last-page {
  width: 60px;
  height: 36px;
  background: white;
  border: 1px solid #cccccc;
  font-size: 14px;
  font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
  font-weight: 400;
  text-align: center;
  color: #1a1a1a;
  line-height: 36px;
  margin-left: 10px;
}

.el-pagination .btn-prev,
.el-pagination .btn-next {
  width: 36px;
  height: 36px;
  background-color: #cccccc;
}

.el-pager li {
  width: 36px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  background-color: #cccccc;
}

.rl-pager .active {
  background-color: #2f69d2;
}
</style>