<template>
    <div class="paymen-type">
        <div class="navigation">
            <div class="home"></div>
            <div class="current">当前页面:</div>
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>购物车</el-breadcrumb-item>
                <el-breadcrumb-item>确认订单</el-breadcrumb-item>
                <el-breadcrumb-item>支付方式</el-breadcrumb-item>
            </el-breadcrumb>

        </div>
        <div class="total-amount">
            实付款：<span>{{actualtotal | numFilter}}元</span><span>（含运费）</span>
        </div>
        <div class="paytitle">选择付款方式</div>
        <div class="paytype">
            <!-- 微信支付 -->
            <div class="weipay">
                <div>
                    <div @click="paymentMethod=2,orderPay(paymentMethod)">
                        <img v-if="paymentMethod==2" src="../../assets/check.png" alt="">
                        <img v-else src="../../assets/checkno.png" alt="">
                    </div>
                    <span>微信支付</span>
                </div>
                <div class="paycode" v-show="paymentMethod==2">
                    <!-- <div class="sj"><i class="el-icon-caret-top"></i></div> -->
                    <div class="pay-code" id='qrcode'>
                    </div>
                </div>
            </div>

            <!-- 支付包支付 -->
            <div class="weipay">
                <div>
                    <div @click="paymentMethod=1,orderPay(paymentMethod)">
                        <img v-if="paymentMethod==1" src="../../assets/check.png" alt="">
                        <img v-else src="../../assets/checkno.png" alt="">
                    </div>
                    <span>支付宝支付</span>
                </div>
                <div class="paycode" v-show="paymentMethod==1">
                    <!-- <div class="sj"><i class="el-icon-caret-top"></i></div> -->
                    <div class="pay-code" id='qrcode2'>
                    </div>
                </div>
            </div>

            <!-- 银联支付 -->
            <div class="weipay">
                <div class="unionpay-top" style="margin-bottom: 20px;">
                    <div @click="paymentMethod=3,orderPay(3,1)">
                        <img v-if="paymentMethod==3" src="../../assets/check.png" alt="">
                        <img v-else src="../../assets/checkno.png" alt="">
                    </div>
                    <span>银联收银台</span>
                    
                </div>

                <div class="unionpay-top" >
                    <div @click="paymentMethod=4,orderPay(3,2)">
                        <img v-if="paymentMethod==4" src="../../assets/check.png" alt="">
                        <img v-else src="../../assets/checkno.png" alt="">
                    </div>
                    <span>银联个人网银支付</span>
                    
                </div>

                <div class="unionpay-top">
                    <div @click="paymentMethod=5,orderPay(3,3)">
                        <img v-if="paymentMethod==5" src="../../assets/check.png" alt="">
                        <img v-else src="../../assets/checkno.png" alt="">
                    </div>
                    <span>银联企业网银支付</span>
                    
                </div>

                <div class="unionpay-top">
                    <div @click="paymentMethod=6,orderPay(3,4)">
                        <img v-if="paymentMethod==6" src="../../assets/check.png" alt="">
                        <img v-else src="../../assets/checkno.png" alt="">
                    </div>
                    <span>银联无卡支付</span>
                    
                </div>

                
                <div class="paymenbtn" @click="getYinlian">
                    确认支付
                </div>
            </div>
        </div>

        <el-dialog :visible.sync="paysuccess">
            <div class="paysuccess">
                <div class="successimg"></div>
                <div class="successtext">支付成功</div>
                <div class="successbtn" @click="paysuccess=false,gethtml()">我知道了</div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import QRCode from 'qrcodejs2'
    export default {
        data() {
            return {
                paysuccess: false,
                paymentMethod: 2,//支付方式
                link: "",
                qrCodeUrl: '',
                actualtotal: this.$route.params.actualtotal,
                timer: '',
                orderType: '',
                html: ''
            }
        },

        methods: {
            orderPay(id,type) {
                // id = this.paymentMethod
                this.$api.post({
                    path: '/myorder/orderPay',
                    params: {
                        oid: this.$route.params.oid,
                        pay_type: id,
                        unionType:type
                    },
                    success: (data) => {
                        console.log(data, '支付');
                        if (id == 3) {
                            this.html = data.data.pcData
                            // document.getElementById("qrcode3").innerHTML = ;
                            // resp.getWriter().write(this.qrCodeUrl)
                        } else {
                            this.qrCodeUrl = data.data.qrCodeUrl
                            this.qrcode()
                        }



                    }
                })
            },

            qrcode() {
                document.getElementById("qrcode").innerHTML = "";
                document.getElementById("qrcode2").innerHTML = "";
                // document.getElementById("qrcode3").innerHTML = "";
                if (this.paymentMethod == 2) {
                    let qrcode = new QRCode('qrcode', {
                        width: 82,
                        height: 82, // 高度  
                        text: this.qrCodeUrl
                    })
                    console.log(qrcode)
                } else if (this.paymentMethod == 1) {
                    let qrcode2 = new QRCode('qrcode2', {
                        width: 82,
                        height: 82, // 高度  
                        text: this.qrCodeUrl
                    })
                    console.log(qrcode2)
                }

            },

            getOrderstate() {
                // let self = this;
                // let num = 0
                this.timers = setInterval(() => { //创建一个全局的定时器
                    this.$api.post({
                        path: '/myorder/checkOrderStatus',
                        params: {
                            oid: this.$route.params.oid
                        }, success: (data) => {
                            console.log(data)
                            this.orderType = data.data.orderType
                            if (data.data.status == '待发货' || data.data.status == '待成团' || data.data.status == '待生产') { //判断 如果data.data==true就是订单支付成功
                                this.paysuccess = true
                                clearInterval(this.timers) //别忘记关闭定时器，否则会一直调这个接口
                            }
                        }
                    })
                }, 1500)
            },

            gethtml() {
                if (this.orderType == '1' || this.orderType == '2' || this.orderType == '4') {
                    this.$router.push({
                        path: '/myorder', //成功以后的跳转路径
                    })
                } else {
                    this.$router.push({
                        path: '/advanceorder', //成功以后的跳转路径
                    })
                }


            },
            getYinlian() {
               let myWindow = window.open()
                myWindow.document.write(this.html)
                myWindow.focus()
            }
        },
        filters: {
            numFilter(value) {
                // 截取当前数据到小数点后两位
                let realVal = parseFloat(value).toFixed(2)
                return realVal
            }
        },
        beforeDestroy() {
            if (this.timers) {
                clearInterval(this.timers); //关闭
            }
        },
        mounted() {
            this.orderPay(2)
            // this.checkOrderStatus()
            this.getOrderstate()
        },



    }
</script>


<style>
    .paymen-type {
        width: 1200px;
        margin: 130px auto;

        margin-bottom: 45px;
    }

    .navigation {
        width: 100%;
        height: 20px;
        font-size: 14px;
        font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
        font-weight: 500;
        display: flex;
        margin-bottom: 17px;
        margin-left: 5px;
    }

    canvas {
        display: none;
    }

    .home {
        width: 16px;
        height: 16px;
        /* background-color: thistle; */
        background-image: url(../../assets/home.png);
        background-size: 100%;
        margin-right: 10px;
    }

    .current {

        color: #666666;
        margin-right: 10px;
    }

    .navigation div:last-child {
        color: #2F69D2;
    }

    .el-breadcrumb {
        line-height: 20px;
    }

    .el-breadcrumb-item:last-child {
        color: #2F69D2;
    }

    .total-amount {
        width: 100%;
        height: 18px;
        font-size: 16px;
        font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
        font-weight: 600;
        text-align: left;
        color: #1a1a1a;
        line-height: 26px;
        margin-bottom: 30px;
    }

    .total-amount span:first-child {
        font-size: 18px;
        color: red;
    }

    .total-amount span:last-child {
        font-size: 14px;
        color: #999999;
        font-weight: 500;
    }

    .paytitle {
        width: 133px;
        height: 20px;
        font-size: 20px;
        font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
        font-weight: bold;
        text-align: right;
        color: #1a1a1a;
        border-left: 8px solid #2f69d2;
        line-height: 20px;
        margin-bottom: 30px;
    }

    .paytype {
        width: 440px;
        /* height: 350px; */
        margin-bottom: 36px;
    }

    .weipay {
        width: 100%;
        margin-bottom: 20px;
    }

    .weipay>div:first-child {
        width: 100%;
        height: 18px;
        display: flex;
        font-size: 14px;
        font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
        font-weight: bold;
        text-align: left;
        color: #1a1a1a;
        line-height: 18px;
        margin-bottom: 4px;
    }

    .weipay>div:first-child div {
        width: 18px;
        height: 18px;
        margin-right: 11px;
    }

    .weipay>div:first-child div img {
        width: 18px;
        height: 18px;
    }

    .paycode {
        width: 82px;
        height: 88px;
        margin-left: 18px;
        /* background-color: thistle; */
    }

    .sj {
        width: 12px;
        height: 12px;
        color: #f2f2f2;
        margin: 0 auto;
        font-size: 10px;
        line-height: 12px;
        margin-bottom: -3px;
    }

    .pay-code {
        width: 82px;
        height: 82px;
        background-color: #f2f2f2;
    }

    /* .pay-code img {
        width: 100%;
        height: 100%;
    } */

    .unionpay {
        width: 100%;

    }

    .unionpay-top {
        width: 100%;
        height: 18px;
        display: flex;
        margin-bottom: 20px;
    }

    .unionpay-top>div:first-child {
        width: 18px;
        height: 18px;
        margin-right: 12px;
    }

    .unionpay-top>div:first-child img {
        width: 18px;
        height: 18px;

    }

    .bankimg {
        width: 18px;
        height: 18px;
        margin-right: 8px;
    }

    .bankimg img {
        width: 18px;
        height: 18px;
    }

    .unionpay-top>span {
        display: inline-block;
        height: 100%;
        font-size: 14px;
        font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
        font-weight: bold;
        text-align: left;
        color: #1a1a1a;
        line-height: 19px;
        margin-right: 30px;
    }

    /* .unionpay-top>span:first-child{
        margin-right: 30px;
    }
    .unionpay-top>span:nth-child(2){
        margin-right: 20px;
    } */
    .unionpay-typ {
        height: 100%;
        font-size: 14px;
        font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
        font-weight: bold;
        text-align: left;
        color: #1a1a1a;
        line-height: 18px;
    }

    .unionpay-typ span:first-child {
        display: inline-block;
        padding-right: 10px;
        border-right: 1px solid #1a1a1a;
    }

    .unionpay-typ span:last-child {
        display: inline-block;
        padding-left: 10px;
        border-left: 1px solid #1a1a1a;
    }

    .unionpays {
        width: 100%;
    }

    .addunion {
        width: 100px;
        height: 36px;
        /* background: #2f69d2; */
        border: 1px dashed #2f69d2;
        font-size: 14px;
        font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
        font-weight: bold;
        text-align: center;
        color: #1a1a1a;
        line-height: 36px;
        margin-bottom: 30px;
    }

    .upassword {
        width: 100%;
        height: 18px;
        font-size: 14px;
        font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
        font-weight: bold;
        text-align: left;
        color: #1a1a1a;
        line-height: 18px;
        margin-bottom: 12px;
    }

    .pay-password {
        /* width: 6.6rem;
        height: 36px;
        border: 1px solid #999999;
        margin: 0 auto; */
        position: relative;

        width: 216px;
        height: 36px;
        background: #ffffff;
        border: 1px solid #cccccc;
        margin-bottom: 11px;

    }

    .pay-password .real-ipt {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        line-height: 36px;
        opacity: 0;
        z-index: 3;
    }

    .pay-password .surface-ipts {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 36px;
        line-height: 36px;
        z-index: 1;
        /* overflow: hidden; */
    }

    .pay-password .surface-ipts .surface-ipt {
        height: 36px;
        line-height: 36px;
        display: flex;
        justify-content: space-between;
    }

    .pay-password .surface-ipts .surface-ipt input {
        width: 36px;
        height: 36px;
        line-height: 36px;
        border: 0;
        border-right: 1px solid #999999;
        color: #333333;
        font-size: 14px;
        text-align: center;
        padding: 0;
    }

    .passtips {
        font-size: 14px;
        font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
        font-weight: Normal;
        text-align: left;
        color: #999999;

    }

    .paymenbtn {
        width: 120px;
        height: 42px;
        background: #ff0000;
        font-size: 18px;
        font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
        font-weight: bold;
        text-align: center;
        color: #ffffff;
        line-height: 42px;
    }

    /* ===================================支付成功弹窗===================================== */
    .el-dialog {
        background-color: transparent;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);

    }

    .paysuccess {
        width: 280px;
        height: 194px;
        padding-top: 26px;
        background: #ffffff;
        border-radius: 10px;
        margin: 100px auto;
    }

    .successimg {
        width: 76px;
        height: 76px;
        background-image: url(../../assets/pay.png);
        background-size: 100%;
        margin: 0 auto;
        margin-bottom: 5px;
    }

    .successtext {
        font-size: 18px;
        font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
        font-weight: bold;
        text-align: center;
        color: #2f69d2;
        margin-bottom: 39px;
    }

    .successbtn {
        width: 180px;
        height: 36px;
        background: #ffffff;
        border: 1px solid #2f69d2;
        border-radius: 4px;
        font-size: 14px;
        font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
        font-weight: bold;
        text-align: center;
        color: #2f69d2;
        line-height: 36px;
        margin: 0 auto;
    }
</style>