<template>
  <div class="confirmorder">
    <div class="navigation">
      <div class="home"></div>
      <div class="current">当前页面:</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>购物车</el-breadcrumb-item>
        <el-breadcrumb-item>确认订单</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="addressbox">
      <div class="addresses" v-for="(item, k) in addresList" :key="item.addressId"
        @click="selectAddress(item.addressId), active(k)">
        <div class="address-top">
          <div class="username">
            <span>{{ item.receiver }}</span><span>{{ item.receiverPhone }}</span>
          </div>
          <div class="default" v-show="item.isDefault == 1">默认地址</div>
        </div>
        <div class="address-main">
          <span v-for="(txt, ind) in item.areaName" :key="ind">
            <span v-if="ind % 2 == 0">{{ txt }}</span></span>
          {{ item.addressDetail }}
        </div>
        <div class="address-foot">
          <span @click="edit(item.addressId), (editition = true)">编辑</span>
          <span @click="edit(item.addressId),delorder=true">删除</span>
        </div>
        <div class="acheck" v-if="index == k"></div>
      </div>
    </div>

    <div class="address-manage">
      <div class="newaddress" @click="addition = true">使用新地址</div>
    </div>

    <div class="address-title">确认订单信息</div>

    <!-- 购买商品列表 -->
    <table width="1200px" class="tables">
      <tr style="
          height: 48px;
          background-color: rgb(252, 252, 252);
          font-size: 16px;
        ">
        <th style="width: 169px">图片</th>
        <th style="width: 360px">商品名称</th>
        <th style="width: 150px">单价</th>
        <th style="width: 150px">数量</th>
        <th style="width: 130px">备注</th>
        <th style="width: 177px">小计</th>
      </tr>
      <tr style="height: 100px" v-for="(item, index) in goodsList" :key="index">
        <td>
          <div class="goodsImage">
            <img :src="item.coverImg" alt="" />
          </div>
        </td>
        <td style="font-weight: 600">{{ item.goodsName }}</td>
        <td style="color: red">
          <!-- 拼团直接购买单价 -->
          <div style="width: 100%; height: 100%" v-if="item.goodsMode == 1 && item.isPre == 0 && is_group == 0">
            {{ item.goodsGroup.alonePrice }}元
          </div>
          <div style="width: 100%; height: 100%" v-else-if="item.goodsMode == 1 && item.isPre == 0 && is_group == 1">
            {{ item.goodsGroup.groupPrice }}元
          </div>
          <div style="width: 100%; height: 100%" v-else>
            {{ item.priceMin }}元
          </div>
        </td>
        <td>{{ item.goodsNum }}</td>
        <td>
          <el-form size="medium">
            <el-form-item>
              <el-input type="textarea" v-model="orderdesc"></el-input>
            </el-form-item>
          </el-form>
        </td>
        <td style="color: red">
          <!-- 拼团商品小计 -->
          <div style="width: 100%; height: 100%" v-if="item.goodsMode == 1 && item.isPre == 0 && is_group == 0">
            {{ item.goodsGroup.alonePrice * item.goodsNum | numFilter}}元
          </div>
          <div style="width: 100%; height: 100%" v-else-if="item.goodsMode == 1 && item.isPre == 0 && is_group == 1">
            {{ item.goodsGroup.groupPrice * item.goodsNum | numFilter}}元
          </div>
          <div style="width: 100%; height: 100%" v-else>
            {{ item.priceMin * item.goodsNum  | numFilter}}元
          </div>
        </td>
      </tr>
    </table>

    <!-- 发票 -->
    <div class="invoice">
      <div class="invoicel">
        <div class="check">
          <div @click="invoiceOneChecked">
            <img v-show="!invoiceOne" src="../../assets/checkno.png" alt="" />
            <img v-show="invoiceOne" src="../../assets/check.png" alt="" />
          </div>
          <span>发票</span>
        </div>
        <!-- 发票类型 -->
        <div class="invoicetype">发票类型：增值税电子普通发票</div>

        <!-- 抬头类型 -->
        <div class="risetype">
          抬头类型：
          <div class="personal">
            <div @click="isEnterpriseChecked">
              <img v-show="isEnterprise" src="../../assets/checkno.png" alt="" />
              <img v-show="!isEnterprise" src="../../assets/check.png" alt="" />
            </div>
            <span>个人</span>
          </div>
          <div class="personal">
            <div @click="isEnterpriseChecked">
              <img v-show="!isEnterprise" src="../../assets/checkno.png" alt="" />
              <img v-show="isEnterprise" src="../../assets/check.png" alt="" />
            </div>
            <span>企业</span>
          </div>
        </div>

        <!-- 发票抬头 -->
        <div class="invoicel-rise">
          发票抬头：<input type="text" style="height: 20px; border: 1px solid #ccc" />
        </div>

        <!-- 发票抬头 -->
        <div class="invoicel-rise" v-show="isEnterprise">
          税号：<input type="text" style="height: 20px; border: 1px solid #ccc; margin-left: 28px" />
        </div>
      </div>

      <div class="invoicer">
        <div class="freight">
          运费：<span v-if="total > myorder.setVal3">0元</span><span v-else>{{ myorder.setVal2 }}元</span>
        </div>
        <div class="invoice-jifen">
          <div class="checks">
            <div @click="invoiceTwoChecked">
              <img v-show="!invoiceTwo" src="../../assets/checkno.png" alt="" />
              <img v-show="invoiceTwo" src="../../assets/check.png" alt="" />
            </div>
            <span>积分</span>
          </div>
          <input v-model="point" style="height: 20px; border: 1px solid #ccc; margin-right: 10px;" />
          分
          <!-- <el-form class="jifenbox">
            <el-form-item>
              <el-input type="number" v-model="point" :max="myorder.sumPoint"></el-input>
            </el-form-item>
          </el-form> -->
          <span>（可用积分：{{ myorder.sumPoint }}）</span>
        </div>
        <div class="discount">
          优惠
          <select id="group" v-model="coupon" @change="changeProduct($event)" value="1" style="
              margin-left: 7px;
              width: 90px;
              height: 20px;
              border: 1px solid #ccc;
            ">
            <option v-for="(item, index) in couponList" :key="index">
              {{ item.fullAmount }}-{{ item.couponAmount }}
            </option>
          </select>
          <!-- <el-form>
            <el-form-item label="优惠" size='mini'>
              <el-select v-model="coupon">
                <el-option v-for="item in couponList" :key="item.couponId" :value='item.couponId' :label="`${item.fullAmount} + '-' + ${item.couponAmount}`"></el-option>
              </el-select>
            </el-form-item>
          </el-form> -->
        </div>
      </div>
    </div>

    <!-- 提交订单 -->
    <div class="placeorder">
      <div class="placeorderl">
        <router-link tag="div" class="return" to="/buycart">
          <div></div>
          <span>返回购物车</span>
        </router-link>
        <div class="payment">
          实付款：<span v-if="invoiceTwo == true">{{ (actualtotal - integral - couponAmount) | numFilter }}元</span>
          <span v-else>{{ (actualtotal - couponAmount) | numFilter}}元</span>
          <span>（含运费）</span>
        </div>
      </div>
      <div @click="generateOrder" class="placeorderbtn">提交订单</div>
    </div>

    <!-- 添加地址 -->
    <el-dialog :visible.sync="addition" class="additionbox">
      <div class="addition">
        <div class="additionclose" @click="addition = false"></div>
        <div class="reject-title">新增地址</div>
        <div class="reject-line"></div>
        <el-form :model="ruleForm" size="small" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="收货人姓名" prop="name" class="froms">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="phone" class="froms">
            <el-input v-model="ruleForm.phone"></el-input>
          </el-form-item>
          <el-form-item label="收货地址" prop="cityName">
            <el-select v-model="provinceIndex" v-on:change="
                getCityCode(provinceIndex, 2);
                (cityIndex = ''),
                  (districtIndex = ''),
                  (streetIndex = ''),
                  changeLocationValue(provinceIndex);
              " placeholder="请选择省">
              <el-option v-for="item in provinceList" :key="item.areaId" :label="item.areaName" :value="item.areaId">
              </el-option>
            </el-select>

            <el-select v-model="cityIndex" v-on:change="
                getCityCode(cityIndex, 3);
                (districtIndex = ''),
                  (streetIndex = ''),
                  changeLocationValue2(cityIndex);
              " placeholder="请选择市">
              <el-option v-for="item in cityList" :key="item.areaId" :label="item.areaName" :value="item.areaId">
              </el-option>
            </el-select>

            <el-select v-model="districtIndex" v-on:change="
                getCityCode(districtIndex, 4);
                (streetIndex = ''), changeLocationValue3(districtIndex);
              " placeholder="请选择区/县">
              <el-option v-for="item in districtList" :key="item.areaId" :label="item.areaName" :value="item.areaId">
              </el-option>
            </el-select>

            <el-select v-model="streetIndex" placeholder="请选择街道" @change="changeLocationValue4(streetIndex)">
              <el-option v-for="item in streetList" :key="item.areaId" :label="item.areaName" :value="item.areaId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="详细地址" prop="adderss" class="froms">
            <el-input v-model="ruleForm.adderss"></el-input>
          </el-form-item>
        </el-form>
        <div class="check" style="margin-left: 100px; margin-top: 30px; width: 30%">
          <div @click="isdefaultaddress(ruleForm.is_default)">
            <img v-show="ruleForm.is_default" src="../../assets/check.png" alt="" />
            <img v-show="!ruleForm.is_default" src="../../assets/checkno.png" alt="" />
          </div>
          <span>设置为默认地址</span>
        </div>
        <div class="addAddress" @click="getaddAddres">保存</div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="delorder" class="del">
      <div class="delbox">
        <div class="deltext">确定要删除吗？</div>
        <div class="delbtn">
          <span @click="delorder = false">取消</span>
          <span @click="getdelAddress">删除</span>
        </div>
      </div>
    </el-dialog>

    <!-- 编辑地址 -->
    <el-dialog :visible.sync="editition" class="additionbox">
      <div class="addition">
        <div class="additionclose" @click="editition = false"></div>
        <div class="reject-title">编辑地址</div>
        <div class="reject-line"></div>
        <el-form :model="ruleForms" size="small" :rules="rules" ref="ruleForms" label-width="100px" class="demo-ruleForm editition">
          <el-form-item label="收货人姓名" prop="name" class="froms">
            <el-input v-model="ruleForms.name"></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="phone" class="froms">
            <el-input v-model="ruleForms.phone"></el-input>
          </el-form-item>
          <el-form-item label="收货地址" prop="cityName">
            <el-select v-model="provinceIndex" v-on:change="
                getCityCode(provinceIndex, 2);
                (cityIndex = ''),
                  (districtIndex = ''),
                  (streetIndex = ''),
                  changeLocationValue(provinceIndex);
              " :placeholder="province">
              <el-option v-for="item in provinceList" :key="item.areaId" :label="item.areaName" :value="item.areaId">
              </el-option>
            </el-select>

            <el-select v-model="cityIndex" v-on:change="
                getCityCode(cityIndex, 3);
                (districtIndex = ''),
                  (streetIndex = ''),
                  changeLocationValue2(cityIndex);
              " :placeholder="city">
              <el-option v-for="item in cityList" :key="item.areaId" :label="item.areaName" :value="item.areaId">
              </el-option>
            </el-select>

            <el-select v-model="districtIndex" v-on:change="
                getCityCode(districtIndex, 4);
                (streetIndex = ''), changeLocationValue3(districtIndex);
              " :placeholder="district">
              <el-option v-for="item in districtList" :key="item.areaId" :label="item.areaName" :value="item.areaId">
              </el-option>
            </el-select>

            <el-select v-model="streetIndex" :placeholder="street"  @change="changeLocationValue4(streetIndex)">
              <el-option v-for="item in streetList" :key="item.areaId" :label="item.areaName" :value="item.areaId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="详细地址" prop="adderss" class="froms">
            <el-input v-model="ruleForms.adderss"></el-input>
          </el-form-item>
        </el-form>
        <div class="check" style="margin-left: 100px; margin-top: 30px; width: 30%">
          <div @click="isdefaultaddress(ruleForm.is_default)">
            <img v-show="ruleForms.is_default" src="../../assets/check.png" alt="" />
            <img v-show="!ruleForms.is_default" src="../../assets/checkno.png" alt="" />
          </div>
          <span>设置为默认地址</span>
        </div>
        <div class="addAddress" @click="editAddress()">保存</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        invoiceOne: false,
        invoiceTwo: false,
        isEnterprise: false,
        goodsList: [],
        myorder: {},
        point: "", //积分
        uid: localStorage.getItem("uid"),

        // 添加新地址
        addition: false,
        editition: false,
        delorder: false,
        editid: "",

        provinceIndex: "",
        provinceName: "",
        provinceList: [],
        province:'',

        cityIndex: "",
        cityName: "",
        cityList: [],
        city:'',

        districtIndex: "",
        districtName: "",
        districtList: [],
        district:'',

        streetIndex: "",
        streetName: "",
        streetList: [],
        street:'',

        ruleForm: {
          name: "",
          phone: "",
          adderss: "",
          is_default: 0,
        },

        ruleForms: {
          name: "",
          phone: "",
          adderss: "",
          is_default: 0,
        },
        addressd:{},
        rules: {
          name: [
            { required: true, message: "请输入收货人姓名", trigger: "blur" },
            // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'change' }
          ],
          phone: [
            { required: true, message: "请输入电话号码", trigger: "blur" },
            { min: 11, max: 11, message: "请输入正确号码", trigger: "blur" },
          ],
          adderss: [
            { required: true, message: "请输入详细地址", trigger: "blur" },
            // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'change' }
          ],
          cityName: [
            // { required: true, message: "请选择", trigger: "change" }
          ],
        },

        addresList: [], //地址列表
        area_name: [], //省市区街道
        edit_name: [],
        isdefault: false, //默认地址
        addressid: "", //选择地址id
        isAddress: false,
        couponList: [], //优惠券列表
        couponAmount: 0, //优惠券值
        couponVal: "",
        coupon: "",

        orderdesc: "", //下单备注
        total: 0,

        cart: 0, //是否是从购物车下单

        actualtotal: 0, //实际金额
        sumPoint: 0, //自己有的积分
        pointSet: "", //表示多少积分抵扣1元
        setVal2: "", //邮费
        setVale: "", //表示多少包邮

        totalprice: "", //总价

        goodsMode: "",
        isPre: "",
        prePercent: "",

        oid: "", //订单id
        integral: 0,
        goods_ids: [],
        setVal3: 0,

        index: 0,

        is_group: "",
        is_cart: "",
        group_id: '',

        sjtotalprice: "",
        eaddress:'',
        coupon_id: '',
      };
    },
    created() {
      // this.getData();
      this.getCityCode();
    },
    watch: {
      // totalPrice(n) {
      //   this.total = n;
      //   console.log(this.total);
      //   this.getAvailableCoupon();

      // },
      // area_name(n) {
      //   console.log(n);
      // },

      point(n) {
        if (n > this.myorder.sumPoint) {
          this.point = this.myorder.sumPoint;
        }
      },
    },
    methods: {
      active(k) {
        this.index = k;
      },
      getData() {
        this.$api.post({
          path: "/myorder/getMyOrder",
          params: JSON.parse(sessionStorage.getItem("myOrder")),
          success: (data) => {
            console.log(data);
            this.myorder = data.data;
            this.goodsList = data.data.goodsList;
            this.pointSet = data.data.pointSet; //表示多少积分抵扣一元
            this.sumPoint = data.data.sumPoint;
            this.goodsMode = data.data.goodsList[0].goodsMode;
            this.isPre = data.data.goodsList[0].isPre;
            this.prePercent = data.data.goodsList[0].prePercent;
            this.setVal3 = data.data.setVal3;
            this.setVal2 = data.data.setVal2;
            this.is_group = JSON.parse(sessionStorage.getItem("myOrder")).is_group;
            this.is_cart = JSON.parse(sessionStorage.getItem("myOrder")).is_cart;
            this.group_id = JSON.parse(sessionStorage.getItem("myOrder")).group_id
            console.log(this.is_group);
            console.log(data.data);
            this.gettotal();
            // this.changeProduct(event)
          },
        });
      },
      invoiceOneChecked() {
        this.invoiceOne = !this.invoiceOne;
      },

      isEnterpriseChecked() {
        this.isEnterprise = !this.isEnterprise;
      },

      isdefaultaddress(is_default) {
        console.log(is_default);
        if (is_default) {
          this.ruleForm.is_default = 0;
          this.ruleForms.is_default = 0;
        } else {
          this.ruleForm.is_default = 1;
          this.ruleForms.is_default = 1;
        }
      },
      getAvailableCoupon() {
        this.$api.post({
          path: "/myorder/getAvailableCoupon",
          params: {
            uid: this.uid,
            full_amount: this.total,
          },
          success: (data) => {
            console.log(data, "1111");
            this.couponList = data.data;
            // console.log(this.total);
          },
        });
      },
      changeProduct(event) {
         console.log(event.target.selectedIndex);
        this.coupon = event.target.value; //获取option对应的value值
        let arr = this.coupon.split("-");
        console.log(arr);

        let arr2 = arr.splice(1, 1);
        this.couponAmount = arr2.join("");
        console.log(this.couponAmount);
        this.coupon_id = this.couponList[event.target.selectedIndex].couponId
        console.log("你选中了", this.coupon_id)
        // this.gettotal()
      },
      gettotal() {
        // if (this.invoiceTwo == true) {
        //   this.integral = (this.point / this.pointSet).toFixed(2)
        //   console.log(this.integral, "积分");
        // } else {
        //   this.integral = 0
        //   console.log(this.integral, "积分");

        // }
        // console.log(this.integral, "积分");
        console.log(this.pointSet);
        if (
          this.goodsList[0].goodsMode == 1 &&
          this.goodsList[0].isPre == 0 &&
          this.is_group == 0
        ) {
          this.total =
            this.goodsList[0].goodsGroup.alonePrice * this.goodsList[0].goodsNum;
          let postage = 0;
          if (this.total >= this.setVal3) {
            postage = 0;
            console.log(postage, "运费");
          } else {
            postage = Number(this.setVal2);
            console.log(postage, "运费");
          }
          console.log(this.couponAmount);
          this.actualtotal = postage + this.total - this.couponAmount;
          console.log(this.actualtotal);
        } else if (
          this.goodsList[0].goodsMode == 1 &&
          this.goodsList[0].isPre == 0 &&
          this.is_group == 1
        ) {
          this.total =
            this.goodsList[0].goodsGroup.groupPrice * this.goodsList[0].goodsNum;
          let postage = 0;
          if (this.total >= this.setVal3) {
            postage = 0;
            console.log(postage, "运费");
          } else {
            postage = Number(this.setVal2);
            console.log(postage, "运费");
          }
          console.log(this.couponAmount);
          this.actualtotal = postage + this.total - this.couponAmount;
          console.log(this.actualtotal);
        } else if (
          this.goodsList[0].goodsMode == 2 &&
          this.goodsList[0].isPre == 1
        ) {
          this.total = this.goodsList[0].priceMin * this.goodsList[0].goodsNum;
          console.log(this.total);
          let postage = 0;
          if (this.total >= this.setVal3) {
            postage = 0;
            console.log(postage, "运费");
          } else {
            postage = Number(this.setVal2);
            console.log(postage, "运费");
          }
          console.log(this.couponAmount);
          this.actualtotal =
            postage + this.total * (this.prePercent * 0.01) - this.couponAmount;
          console.log(this.actualtotal);
        } else {
          this.goodsList.forEach((e) => {
            this.total += e.priceMin * e.goodsNum;
          });
          let postage = 0;
          if (this.total >= this.setVal3) {
            postage = 0;
            console.log(postage, "运费");
          } else {
            postage = Number(this.setVal2);
            console.log(postage, "运费");
          }
          console.log(this.couponAmount);
          this.actualtotal = postage + this.total - this.couponAmount;
          console.log(this.actualtotal);
        }
        // this.invoiceTwoChecked()

        this.getAvailableCoupon()
        // this.changeProduct(event)
      },
      invoiceTwoChecked() {
        this.invoiceTwo = !this.invoiceTwo;
        if (this.invoiceTwo == true) {
          this.integral = (this.point / this.pointSet).toFixed(2);
          // this.actualtotal = this.total - this.integral
          console.log(this.integral, "积分");
        } else if (this.invoiceTwo == false) {
          this.integral = 0;
          // console.log(this.integral, "积分");
          // this.actualtotal = this.total + (this.point / this.pointSet).toFixed(2)
        }
        // // console.log(this.integral, "积分");
        // console.log(this.pointSet)
        // this.getData()
        // this.gettotal()

        console.log(this.invoiceTwo);
      },

      // 根据金额获取可用的优惠券
     

      // 获取优惠券值
      

      // 地址列表
      // 地址列表
      getAddresList() {
        this.$api.post({
          path: "/myorder/getAddresList",
          params: {
            pageNum: 1,
            pageSize: 10,
            uid: this.uid,
          },
          success: (data) => {
            console.log(data, "地址");
            this.addresList = data.data.list;
            this.addresList.forEach((e, index) => {
              e.areaName = e.areaName.split(",");
              if (e.isDefault) {
                this.index = index
                this.adderssId = e.addressId
              } else {
                this.adderssId = data.data.list[0].addressId
              }

            });
          },
        });
      },

      getCityCode(pid, type) {
        this.$api.post({
          path: `/user/getAreas`,
          params: {
            pid: pid ? pid : 0,
          },
          success: (data) => {
            if (!type) {
              this.provinceList = data.data;
            }
            console.log(data);
            // console.log(type);
            switch (type) {
              case 2:
                this.cityList = data.data;
                break;
              case 3:
                this.districtList = data.data;
                break;
              case 4:
                this.streetList = data.data;
                break;
              default:
                break;
            }
          },
        });
        console.log(this.provinceIndex);
      },

      // 省 下拉列表值
      changeLocationValue(val) {
        //locations是v-for里面的也是datas里面的值
        let obj = {};
        obj = this.provinceList.find((item) => {
          return item.areaId === val;
        });
        this.provinceName = obj.areaName;
        console.log(obj.areaName);
      },

      // 市 下拉列表值
      changeLocationValue2(val) {
        //locations是v-for里面的也是datas里面的值
        let obj2 = {};
        obj2 = this.cityList.find((item) => {
          return item.areaId === val;
        });
        this.cityName = obj2.areaName;
        console.log(obj2.areaName);
      },

      // 区/县 下拉列表值
      changeLocationValue3(val) {
        //locations是v-for里面的也是datas里面的值
        let obj3 = {};
        obj3 = this.districtList.find((item) => {
          return item.areaId === val;
        });
        this.districtName = obj3.areaName;
        console.log(obj3.areaName);
      },

      // 街道 下拉列表值
      changeLocationValue4(val) {
        //locations是v-for里面的也是datas里面的值
        let obj4 = {};
        obj4 = this.streetList.find((item) => {
          return item.areaId === val;
        });
        this.streetName = obj4.areaName;
        console.log(obj4.areaName);
      },

      
      getaddAddres() {
        this.area_name.push(
          this.provinceName,
          this.cityName,
          this.districtName,
          this.streetName
        );

        if (
          this.ruleForm.name == "" ||
          this.ruleForm.phone == "" ||
          this.ruleForm.adderss == "" ||
          this.area_name.join("") == ""
        ) {
          this.$message({
            showClose: true,
            message: "请填写完整信息",
            type: "warning",
          });
          // return false
        } else {
          this.$api.post({
            path: "/myorder/addAddress",
            params: {
              uid: this.uid,
              address_id: 0, //新增id传0，编辑传对应id
              receiver: this.ruleForm.name,
              receiver_phone: this.ruleForm.phone,
              area_name: this.area_name.join(""),
              address_detail: this.ruleForm.adderss,
              is_default: this.ruleForm.is_default, //是否默认：0非默认；1默认。C端用户有多个地址，每个用户只能有一个默认地址
            },
            success: (data) => {
              console.log(data);

              if (data.code == 1) {
                this.$message({
                  message: data.msg,
                  type: "success",
                });
                this.area_name = [];
                this.ruleForm.name = "";
                this.ruleForm.phone = "";
                this.ruleForm.adderss = "";
                this.provinceName = "";
                this.cityName = "";
                this.districtName = "";
                this.streetName = "";
                this.provinceIndex = "";
                this.cityIndex = "";
                this.districtIndex = "";
                this.streetIndex = "";
                this.addition = false;
              }
              this.getAddresList();
            },
          });
        }

        // this.addition = false;
      },

      // 勾选、取消默认地址
      defaultAddress(val) {
        this.$api.post({
          path: "/myorder/operationAddress",
          params: {
            uid: this.uid,
            address_id: val,
          },
        });
      },
      // 选择地址
      selectAddress(id) {
        console.log(id);
        this.adderssId = id;
        if (this.adderssId == id) {
          this.isAddress = true;
        }
      },
      edit(v) {
        this.editid = v;
        this.$api.post({
          path:'/myorder/getAddressById',
          params:{
            addressId:v
          },success:(data)=>{
            console.log(data)
            this.addressd = data.data
            this.ruleForms.name = data.data.receiver
            this.ruleForms.phone = data.data.receiverPhone
            this.ruleForms.adderss = data.data.addressDetail
            this.eaddress = data.data.areaName
            
            var regex = "(?<province>[^省]+省|[^市]+市|.+自治区)(?<city>[^自治州]+自治州|[^市]+市|[^盟]+盟|[^地区]+地区|.+区划)(?<county>[^市]+市|[^县]+县|[^旗]+旗|.+区)?(?<town>[^区]+区|.+镇)?(?<village>.*)";
            var address = `${this.eaddress}`;
            var result = address.match(regex);
            console.log(result);

            this.province = result.groups.province
            this.city = result.groups.province
            this.district = result.groups.county
            this.street = result.groups.village

          }
        })
      },
      // 编辑
      editAddress() {
        this.edit_name.push(
          this.provinceName,
          this.cityName,
          this.districtName,
          this.streetName
        );

        if (this.edit_name == []) {
          this.eaddress = this.this.addressd.areaName
        }
        this.$api.post({
          path: "/myorder/addAddress",
          params: {
            uid: this.uid,
            address_id: this.editid, //新增id传0，编辑传对应id
            receiver: this.ruleForms.name,
            receiver_phone: this.ruleForms.phone,
            area_name: this.eaddress,
            address_detail: this.ruleForms.adderss,
            is_default: this.ruleForms.is_default,
          },
          success: (data) => {
            console.log(data);
            this.ruleForm.name = "";
            this.ruleForm.phone = "";
            this.ruleForm.adderss = "";
            this.provinceName = "";
            this.cityName = "";
            this.districtName = "";
            this.streetName = "";
            this.provinceIndex = "";
            this.cityIndex = "";
            this.districtIndex = "";
            this.streetIndex = "";
            this.editition = false;
            this.getAddresList();
          },
        });
      },
      // 删除地址
      getdelAddress() {
        this.$api.post({
          path: "/myorder/delAddress",
          params: {
            address_id: this.editid,
          },
          success: (data) => {
            console.log(data);
            if (data.code == 1) {
              this.$message({
                message: data.msg,
                type: "success",
              });
              this.delorder = false
              this.getAddresList();
            }
          },
        });
      },

      // getactualtotal() {
      //   console.log(this.total)
      //   // 是否包邮
      //   let postage = 0;
      //   if (this.total >= this.setVal3) {
      //     postage = 0;
      //   } else {
      //     postage = this.setVal2;
      //   }
      //   console.log(this.total);
      //   // 抵扣积分
      //   let integral = (this.sumPoint / this.pointSet).toFixed(2);
      //   if (this.goodsMode == 1 && this.isPre == 0) {
      //     this.actualtotal = postage + this.total - this.couponAmount - integral;
      //   } else if (this.goodsMode == 2 && this.isPre == 1) {
      //     this.actualtotal =
      //       postage + this.total * this.prePercent - this.couponAmount - integral;
      //   }
      //   console.log(this.actualtotal);
      // },

      // 生成订单
      generateOrder() {
        var that = this
        let type = "";
        // let is_cart = 0;
        // let group_id = "0";
        if (this.goodsList[0].goodsMode == 1 && this.goodsList[0].isPre == 0) {
          type = "2";
          // is_cart = 0;
        } else if (
          this.goodsList[0].goodsMode == 2 &&
          this.goodsList[0].isPre == 1
        ) {
          type = "3";
          // is_cart = 0;
          // group_id = "0";
        } else {
          type = "1";
          // is_cart = 1;
          // group_id = "0";
        }

        if (this.invoiceTwo == false) {
          this.point = "0";
        }
        let orderGoods = [];
        this.goodsList.forEach((e) => {
          orderGoods.push({
            goods_id: e.goodsId,
            num: e.goodsNum,
            memo: this.orderdesc,
          });
        });

        this.goodsList.forEach((e) => {
          this.goods_ids.push(e.goodsId);
        });

        this.$api.post({
          path: "/myorder/generateOrder",
          params: {
            uid: this.uid,
            client_type: 3,
            address_id: this.adderssId,
            order_type: type,
            coupon_id: this.coupon_id, //优惠券id
            point: this.point, //积分
            group_id: this.group_id,
            is_group: this.is_group,
            is_cart: this.is_cart,
            orderGoods: orderGoods,
            invoice_id: "0",
            goods_ids: this.goods_ids.join(","),
          },

          success: (data) => {
            console.log(data, 'data');
            // console.log(this.goodsList[0], 'goodsList');
            if (data.code == 1) {
              console.log(data.code, 'data.code')
              if (this.invoiceTwo == true) {
                this.sjtotalprice = this.actualtotal - this.integral - this.couponAmount;
                this.$router.push(`/payment/${data.data}/${this.sjtotalprice}`);
              } else {
                this.sjtotalprice = this.actualtotal - this.couponAmount;
                // this.$router.push(`/payment/${data.data}/${this.actualtotal}`);
                this.$router.push(`/payment/${data.data}/${this.sjtotalprice}`);
              }

              // this.oid = data.data
            } else {
              // console.log(data.msg, 'data.msg')
              that.$message({
                message: data.msg,
                type: "error",
              });
            }
          }
        });
      },
    },
    filters: {
      numFilter(value) {
        // 截取当前数据到小数点后两位
        let realVal = parseFloat(value).toFixed(2)
        return realVal
      }
    },
    computed: {
      // totalPrice() {
      //   // let count = [];
      //   let totalPrice = 0;
      //     this.goodsList.forEach((e) => {
      //       totalPrice += e.priceMin * e.goodsNum;
      //     });
      //     console.log(totalPrice);
      //   return totalPrice;
      // },
    },

    mounted() {
      this.getData();
      this.getAddresList();
      this.getAvailableCoupon();

      // this.getactualtotal();
      // this.sumprice();
      // this.getCoupon()
    },
  };
</script>

<style>
  .confirmorder {
    width: 1200px;
    margin: 130px auto;
    margin-bottom: 20px;
  }

  .navigation {
    width: 100%;
    height: 20px;
    font-size: 14px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    font-weight: 500;
    display: flex;
    margin-bottom: 17px;
    margin-left: 5px;
  }

  .home {
    width: 16px;
    height: 16px;
    /* background-color: thistle; */
    background-image: url(../../assets/home.png);
    background-size: 100%;
    margin-right: 10px;
  }

  .current {
    color: #666666;
    margin-right: 10px;
  }

  .navigation div:last-child {
    color: #2f69d2;
  }

  .el-breadcrumb__item:last-child .el-breadcrumb__inner {
    color: #2f69d2;
  }

  .el-breadcrumb {
    line-height: 20px;
  }

  .el-breadcrumb-item:last-child {
    color: #2f69d2;
  }

  /* =========================地址================================= */
  .addressbox {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  .addresses {
    width: 254px;
    height: 108px;
    padding: 10px 10px 20px 20px;
    background: #f6f6f6;
    border: 2px dashed #cccccc;
    border-radius: 4px;
    position: relative;
    margin-right: 15px;
    margin-bottom: 10px;
  }

  /* .address:last-child {
    margin-right: 0px;
  } */

  .addresses:nth-child(4n) {
    margin-right: 0px;
  }

  .address-top {
    width: 100%;
    height: 26px;
    display: flex;
    margin-bottom: 15px;
  }

  .username {
    width: 80%;
    height: 100%;
    font-size: 16px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    font-weight: 600;
    text-align: left;
    color: #333;
    line-height: 26px;
    /* background-color: thistle; */
  }

  .username span:last-child {
    display: inline-block;
    margin-left: 10px;
  }

  .default {
    width: 56px;
    height: 26px;
    /* opacity: 0.1; */
    background: rgba(47, 105, 210, 0.1);
    border-radius: 4px;
    font-size: 12px;
    font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
    font-weight: Normal;
    text-align: center;
    color: #2f69d2;
    line-height: 26px;
    margin-left: auto;
  }

  .address-main {
    width: 100%;
    height: 36px;
    font-size: 14px;
    font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
    font-weight: Normal;
    text-align: left;
    color: #1a1a1a;
    margin-bottom: 20px;
  }

  .address-foot {
    width: 88px;
    height: 14px;
    font-size: 14px;
    font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
    font-weight: Normal;
    line-height: 14px;
  }

  .address-foot span:first-child {
    display: inline-block;
    width: 50%;
    height: 100%;
    color: #2f69d2;
    text-align: left;
  }

  .address-foot span:last-child {
    display: inline-block;
    width: 50%;
    height: 100%;
    text-align: right;
    color: #ff0000;
  }

  .acheck {
    position: absolute;
    width: 30px;
    height: 30px;
    background-image: url(../../assets/acheck.png);
    background-size: 100%;
    bottom: 0px;
    right: 0px;
  }

  .address-manage {
    width: 100%;
    height: 38px;
    display: flex;
    font-size: 14px;
    font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
    font-weight: Normal;
    text-align: center;
    color: #2f69d2;
    margin-bottom: 48px;
  }

  .newaddress {
    width: 80px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #cccccc;
    line-height: 36px;
    margin-right: 35px;
  }

  .address-manage span {
    display: inline-block;
    height: 100%;
    line-height: 38px;
    color: #2f69d2;
  }

  .address-title {
    width: 141px;
    height: 20px;
    font-size: 20px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    font-weight: bold;
    text-align: right;
    color: #1a1a1a;
    border-left: 8px solid #2f69d2;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .tables {
    /* width: 1480px; */
    border: 1px solid rgba(204, 204, 204, 0.3);
    /* margin-top: 10px; */
    border-spacing: 0;
    margin-bottom: 0px;
    /*去掉单元格间隙*/
  }

  .tables th {
    font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
    font-weight: 500;
    text-align: center;
    color: #1a1a1a;
    border-bottom: 1px solid rgba(204, 204, 204, 0.3);
  }

  .tables td {
    border-right: 1px solid rgba(204, 204, 204, 0.3);
    border-bottom: 1px solid rgba(204, 204, 204, 0.3);
    font-size: 14px;
    text-align: center;
  }

  .tables .el-textarea__inner {
    padding: 5px 5px;
    border-color: transparent;
  }

  .tables .el-form-item {
    margin-top: 22px;
  }

  .goodsImage {
    width: 68px;
    height: 68px;
    margin: 0 auto;
    background-color: thistle;
  }

  .goodsImage img {
    width: 68px;
    height: 68px;
  }

  .invoice {
    width: 1160px;
    /* height: 115px; */
    padding: 20px 20px 19px 19px;
    background: #f6f6f6;
    border: 1px solid #cccccc;
    margin-bottom: 10px;
    display: flex;
  }

  .invoicel {
    width: 20%;
    height: 100%;
    margin-right: 127px;
  }

  .check {
    width: 100%;
    height: 18px;
    display: flex;
    margin-bottom: 22px;
  }

  .check div {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }

  .check div img {
    width: 18px;
    height: 18px;
  }

  .check span {
    height: 100%;
    font-size: 14px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: bold;
    text-align: left;
    color: #1a1a1a;
    line-height: 18px;
  }

  .invoicetype {
    font-size: 14px;
    font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
    font-weight: Normal;
    text-align: left;
    color: #1a1a1a;
    margin-bottom: 15px;
  }

  .risetype {
    width: 100%;
    height: 18px;
    display: flex;
    font-size: 14px;
    font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
    font-weight: Normal;
    text-align: left;
    color: #1a1a1a;
    line-height: 18px;
    margin-bottom: 14px;
  }

  .personal {
    width: 60px;
    height: 100%;
    display: flex;
  }

  .personal div {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }

  .personal div img {
    width: 18px;
    height: 18px;
  }

  .personal span {
    height: 100%;
    font-size: 14px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: bold;
    text-align: left;
    color: #1a1a1a;
    line-height: 18px;
  }

  .personal:last-child {
    margin-left: auto;
  }

  .invoicel-rise {
    height: 15px;
    font-size: 14px;
    font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
    font-weight: Normal;
    text-align: left;
    color: #1a1a1a;
    line-height: 15px;
    margin-bottom: 15px;
  }

  input {
    outline-style: none;
    border: 0px;
    width: 157px;
    height: 100%;
    /* margin-left: 10px; */
  }

  .invoicer {
    width: 50%;
    font-size: 14px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: bold;
    text-align: left;
    color: #1a1a1a;
  }

  .freight {
    width: 100%;
    margin-bottom: 25px;
  }

  .freight span {
    display: inline-block;
    margin-right: 20px;
  }

  .invoice-jifen {
    width: 100%;
    height: 20px;
    display: flex;
    margin-bottom: 19px;
  }

  .invoice-jifen input {
    width: 90px;
    height: 20px;
    /* margin-left: 7px; */
  }

  .placeorder {
    width: 1200px;
    height: 62px;
    display: flex;
  }

  .invoice-jifen span {
    color: #999999;
  }

  .checks {
    width: 70px;
    height: 18px;
    display: flex;
    margin-bottom: 22px;
  }

  .checks div {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }

  .checks div img {
    width: 18px;
    height: 18px;
  }

  .checks span {
    height: 100%;
    font-size: 14px;
    font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
    font-weight: bold;
    text-align: left;
    color: #1a1a1a;
    line-height: 18px;
  }

  .discount {
    width: 100%;
  }

  .placeorderl {
    width: 1020px;
    height: 16px;
    padding: 22px 20px;
    background: #ffffff;
    border: 1px solid #cccccc;
    border-right: 0px;
    display: flex;
  }

  .return {
    width: 100px;
    height: 100%;
    display: flex;
  }

  .return div {
    width: 22px;
    height: 100%;
    background-image: url(../../assets/return.png);
    background-size: 100%;
    margin-right: 8px;
  }

  .return span {
    font-size: 14px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    font-weight: 500;
    text-align: left;
    color: #2f69d2;
  }

  .placeorderbtn {
    width: 140px;
    height: 100%;
    background: #ff0000;
    font-size: 18px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    line-height: 62px;
    margin-left: auto;
  }

  .payment {
    height: 100%;
    font-size: 16px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    font-weight: 600;
    text-align: right;
    color: #1a1a1a;
    margin-left: auto;
  }

  .payment span:first-child {
    color: #ff0000;
  }

  .payment span:last-child {
    font-size: 12px;
    color: #999999;
    font-weight: 500;
  }

  /* =========================新增地址============================ */
  .addition {
    width: 520px;
    /* height: 330px; */
    padding: 30px 40px;
    background-color: white;
    border-radius: 10px;
    margin: 100px auto;
    position: relative;
  }

  .addition .el-input__inner {
    width: 90%;
    padding-left: 5px;
  }

  .froms .el-input__inner {
    width: 98%;
    padding-left: 5px;
  }

  .addition .el-form-item__content {
    display: flex;
  }

  .addition .el-input__suffix {
    right: 15px;
  }

  .additionclose {
    width: 34px;
    height: 34px;
    background-image: url(../../assets/close.png);
    background-size: 100%;
    position: absolute;
    top: -55px;
    right: 0px;
  }

  .additionbox .el-dialog__headerbtn {
    top: 114px;
    right: 177px;
    z-index: 999;
    color: white;
  }

  .addition .addAddress {
    margin-left: 100px;
    width: 60px;
    height: 30px;
    background: #2f69d2;
    border-radius: 4px;
    color: white;
    font-weight: normal;
    font-size: 14px;
    font-family: PingFang SC Medium, PingFang SC Medium-Medium;
    text-align: center;
    line-height: 30px;
  }

  .jifenbox .el-form-item__content {
    line-height: 20px;
  }


  /* 下拉框的默认提示的颜色 */


  .editition .el-input__inner::placeholder {
    color: black;
    text-align: center;
  }

  /* 谷歌 */
  .editition .el-input__inner::-webkit-input-placeholder {
    color:  black;
    text-align: center;
  }

  /* 火狐 */
  .editition .el-input__inner:-moz-placeholder {
    color:  black;
    text-align: center;
  }

  /*ie*/
  .editition.el-input__inner:-ms-input-placeholder {
    color:  black;
    text-align: center;
  }
</style>