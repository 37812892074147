<template>
    <div class="mycoupon">
        <!-- 面包屑 -->
        <div class="navigation">
            <div class="home"></div>
            <div class="current">当前页面:</div>
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item to="/pmessage">个人中心</el-breadcrumb-item>
                <el-breadcrumb-item>我的优惠券</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="couponList">
            <div class="coupon-type">
                <div :class="['coupon-type-item', coupon.status == 2 ? 'active' : '']" @click="gettype(2)">
                    未使用
                </div>
                <div :class="['coupon-type-item', coupon.status == 1 ? 'active' : '']" @click="gettype(1)">
                    已使用
                </div>
                <div :class="['coupon-type-item', coupon.status == 3 ? 'active' : '']" @click="gettype(3)">
                    已过期
                </div>
            </div>
            <div class="coupons" v-if="coupon.status == 2">
                <div style="width: 100%; height: 50px; text-align: center; line-height: 50px;" v-if="couponList.length==0">{{this.noData}}</div>
                <div class="cou" v-for="item in couponList" :key="item.couponRuleId">
                    <div class="cou-left">
                        <img src="../../assets/blue.png" alt="">
                    </div>
                    <div class="cou-main"><span>￥</span>{{item.couponAmount}}<span>元</span></div>
                    <div class="cou-right">
                        <div class="cou-name">
                            {{item.couponName}}
                        </div>
                        <div class="cou-lastl">满{{item.fullAmount}}减{{item.couponAmount}}</div>
                        <div class="cou-time">有效期至<span>{{item.deadline | timeFiler}}</span></div>
                    </div>
                </div>
            </div>
            <div class="coupons" v-if="coupon.status == 1">
                <div style="width: 100%; height: 50px; text-align: center; line-height: 50px;" v-if="couponList.length==0">{{this.noData}}</div>
                <div class="cou" v-for="item in couponList" :key="item.couponRuleId">
                    <div class="cou-left">
                        <img src="../../assets/gray.png" alt="">
                    </div>
                    <div class="cou-main" style="background-color: #cccccc;"><span>￥</span>{{item.couponAmount}}<span>元</span></div>
                    <div class="cou-right">
                        <div class="cou-name">
                            {{item.couponName}}
                        </div>
                        <div class="cou-lastl">满{{item.fullAmount}}减{{item.couponAmount}}</div>
                        <div class="cou-time">已使用</div>
                    </div>
                </div>
            </div>
            <div class="coupons" v-if="coupon.status == 3">
                <div style="width: 100%; height: 50px; text-align: center; line-height: 50px;" v-if="couponList.length==0">{{this.noData}}</div>
                <div class="cou" v-for="item in couponList" :key="item.couponRuleId">
                    <div class="cou-left">
                        <img src="../../assets/gray.png" alt="">
                    </div>
                    <div class="cou-main" style="background-color: #cccccc;"><span>￥</span>{{item.couponAmount}}<span>元</span></div>
                    <div class="cou-right">
                        <div class="cou-name">
                            {{item.couponName}}
                        </div>
                        <div class="cou-lastl">满{{item.fullAmount}}减{{item.couponAmount}}</div>
                        <div class="cou-time">已过期</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                coupon: {
                    status: 2 //
                },
                uid: localStorage.getItem("uid"),
                couponList:[],
                noData:''
            }
        },
        methods: {
            // 优惠券
            getcoupon() {
                // this.coupon.status = v
                this.$api.post({
                    path:'/myorder/getCouponList',
                    params:{
                        token:this.token,
                        uid:this.uid,
                        type:this.coupon.status
                    },
                    success:(data)=>{
                        if (data.code == 0 && data.msg == '会话已过期') {
                            this.$router.replace('/indexDetails')
                            this.$router.go('/indexDetails');
                            localStorage.removeItem('token')
                            localStorage.removeItem('uid')
                            localStorage.removeItem('nickName')
                            localStorage.removeItem('avatar')
                        }
                        console.log(data,'优惠券');
                        if(data.data.list.length){
                            this.noData = '暂无优惠券'
                            this.couponList = data.data.list
                        } else{
                            this.couponList = data.data.list
                        // console.log(data.data.list)
                        }
                        
                    }
                })
            },
            gettype(v){
                this.coupon.status = v;
                this.getcoupon()
            }
        },
        mounted() {
            this.getcoupon()
        }, filters: {
      timeFiler(value) {
        let times = /\d{4}-\d{1,2}-\d{1,2}/g.exec(value)
        return times[0]
      }}
    }
</script>


<style>
    .mycoupon {
        width: 1200px;
        margin: 130px auto;
    }

    /* 导航栏/面包屑 */
    .navigation {
        width: 100%;
        height: 20px;
        font-size: 14px;
        font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
        font-weight: 500;
        display: flex;
        margin-bottom: 17px;
        margin-left: 5px;
    }

    .home {
        width: 16px;
        height: 16px;
        /* background-color: thistle; */
        background-image: url(../../assets/home.png);
        background-size: 100%;
        margin-right: 10px;
    }

    .current {

        color: #666666;
        margin-right: 10px;
    }

    .navigation div:last-child {
        color: #2F69D2;
    }

    .el-breadcrumb {
        line-height: 20px;
    }

    .el-breadcrumb-item:last-child {
        color: #2F69D2;
    }
    .couponList {
        width: 100%;
        border: 1px solid #f2f2f2;
    }

    /* 优惠券使用分类状态 */
    .coupon-type {
        width: 100%;
        height: 48px;
        border-bottom: 1px solid #f2f2f2;
        background-color: #f6f6f6;
        box-sizing: border-box;
        padding: 15px 0;
        display: flex;
        margin-bottom: 10px;
    }

    .coupon-type-item {
        width: 87px;
        height: 19px;
        font-size: 16px;
        color: #1a1a1a;
        border-right: 1px solid #ccc;
        text-align: center;
        cursor: pointer;
        line-height: 19px;
        font-weight: 500;
        /* margin-bottom: 15px; */
    }

    .coupon-type-item:last-child {
        border-right: 0px
    }

    .active {
        color: #2F69D2;
        /* height: 19px; */
    }

    /* 优惠券 */
    .coupons {
        width: 1160px;
        height: 100%;
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
    }

    .cou {
        width: 380px;
        height: 88px;
        /* background-color: thistle; */
        margin-bottom: 20px;
        margin-right: 10px;
        display: flex;
    }

    .cou:nth-child(3n) {
        margin-right: 0px;
    }

    .cou-main {
        width: 105px;
        height: 100%;
        /* padding: 34px 21px 34px 11px; */
        /* padding-left: 8px; */
        background-color: #2f69d2;
        font-size: 24px;
        color: white;
        font-weight: 600;
        line-height: 88px;
        text-align: center;
    }

    .cou-main span:first-child {
        font-size: 24px;
    }

    .cou-main span:last-child {
        font-size: 14px;
        font-weight: 400;
    }

    .cou-left {
        width: 15px;
        height: 100%;
        /* background-image: url(../../assets/blue.png); */
        background-size: 100%;
    }
    .cou-left img{
        width: 100%;
        height: 100%;
    }

    .cou-right {
        width: 235px;
        height: 68px;
        padding: 10px 10px 10px 15px;
        background: #f2f2f2;
    }

    .cou-name {
        width: 100%;
        height: 18px;
        font-size: 16px;
        font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
        font-weight: bold;
        text-align: left;
        color: #1a1a1a;
        margin-bottom: 18px;
    }

    /* .cou-last {
        width: 100%;
        height: 26px;
        display: flex;
    } */

    .cou-lastl {
        width: 70%;
        /* height: 100%; */
        font-size: 14px;
        font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
        font-weight: bold;
        text-align: left;
        color: #ff0000;
        /* line-height: 37px; */
        /* background-color: white; */
    }

    .cou-time {
        width: 70%;
        font-size: 14px;
        font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
        font-weight: Normal;
        text-align: left;
        color: #1a1a1a;
    }
</style>