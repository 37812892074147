<template>
  <div class="Pbox">
    <div class="navigation">
      <div class="home"></div>
      <div class="current">当前位置:</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/pmessage">个人中心</el-breadcrumb-item>
        <el-breadcrumb-item>个人信息</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="pmain">
      <div class="pmainL">
        <ul>
          <router-link
            to="pmessage"
            class="liactive"
            style="color: white"
            tag="li"
            >个人信息</router-link
          >
          <router-link to="myorder" tag="li">我的订单</router-link>
          <router-link to="advanceorder" tag="li">预付订单</router-link>
          <router-link to="mycollection" tag="li">我的收藏</router-link>
          <router-link to="myaddress" tag="li">我的地址</router-link>
          <router-link to="platform" tag="li">平台介绍</router-link>
          <router-link to="install" tag="li">设置</router-link>
        </ul>
      </div>
      <div class="paminR">
        <div class="pmessagebox">
          <!-- 用户信息 -->
          <div class="pmessageT">
            <div class="pimg">
              <img :src="avatar" alt="" />
            </div>
            <div class="pname">
              <p>{{ nickName }}</p>
              <p>{{ address }}</p>
            </div>
            <router-link tag="div" to="/revise" class="pbtn"
              >修改信息</router-link
            >
            <!-- <div class="pbtn">
                            修改信息
                        </div> -->
          </div>
          <div class="pmessageF">
            <router-link class="mcoupon" tag="div" to="mycoupon">
              <div class="mcouponimg"></div>
              我的优惠券
            </router-link>
            <!-- <div class="mcoupon">
                            <div class="mcouponimg"></div>
                            我的优惠券
                        </div> -->
            <div class="fgx"></div>
            <div class="mcoupon" @click="(extension = true), qrcode()">
              <div class="fenxiaoimg"></div>
              分享有礼
            </div>
          </div>
        </div>

        <!-- 我的积分 -->
        <div class="mintegral">
          <div class="mintegralT">
            <div class="integralimg"></div>
            <div class="myinte">我的积分</div>
            <div class="integralS">
              总积分：<span>{{ point }} </span> 积分
            </div>
          </div>
          <div class="integralList">
            <ul>
              <li v-for="item in pointList" :key="item.pointLogId">
                <div class="iconsume">
                  <p v-if="item.pointType == 1">
                    消费金额{{ item.payAmount }}元
                  </p>
                  <p v-if="item.pointType == 2">签到获得积分</p>
                  <p v-if="item.pointType == 100">
                    消费抵扣金额{{ item.payAmount }}元
                  </p>
                  <p v-if="item.pointType == 4">
                    下级购买{{ item.payAmount }}元
                  </p>
                  <p>{{ item.createTime | timeFiler }}</p>
                </div>
                <div class="addinte">
                  <span v-show="item.currentPoint >= 0">+</span
                  >{{ item.currentPoint }}积分
                </div>
              </li>
            </ul>
          </div>
          <div class="pages">
            <div style="width: 100%; display: flex; margin: 0 auto">
              <div class="home-pages" @click="goFirst">首页</div>
              <el-pagination
               
                @current-change="handlePageChange"
                background
                @size-change="handleSizeChange"
                 :current-page="list.pageNum"
                :page-size="6"
                :total="pagetotal"
                layout="prev, pager, next"
              >
              </el-pagination>
              <div class="last-pages" @click="goLast">尾页</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 分销推广 -->
    <el-dialog :visible.sync="extension" class="extensionbox">
      <div class="extension">
        <div class="extclose" @click="extension = false"></div>
        <div class="extensionimg">
          <div class="extensimg" id="qrcode3" ref="qrcode3"></div>
        </div>
        <div class="invitenum">邀请码：{{ shareCode }}</div>
        <div class="extensionbtn">保存分享</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      uid: localStorage.getItem("uid"),
      nickName: localStorage.getItem("nickName"),
      address: localStorage.getItem("address"),
      avatar: localStorage.getItem("avatar"),
      registerUrl: localStorage.getItem("registerUrl"),
      shareCode: localStorage.getItem("shareCode"),

      // 分销推广
      extension: false,

      // 添加新地址
      addition: false,

      // 删除订单
      delorder: false,

      // 售后退款
      refund: false,

      order: {
        status: "",
      },
      form: {
        desc: "",
      },

      ruleForm: {
        name: "",
        phone: "",
        adderss: "",
      },

      rules: {
        name: [
          { required: true, message: "请输入收货人姓名", trigger: "change" },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'change' }
        ],
        phone: [
          { required: true, message: "请输入电话号码", trigger: "change" },
          { min: 11, max: 11, message: "请输入正确号码", trigger: "change" },
        ],
        adderss: [
          { required: true, message: "请输入详细地址", trigger: "change" },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'change' }
        ],
      },

      pointList: [], //积分列表
      // payAmount:0,
      point: 0, //总积分
      pointtotal: 0, //积分总数
      setVal3: "", //平台介绍
      list: {
        pageSize: 6,
        pageNum: 1,
      },
      pagetotal: 0,
    };
  },
  created() {
    // this.getCityCode();
    // this.getCollectList()
  },
  methods: {
    deleteorder() {
      this.delorder = true;
    },
    isdefaultaddress() {
      this.isdefault = !this.isdefault;
    },

    qrcode() {
      let qrCodeUrl = this.registerUrl + this.shareCode;
      console.log(this.shareCode);
      console.log(qrCodeUrl);
      this.$nextTick(() => {
        let qrcode = new QRCode("qrcode3", {
          width: 57,
          height: 57,
          text: qrCodeUrl,
        });
        console.log(qrcode);
      });
      // document.getElementById("qrcode3").innerHTML = "";
    },

    /*==========================我的积分===========================*/
    getPointList() {
      this.$api.post({
        path: "/myorder/getPointList",
        params: {
          uid: this.uid,
          pageNum: this.list.pageNum,
          pageSize: 6,
        },
        success: (data) => {
          console.log(data);
          this.pointList = data.data.list;
          this.point = data.data.point;
          this.pagetotal = data.data.total;
          // console.log(data);
          this.payAmount = data.data.list.payAmount;

          // console.log(this.payAmount);
        },
      });
    },

    goFirst() {
      this.list.pageNum = 1;
      this.getPointList();
    },
    goLast() {
      this.list.pageNum = Math.ceil(this.pagetotal / 6);
      console.log(this.pagetotal);
      this.getPointList();
    },

    handlePageChange(val) {
      this.list.pageNum = val;
      this.getPointList();
    },
    handleSizeChange(val) {
      this.list.pageSize = val;
      this.getPointList();
    },
    /*====================订单列表=========================*/
    getOrderList() {
      this.$api.post({
        path: "/myorder/getOrderList",
        params: {
          uid: this.uid,
          order_type: 2,
          status: this.order.status,
        },
        success: (data) => {
          console.log(data);
          if (data.data.list.length == 0) {
            this.noData = "暂无数据";
            this.orderList = data.data.list;
            this.ordertotal = 0;
          } else {
            this.orderList = data.data.list;
            this.ordertotal = data.data.total;
          }
        },
      });
    },
    getType(v) {
      this.order.status = v;
      console.log(v);
      this.getOrderList();
    },
  },
  filters: {
    // 时间过滤器
    timeFiler(value) {
      let times = /\d{4}-\d{1,2}-\d{1,2}/g.exec(value);
      return times[0];
    },
  },

  mounted() {
    // this.getAddresList()
    this.getPointList();
    // this.getZiyuanDesc()
    this.getOrderList();
  },
};
</script>

<style>
.Pbox {
  width: 100%;
  margin: 130px auto;
}

.pmain {
  width: 100%;
  display: flex;
}

.pmainL {
  width: 180px;
  margin-right: 10px;
}

.pmainL ul {
  width: 180px;
}

.el-breadcrumb__item:last-child .el-breadcrumb__inner {
  color: #2f69d2;
}

.pmainL li {
  list-style: none;
  height: 48px;
  width: 65px;
  padding: 0px 57px;
  font-size: 16px;
  font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
  /* font-weight: bold; */
  text-align: left;
  color: #1a1a1a;
  line-height: 48px;
}

.liactive {
  background-color: #2f69d2;
  font-weight: bold;
  color: #ffffff;
}

.paminR {
  width: 650px;
}

.invitenum {
  position: absolute;
  bottom: 130px;
  left: 45px;
  width: 110px;
  /* height: 14px; */
  font-size: 14px;
  font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
}

.pmessagebox {
  width: 560px;
  height: 88px;
  padding: 20px 20px 12px 20px;
  background: #f6f6f6;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.pmessageT {
  width: 100%;
  height: 48px;
  display: flex;
  margin-bottom: 24px;
}

.pimg {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: thistle;
  margin-right: 10px;
}

.pimg img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.pname {
  height: 100%;
  font-size: 14px;
  font-family: PingFang SC Medium, PingFang SC Medium-Medium;
}

.pname p:first-child {
  font-weight: bold;
  color: #000000;
  margin-bottom: 8px;
}

.pamin p:last-child {
  color: #666666;
}

.pbtn {
  width: 82px;
  height: 30px;
  background: #2f69d2;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC Medium, PingFang SC Medium-Medium;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  line-height: 30px;
  margin-left: auto;
  margin-top: 9px;
}

.pmessageF {
  width: 60%;
  height: 17px;
  display: flex;
  font-size: 14px;
  font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
  font-weight: bold;
  text-align: left;
  color: #000000;
  line-height: 17px;
}

.mcoupon {
  height: 100%;
  display: flex;
}

.mcouponimg {
  width: 18px;
  height: 16px;
  background-image: url(../../assets/mcoupon.png);
  background-size: 100%;
  margin-right: 9px;
}

.fgx {
  margin: 0 70px;
  height: 100%;
  border: 1px solid rgb(153, 153, 153);
}

.fenxiaoimg {
  width: 18px;
  height: 14px;
  background-image: url(../../assets/distribution.png);
  background-size: 100%;
  margin-right: 7px;
}

/* ===============================我的积分=================================== */
.mintegral {
  width: 600px;
  height: 520px;
  /* background: #cccccc; */
  border: 1px solid #cccccc;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
}

.mintegralT {
  width: 560px;
  height: 30px;
  padding: 10px 20px;
  background-color: rgb(246, 246, 246);
  border-bottom: 1px solid #cccccc;
  display: flex;
  margin-bottom: 4px;
}

.integralimg {
  width: 30px;
  height: 30px;
  background-image: url(../../assets/integral.png);
  background-size: 100%;
  margin-right: 9px;
}

.myinte {
  height: 100%;
  font-size: 16px;
  font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
  font-weight: 600;
  text-align: left;
  color: #1a1a1a;
  line-height: 30px;
}

.integralS {
  height: 100%;
  font-size: 14px;
  font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
  font-weight: 500;
  text-align: left;
  color: #999999;
  line-height: 30px;
  margin-left: auto;
}

.integralS span {
  color: black;
  font-weight: 600;
}

.integralList {
  width: 562px;
  height: 366px;
  padding: 0 19px;
  margin-bottom: 32px;
}

.integralList li {
  list-style: none;
  width: 100%;
  height: 46px;
  padding-top: 15px;
  border-bottom: 1px solid #cccccc;
  display: flex;
}

.iconsume {
  width: 50%;
  height: 100%;
}

.iconsume p:first-child {
  font-size: 16px;
  font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
  font-weight: bold;
  text-align: left;
  color: #1a1a1a;
  margin-bottom: 5px;
}

.iconsume p:last-child {
  font-size: 12px;
  font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
  font-weight: Normal;
  text-align: left;
  color: #999999;
}

.addinte {
  width: 50%;
  font-size: 16px;
  font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
  font-weight: bold;
  text-align: right;
  color: #ff0000;
}

/* =======================页码======================= */
.pages {
  width: 630px;
  height: 40px;
  padding-left: 20px;
  margin: 0 auto;
  /* background-color: thistle; */
  display: flex;
}

.home-pages {
  width: 60px;
  height: 36px;
  background: white;
  border: 1px solid #cccccc;
  font-size: 14px;
  font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
  font-weight: 400;
  text-align: center;
  color: #1a1a1a;
  line-height: 36px;
  margin-right: 10px;
}

.last-pages {
  width: 60px;
  height: 36px;
  background: white;
  border: 1px solid #cccccc;
  font-size: 14px;
  font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
  font-weight: 400;
  text-align: center;
  color: #1a1a1a;
  line-height: 36px;
  margin-left: 10px;
}

.el-pagination .btn-prev,
.el-pagination .btn-next {
  width: 36px;
  height: 36px;
  background-color: #cccccc;
}

.el-pager li {
  width: 36px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  background-color: #cccccc;
}

.rl-pager .active {
  background-color: #2f69d2;
}

/* =========================分销推广=========================== */
.el-dialog {
  background-color: transparent;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}

.extension {
  width: 200px;
  height: 395px;
  margin: 100px auto;
  /* background-color: white; */
  position: relative;
}

.extclose {
  width: 22px;
  height: 22px;
  background-image: url(../../assets/close.png);
  background-size: 100%;
  position: absolute;
  top: -30px;
  right: -30px;
}

.extensionimg {
  width: 195px;
  height: 337px;
  margin: 0 auto;
  background-image: url(../../assets/extension.png);
  background-size: 100%;
  margin-bottom: 20px;
}

.extensimg {
  width: 57px;
  height: 57px;
  background-color: thistle;
  position: absolute;
  bottom: 181px;
  left: 70px;
}

.extensionbtn {
  width: 200px;
  height: 36px;
  /* background: #ffffff; */
  border: 1px solid #ffffff;
  border-radius: 4px;
  font-size: 16px;
  font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  line-height: 36px;
}

.extensionbox .el-dialog__headerbtn {
  top: 133px;
  right: 336px;
  z-index: 999;
}
</style>