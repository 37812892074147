<template>
  <div class="Pbox">
    <div class="navigation">
      <div class="home"></div>
      <div class="current">当前位置:</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/pmessage">个人中心</el-breadcrumb-item>
        <el-breadcrumb-item>我的收藏</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="pmain">
      <div class="pmainL">
        <ul>
          <router-link to="pmessage" tag="li">个人信息</router-link>
          <!-- <router-link to="myorder" tag="li">我的订单</router-link> -->
          <router-link to="myorder" tag="li">我的订单</router-link>
          <router-link to="advanceorder" tag="li">预付订单</router-link>
          <router-link
            to="mycollection"
            class="liactive"
            style="color: white"
            tag="li"
            >我的收藏</router-link
          >
          <router-link to="myaddress" tag="li">我的地址</router-link>
          <router-link to="platform" tag="li">平台介绍</router-link>
          <router-link to="install" tag="li">设置</router-link>
        </ul>
      </div>

      <div class="mycollection">
        <div class="collections">
          <div
            class="hotgoods"
            v-for="item in collectList"
            :key="item.goodsId"
            @click="goodsDetail(item.goodsId)"
          >
            <div class="hot-img">
              <img :src="item.coverImg" alt="" />
            </div>
            <div class="hot-name">
              {{ item.goodsName }}
            </div>
            <div class="hot-pice">￥{{ item.priceMin }}</div>
            <div class="collec">
              <img
                v-show="item.goodsMode == 1"
                src="../../assets/ptuan.png"
                alt=""
              />
              <img
                v-show="item.goodsMode == 2 && item.isPre == 1"
                src="../../assets/yufu.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="orderpage" style="width: 100%">
          <div style="display: flex; margin: 0 auto">
            <div class="home-orderpage" @click="goFirst">首页</div>
            <el-pagination
              background
              layout="prev, pager, next"
              @current-change="handlePageChange"
              :current-page.sync="list.pageNum"
              :page-size="10"
              :total="pagetotal"
            >
            </el-pagination>
            <div class="last-orderpage" @click="goLast">尾页</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      uid: localStorage.getItem("uid"),
      nickName: localStorage.getItem("nickName"),
      address: localStorage.getItem("address"),
      avatar: localStorage.getItem("avatar"),
      // 分销推广
      extension: false,

      // 添加新地址
      addition: false,

      // 删除订单
      delorder: false,

      // 售后退款
      refund: false,

      order: {
        status: "",
      },
      form: {
        desc: "",
      },

      provinceIndex: "",
      provinceName: "",
      provinceList: [],

      cityIndex: "",
      cityName: "",
      cityList: [],

      districtIndex: "",
      districtName: "",
      districtList: [],

      streetIndex: "",
      streetName: "",
      streetList: [],

      ruleForm: {
        name: "",
        phone: "",
        adderss: "",
      },

      rules: {
        name: [
          { required: true, message: "请输入收货人姓名", trigger: "change" },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'change' }
        ],
        phone: [
          { required: true, message: "请输入电话号码", trigger: "change" },
          { min: 11, max: 11, message: "请输入正确号码", trigger: "change" },
        ],
        adderss: [
          { required: true, message: "请输入详细地址", trigger: "change" },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'change' }
        ],
      },

      addresList: [], //地址列表
      area_name: [], //省市区街道
      isdefault: false, //默认地址

      pointList: [], //积分列表
      point: 0, //总积分
      pointtotal: 0, //积分总数
      setVal3: "", //平台介绍

      collectList: [], //收藏列表
      collecttotal: 0, //收藏总数

      // 获取验证码
      phoneShow: true,
      passShow: true,
      count: "",
      timer: null,
      sms_id: "",

      // 修改手机号
      modifyphon: {
        oldphone: "",
        newphone: "",
        code: "",
      },

      // 修改密码
      modifypass: {
        phone: "",
        pass: "",
        newpass: "",
        code: "",
      },
      // 我的订单
      orderList: [],
      ordertotal: 0, //订单中个数
      // 预付列表
      advanceList: [],
      advanceorde: {
        status: "",
      },
      atotal: 0,
      noData: "",
      aData: "",

      list: {
        pageSize: 10,
        pageNum: 1,
      },
      pagetotal: 0,
    };
  },
  created() {
    // this.getCityCode();
    this.getCollectList();
  },
  methods: {
    deleteorder() {
      this.delorder = true;
    },
    isdefaultaddress() {
      this.isdefault = !this.isdefault;
    },
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
      console.log(this.currentPage); //点击第几页
    },
    /*=========================我的收藏=====================*/
    getCollectList() {
      this.$api.post({
        path: "/myorder/getCollectList",
        params: {
          uid: this.uid,
          pageNum: this.list.pageNum,
          pageSize: 10,
        },
        success: (data) => {
          console.log(data);
          this.collectList = data.data.list;
          this.pagetotal = data.data.total;
        },
      });
    },
    goFirst() {
      this.list.pageNum = 1;
      this.getCollectList();
    },
    goLast() {
      this.list.pageNum = Math.ceil(this.pagetotal / 10);
      this.getCollectList();
      console.log(this.list.pageNum);
    },
    handlePageChange(val) {
      this.list.pageNum = val;
      this.getCollectList();
    },

    // 商品详情
    goodsDetail(id) {
      console.log(id);
      this.$router.push(`/goodsDetails/${id}`);
    },
    /*=========================平台介绍=========================*/

    getZiyuanDesc() {
      this.$api.post({
        path: "/myorder/getZiyuanDesc",
        params: {},
        success: (data) => {
          console.log(data);
          this.setVal3 = data.data.setVal3;
        },
      });
    },

    /*======================修改手机号===================*/
    // 获取验证码
    getCode(v) {
      if (v == 4) {
        this.$api.post({
          path: "/user/getCode",
          params: {
            phone: this.modifyphon.newphone,
            type: v, //1用户注册 2用户忘记密码, 3用户换绑原手机, 4用户手机号
          },
          success: (data) => {
            console.log(data);

            this.sms_id = data.data;
          },
        });
      } else if (v == 2) {
        this.$api.post({
          path: "/user/getCode",
          params: {
            phone: this.modifypass.phone,
            type: v, //1用户注册 2用户忘记密码, 3用户换绑原手机, 4用户手机号
          },
          success: (data) => {
            console.log(data);
            this.sms_id = data.data;
            if (data.code == 1) {
              this.$message({
                message: data.msg,
                type: "success",
              });
            }
          },
        });
      }

      if (!this.timer) {
        this.count = 60;
        if (v == 4) {
          this.phonshow = false;
        } else if (v == 2) {
          this.passShow = false;
        }
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= 60) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    // 修改手机号
    updateUserPhone() {
      this.$api.post({
        path: "/myorder/ updateUserPhone",
        params: {
          uid: this.uid,
          phone: this.modifyphon.oldphone,
          new_phone: this.modifyphon.newphone,
          sms_id: this.sms_id,
          sms_code: this.modifyphon.code,
        },
        success: (data) => {
          console.log(data);
          if (data.code == 1) {
            this.$message({
              message: data.msg,
              type: "success",
            });

            this.modifyphon.oldphone = "";
            this.modifyphon.newphone = "";
            this.modifyphon.code = "";
            // this.modifyphon.pass = ""
          }
        },
      });
    },
    // 修改密码
    updateUserPwd() {
      this.$api.post({
        path: "/myorder/updateUserPwd",
        params: {
          uid: this.uid,
          phone: this.modifypass.phone,
          pwd: this.modifypass.newpass,
          sms_id: this.sms_id,
          sms_code: this.modifypass.code,
        },
        success: (data) => {
          console.log(data);
          if (data.code == 1) {
            this.$message({
              message: data.msg,
              type: "success",
            });

            this.modifypass.phone = "";
            this.modifypass.newpass = "";
            this.modifypass.code = "";
            this.modifypass.pass = "";
          }
        },
      });
    },
  },

  mounted() {
    // this.getAddresList()
    // this.getPointList()
    // this.getZiyuanDesc()
    // this.getOrderList()
  },
};
</script>

<style>
.Pbox {
  width: 100%;
  margin: 130px auto;
}

.el-breadcrumb__item:last-child .el-breadcrumb__inner {
  color: #2f69d2;
}

.pmain {
  width: 100%;
  display: flex;
}

.pmainL {
  width: 180px;
  margin-right: 10px;
}

.pmainL ul {
  width: 180px;
}

.pmainL li {
  list-style: none;
  height: 48px;
  width: 65px;
  padding: 0px 57px;
  font-size: 16px;
  font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
  /* font-weight: bold; */
  text-align: left;
  color: #1a1a1a;
  line-height: 48px;
}

.liactive {
  background-color: #2f69d2;
  font-weight: bold;
  color: #ffffff;
}

.mycollection {
  width: 970px;
  height: 100%;
}

.collections {
  width: 100%;
  height: 720px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.hotgoods {
  width: 224px;
  height: 340px;
  background: #ffffff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
  border-radius: 10px;
  margin-right: 20px;
  position: relative;
}

.hotgoods:nth-child(4n) {
  margin-right: 0px;
}

.hot-img {
  width: 224px;
  height: 224px;
  margin-bottom: 20px;
  background-color: thistle;
  border-radius: 10px 10px 0 0;
}

.hot-img img {
  width: 100%;
  height: 100%;
  background-color: thistle;
  border-radius: 10px 10px 0 0;
}

.hot-name {
  width: 185px;
  font-size: 16px;
  font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
  font-weight: 600;
  text-align: left;
  color: #333;
  line-height: 26px;
  margin: 0 auto;
  margin-bottom: 16px;
}

.hot-pice {
  width: 185px;
  margin: 0 auto;
  font-size: 20px;
  font-family: Source Han Sans CN Bold, Source Han Sans CN Bold-Bold;
  font-weight: 700;
  text-align: left;
  color: #ff271e;
  /* line-height: 40px; */
}

.collec {
  width: 36px;
  height: 20px;
  position: absolute;
  top: 10px;
  left: 10px;
}

.collec img {
  width: 100%;
  height: 100%;
}
</style>