<template id="app">
  <router-view></router-view>
</template>

<script>


export default {
 
}
</script>

<style lang='scss'>
  *{
    margin: 0;
    padding: 0;
  }
    html,body{
      height: 100%;
    }
  </style>