<template>
  <div class="Pbox">
    <div class="navigation">
      <div class="home"></div>
      <div class="current">当前位置:</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/pmessage">个人中心</el-breadcrumb-item>
        <el-breadcrumb-item>我的地址</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="pmain">
      <div class="pmainL">
        <ul>
          <router-link to="pmessage" tag="li">个人信息</router-link>
          <!-- <router-link to="myorder" tag="li">我的订单</router-link> -->
          <router-link to="myorder" tag="li">我的订单</router-link>
          <router-link to="advanceorder" tag="li">预付订单</router-link>
          <router-link to="mycollection" tag="li">我的收藏</router-link>
          <router-link to="myaddress" class="liactive" style="color: white" tag="li">我的地址</router-link>
          <router-link to="platform" tag="li">平台介绍</router-link>
          <router-link to="install" tag="li">设置</router-link>
        </ul>
      </div>
      <div class="myaddress">
        <div class="myaddressList">
          <div class="address" v-for="(item,k) in addresList" :key="item.addressId"
            @click="selectAddress(item.addressId),active(k)">
            <div class="address-top">
              <div class="username">
                <span>{{ item.receiver }}</span><span>{{ item.receiverPhone }}</span>
              </div>
              <div class="default" v-show="item.isDefault == 1">默认地址</div>
            </div>
            <div class="address-main">
              <span v-for="(txt, ind) in item.areaName" :key="ind">
                <span v-if="ind % 2 == 0">{{ txt }}</span></span>
              {{ item.addressDetail }}
            </div>
            <div class="address-foot">
              <span @click="edit(item.addressId), (editition = true)">修改</span>
              <span @click="edit(item.addressId),delorder=true">删除</span>
            </div>
            <div class="acheck" v-if="index == k"></div>
          </div>
        </div>

        <div class="newaddress" @click="addition = true">使用新地址</div>
      </div>
    </div>

    <el-dialog :visible.sync="delorder" class="del">
      <div class="delbox">
        <div class="deltext">确定要删除吗？</div>
        <div class="delbtn">
          <span @click="delorder = false">取消</span>
          <span @click="getdelAddress">删除</span>
        </div>
      </div>
    </el-dialog>
    <!-- 添加地址 -->
    <el-dialog :visible.sync="addition" class="additionbox">
      <div class="addition">
        <div class="additionclose" @click="addition = false"></div>
        <div class="reject-title">新增地址</div>
        <div class="reject-line"></div>
        <el-form :model="ruleForm" size="small" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="收货人姓名" prop="name" class="froms">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="phone" class="froms">
            <el-input v-model="ruleForm.phone"></el-input>
          </el-form-item>
          <el-form-item label="收货地址" prop="cityName">
            <el-select v-model="provinceIndex" v-on:change="
                getCityCode(provinceIndex, 2);
                (cityIndex = ''),
                  (districtIndex = ''),
                  (streetIndex = ''),
                  changeLocationValue(provinceIndex);
              " placeholder="请选择省">
              <el-option v-for="item in provinceList" :key="item.areaId" :label="item.areaName" :value="item.areaId">
              </el-option>
            </el-select>

            <el-select v-model="cityIndex" v-on:change="
                getCityCode(cityIndex, 3);
                (districtIndex = ''),
                  (streetIndex = ''),
                  changeLocationValue2(cityIndex);
              " placeholder="请选择市">
              <el-option v-for="item in cityList" :key="item.areaId" :label="item.areaName" :value="item.areaId">
              </el-option>
            </el-select>

            <el-select v-model="districtIndex" v-on:change="
                getCityCode(districtIndex, 4);
                (streetIndex = ''), changeLocationValue3(districtIndex);
              " placeholder="请选择区/县">
              <el-option v-for="item in districtList" :key="item.areaId" :label="item.areaName" :value="item.areaId">
              </el-option>
            </el-select>

            <el-select v-model="streetIndex" placeholder="请选择街道" @change="changeLocationValue4(streetIndex)">
              <el-option v-for="item in streetList" :key="item.areaId" :label="item.areaName" :value="item.areaId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="详细地址" prop="adderss" class="froms">
            <el-input v-model="ruleForm.adderss"></el-input>
          </el-form-item>
        </el-form>
        <div class="check" style="margin-left: 100px; margin-top: 30px; width: 30%">
          <div @click="isdefaultaddress(ruleForm.is_default)">
            <img v-show="ruleForm.is_default" src="../../assets/check.png" alt="" />
            <img v-show="!ruleForm.is_default" src="../../assets/checkno.png" alt="" />
          </div>
          <span>设置为默认地址</span>
        </div>
        <div class="addAddress" @click="getaddAddres">保存</div>
      </div>
    </el-dialog>

    <!-- 编辑地址 -->
    <el-dialog :visible.sync="editition" class="additionbox">
      <div class="addition">
        <div class="additionclose" @click="editition = false"></div>
        <div class="reject-title">编辑地址</div>
        <div class="reject-line"></div>
        <el-form :model="ruleForms" size="small" :rules="rules" ref="ruleForms" label-width="100px"
          class="demo-ruleForm editition">
          <el-form-item label="收货人姓名" prop="name" class="froms">
            <el-input v-model="ruleForms.name"></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="phone" class="froms">
            <el-input v-model="ruleForms.phone"></el-input>
          </el-form-item>
          <el-form-item label="收货地址" prop="cityName">
            <el-select v-model="provinceIndex" v-on:change="
                getCityCode(provinceIndex, 2);
                (cityIndex = ''),
                  (districtIndex = ''),
                  (streetIndex = ''),
                  changeLocationValue(provinceIndex);
              " :placeholder="province">
              <el-option v-for="item in provinceList" :key="item.areaId" :label="item.areaName" :value="item.areaId">
              </el-option>
            </el-select>

            <el-select v-model="cityIndex" v-on:change="
                getCityCode(cityIndex, 3);
                (districtIndex = ''),
                  (streetIndex = ''),
                  changeLocationValue2(cityIndex);
              " :placeholder="city">
              <el-option v-for="item in cityList" :key="item.areaId" :label="item.areaName" :value="item.areaId">
              </el-option>
            </el-select>

            <el-select v-model="districtIndex" v-on:change="
                getCityCode(districtIndex, 4);
                (streetIndex = ''), changeLocationValue3(districtIndex);
              " :placeholder="district">
              <el-option v-for="item in districtList" :key="item.areaId" :label="item.areaName" :value="item.areaId">
              </el-option>
            </el-select>

            <el-select v-model="streetIndex" :placeholder="street" @change="changeLocationValue4(streetIndex)">
              <el-option v-for="item in streetList" :key="item.areaId" :label="item.areaName" :value="item.areaId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="详细地址" prop="adderss" class="froms">
            <el-input v-model="ruleForms.adderss"></el-input>
          </el-form-item>
        </el-form>
        <div class="check" style="margin-left: 100px; margin-top: 30px; width: 30%">
          <div @click="isdefaultaddress(ruleForms.is_default)">
            <img v-show="ruleForms.is_default" src="../../assets/check.png" alt="" />
            <img v-show="!ruleForms.is_default" src="../../assets/checkno.png" alt="" />
          </div>
          <span>设置为默认地址</span>
        </div>
        <div class="addAddress" @click="editAddress()">保存</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        uid: localStorage.getItem("uid"),
        nickName: localStorage.getItem("nickName"),
        address: localStorage.getItem("address"),
        avatar: localStorage.getItem("avatar"),
        // 分销推广
        extension: false,

        // 添加新地址
        addition: false,
        editition: false,
        editid: "",
        // 删除订单
        delorder: false,

        // 售后退款
        refund: false,

        order: {
          status: "",
        },
        form: {
          desc: "",
        },
        index: 0,
        provinceIndex: "",
        provinceName: "",
        provinceList: [],
        province: '',

        cityIndex: "",
        cityName: "",
        cityList: [],
        city: '',

        districtIndex: "",
        districtName: "",
        districtList: [],
        district: '',

        streetIndex: "",
        streetName: "",
        streetList: [],
        street: '',

        ruleForm: {
          name: "",
          phone: "",
          adderss: "",
          is_default: 0,
        },
        ruleForms: {
          name: "",
          phone: "",
          adderss: "",
          is_default: 0,
        },
        id: '',
        rules: {
          name: [
            { required: true, message: "请输入收货人姓名", trigger: "blur" },
            // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'change' }
          ],
          phone: [
            { required: true, message: "请输入电话号码", trigger: "blur" },
            { min: 11, max: 11, message: "请输入正确号码", trigger: "blur" },
          ],
          adderss: [
            { required: true, message: "请输入详细地址", trigger: "blur" },
            // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'change' }
          ],
        },

        addresList: [], //地址列表
        area_name: [], //省市区街道
        edit_name: [],
        isdefault: false, //默认地址
        addressid: "", //选择地址id
        isAddress: false,

        eaddress: '',

        addressd:{},

        atotal: 0,
        noData: "",
        aData: "",
        // isAddress: false,
      };
    },
    created() {
      this.getCityCode();
    },
    methods: {
      deleteorder() {
        this.delorder = true;
      },

      active(k) {
        this.index = k;
      },
      /*==========================我的地址===========================*/

      // 地址列表
      // 地址列表
      getAddresList() {
        this.$api.post({
          path: "/myorder/getAddresList",
          params: {
            pageNum: 1,
            pageSize: 10,
            uid: this.uid,
          },
          success: (data) => {
            console.log(data, "地址");
            this.addresList = data.data.list;
            this.addresList.forEach((e, index) => {
              e.areaName = e.areaName.split(",");
              if (e.isDefault) {
                this.index = index
                this.adderssId = e.addressId
              }
            });
          },
        });
      },
      isdefaultaddress(is_default) {
        console.log(is_default);
        if (is_default) {
          this.ruleForm.is_default = 0;
          this.ruleForms.is_default = 0;
        } else {
          this.ruleForm.is_default = 1;
          this.ruleForms.is_default = 1;
        }
      },

      // isdefaultaddress(is_default) {
      //   console.log(is_default);
      //   if (is_default) {
      //     this.ruleForms.is_default = 0;
      //   } else {
      //     this.ruleForms.is_default = 1;
      //   }
      // },
      getCityCode(pid, type) {
        this.$api.post({
          path: `/user/getAreas`,
          params: {
            pid: pid ? pid : 0,
          },
          success: (data) => {
            if (!type) {
              this.provinceList = data.data;
            }
            console.log(data);
            // console.log(type);
            switch (type) {
              case 2:
                this.cityList = data.data;
                break;
              case 3:
                this.districtList = data.data;
                break;
              case 4:
                this.streetList = data.data;
                break;
              default:
                break;
            }
          },
        });
        console.log(this.provinceIndex);
      },

      // 省 下拉列表值
      changeLocationValue(val) {
        //locations是v-for里面的也是datas里面的值
        let obj = {};
        obj = this.provinceList.find((item) => {
          return item.areaId === val;
        });
        this.provinceName = obj.areaName;
        console.log(obj.areaName);
      },

      // 市 下拉列表值
      changeLocationValue2(val) {
        //locations是v-for里面的也是datas里面的值
        let obj2 = {};
        obj2 = this.cityList.find((item) => {
          return item.areaId === val;
        });
        this.cityName = obj2.areaName;
        console.log(obj2.areaName);
      },

      // 区/县 下拉列表值
      changeLocationValue3(val) {
        //locations是v-for里面的也是datas里面的值
        let obj3 = {};
        obj3 = this.districtList.find((item) => {
          return item.areaId === val;
        });
        this.districtName = obj3.areaName;
        console.log(obj3.areaName);
      },

      // 街道 下拉列表值
      changeLocationValue4(val) {
        //locations是v-for里面的也是datas里面的值
        let obj4 = {};
        obj4 = this.streetList.find((item) => {
          return item.areaId === val;
        });
        this.streetName = obj4.areaName;
        console.log(obj4.areaName);
      },


      getaddAddres() {
        this.area_name.push(
          this.provinceName,
          this.cityName,
          this.districtName,
          this.streetName
        );

        if (
          this.ruleForm.name == "" ||
          this.ruleForm.phone == "" ||
          this.ruleForm.adderss == "" ||
          this.area_name.join("") == ""
        ) {
          this.$message({
            showClose: true,
            message: "请填写完整信息",
            type: "warning",
          });
          // return false
        } else {
          this.$api.post({
            path: "/myorder/addAddress",
            params: {
              uid: this.uid,
              address_id: 0, //新增id传0，编辑传对应id
              receiver: this.ruleForm.name,
              receiver_phone: this.ruleForm.phone,
              area_name: this.area_name.join(""),
              address_detail: this.ruleForm.adderss,
              is_default: this.ruleForm.is_default, //是否默认：0非默认；1默认。C端用户有多个地址，每个用户只能有一个默认地址
            },
            success: (data) => {
              console.log(data);

              if (data.code == 1) {
                this.$message({
                  message: data.msg,
                  type: "success",
                });
                this.area_name = [];
                this.ruleForm.name = "";
                this.ruleForm.phone = "";
                this.ruleForm.adderss = "";
                this.provinceName = "";
                this.cityName = "";
                this.districtName = "";
                this.streetName = "";
                this.provinceIndex = "";
                this.cityIndex = "";
                this.districtIndex = "";
                this.streetIndex = "";
                this.addition = false;
              }
              this.getAddresList();
            },
          });
        }

        // this.addition = false;
      },



      // 勾选、取消默认地址
      defaultAddress(val) {
        this.$api.post({
          path: "/myorder/operationAddress",
          params: {
            uid: this.uid,
            address_id: val,
          },
        });
      },
      // 选择地址
      selectAddress(id) {
        console.log(id);
        this.adderssId = id;
        if (this.adderssId == id) {
          this.isAddress = true;
        }
      },

      // 删除地址
      getdelAddress() {
        this.$api.post({
          path: "/myorder/delAddress",
          params: {
            address_id: this.editid,
          },
          success: (data) => {
            console.log(data);
            if (data.code == 1) {
              this.$message({
                message: data.msg,
                type: "success",
              });
              this.delorder = false
              this.getAddresList();
            }
          },
        });
      },

      edit(v) {
        this.editid = v;
        this.$api.post({
          path: '/myorder/getAddressById',
          params: {
            addressId: v
          }, success: (data) => {
            console.log(data)
            this.addressd = data.data
            this.ruleForms.name = data.data.receiver
            this.ruleForms.phone = data.data.receiverPhone
            this.ruleForms.adderss = data.data.addressDetail
            this.eaddress = data.data.areaName

            var regex = "(?<province>[^省]+省|[^市]+市|.+自治区)(?<city>[^自治州]+自治州|[^市]+市|[^盟]+盟|[^地区]+地区|.+区划)(?<county>[^市]+市|[^县]+县|[^旗]+旗|.+区)?(?<town>[^区]+区|.+镇)?(?<village>.*)";
            var address = `${this.eaddress}`;
            var result = address.match(regex);
            console.log(result);

            this.province = result.groups.province
            this.city = result.groups.province
            this.district = result.groups.county
            this.street = result.groups.village

          }
        })
      },
      // 编辑
      editAddress() {
        this.edit_name.push(
          this.provinceName,
          this.cityName,
          this.districtName,
          this.streetName
        );

        if (this.edit_name == []) {
          this.eaddress = this.this.addressd.areaName
        }
        this.$api.post({
          path: "/myorder/addAddress",
          params: {
            uid: this.uid,
            address_id: this.editid, //新增id传0，编辑传对应id
            receiver: this.ruleForms.name,
            receiver_phone: this.ruleForms.phone,
            area_name: this.eaddress,
            address_detail: this.ruleForms.adderss,
            is_default: this.ruleForms.is_default,
          },
          success: (data) => {
            console.log(data);
            this.ruleForm.name = "";
            this.ruleForm.phone = "";
            this.ruleForm.adderss = "";
            this.provinceName = "";
            this.cityName = "";
            this.districtName = "";
            this.streetName = "";
            this.provinceIndex = "";
            this.cityIndex = "";
            this.districtIndex = "";
            this.streetIndex = "";
            this.editition = false;
            this.getAddresList();
            console.log(this.eaddress)
          },
        });
      },
    },

    mounted() {
      this.getAddresList();
      // this.getPointList()
      // this.getZiyuanDesc()
      // this.getOrderList()
    },
  };
</script>

<style>
  .Pbox {
    width: 100%;
    margin: 130px auto;
  }

  .el-breadcrumb__item:last-child .el-breadcrumb__inner {
    color: #2f69d2;
  }

  .pmain {
    width: 100%;
    display: flex;
  }

  .pmainL {
    width: 65px;
    margin-right: 10px;
  }

  .pmainL ul {
    width: 180px;
  }

  .pmainL li {
    list-style: none;
    height: 48px;
    width: 65px;
    padding: 0px 57px;
    font-size: 16px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    /* font-weight: bold; */
    text-align: left;
    color: #1a1a1a;
    line-height: 48px;
  }

  .liactive {
    background-color: #2f69d2;
    font-weight: bold;
    color: #ffffff;
  }

  .myaddress {
    width: 1000px;
  }

  .myaddressList {
    width: 1000px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  .address {
    width: 254px;
    height: 108px;
    padding: 10px 10px 20px 20px;
    background: #f6f6f6;
    border: 2px dashed #cccccc;
    border-radius: 4px;
    position: relative;
    margin-right: 17px;
    margin-bottom: 10px;
  }

  .address:nth-child(3m) {
    margin-right: 0px;
  }

  .address-top {
    width: 100%;
    height: 26px;
    display: flex;
    margin-bottom: 15px;
  }

  .username {
    width: 70%;
    height: 100%;
    font-size: 16px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    font-weight: 600;
    text-align: left;
    color: #333;
    line-height: 26px;
    /* background-color: thistle; */
  }

  .username span:last-child {
    display: inline-block;
    margin-left: 10px;
  }

  .default {
    width: 56px;
    height: 26px;
    /* opacity: 0.1; */
    background: rgba(47, 105, 210, 0.1);
    border-radius: 4px;
    font-size: 12px;
    font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
    font-weight: Normal;
    text-align: center;
    color: #2f69d2;
    line-height: 26px;
    margin-left: auto;
  }

  .address-main {
    width: 100%;
    height: 36px;
    font-size: 14px;
    font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
    font-weight: Normal;
    text-align: left;
    color: #1a1a1a;
    margin-bottom: 20px;
  }

  .address-foot {
    width: 88px;
    height: 14px;
    font-size: 14px;
    font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
    font-weight: Normal;
    line-height: 14px;
  }

  .address-foot span:first-child {
    display: inline-block;
    width: 50%;
    height: 100%;
    color: #2f69d2;
    text-align: left;
  }

  .address-foot span:last-child {
    display: inline-block;
    width: 50%;
    height: 100%;
    text-align: right;
    color: #ff0000;
  }

  .froms .el-input__inner {
    width: 98%;
    padding-left: 5px;
  }

  .acheck {
    position: absolute;
    width: 30px;
    height: 30px;
    background-image: url(../../assets/acheck.png);
    background-size: 100%;
    bottom: 0px;
    right: 0px;
  }

  .newaddress {
    width: 80px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #cccccc;
    font-size: 14px;
    font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
    font-weight: Normal;
    text-align: center;
    color: #2f69d2;
    line-height: 36px;
    /* margin-right: 35px; */
  }

  .platform {
    width: 645px;
  }

  .platformtitle {
    width: 85px;
    height: 20px;
    font-size: 18px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    font-weight: bold;
    text-align: right;
    color: #1a1a1a;
    line-height: 20px;
    border-left: 8px solid #2f69d2;
    margin-bottom: 20px;
  }

  .platformtext {
    width: 100%;
  }

  /* 下拉框的默认提示的颜色 */


  .editition .el-input__inner::placeholder {
    color: black;
    text-align: center;
  }

  /* 谷歌 */
  .editition .el-input__inner::-webkit-input-placeholder {
    color:  black;
    text-align: center;
  }

  /* 火狐 */
  .editition .el-input__inner:-moz-placeholder {
    color:  black;
    text-align: center;
  }

  /*ie*/
  .editition.el-input__inner:-ms-input-placeholder {
    color:  black;
    text-align: center;
  }
</style>