<template>
  <!-- 追溯 -->

  <div class="trace">
    <!-- 面包屑 -->
    <div class="navigation">
      <div class="home"></div>
      <div class="current">当前页面:</div>
      <router-link tag="div" to="/trace">追溯</router-link>
    </div>

    <div class="title">追溯</div>

    <!-- 查询 -->
    <div class="query">
      <input
        type="text"
        v-model="traceCode"
        class="querytext"
        placeholder="请输入溯源码查询"
      />
      <!-- <router-link tag="div" class="querybtn" to="trace">查询</router-link> -->
      <div class="querybtn" @click="gettraceCodeInfo">查询</div>
    </div>

    <!--查询信息 -->
    <div class="inquiry-details" v-if="code == 1">
      <div class="tracenum">
        溯源码：{{ tracelist.GoodsLogistics.trace_code }}
      </div>
      <div class="goods-news">
        <div class="goodsimg">
          <img :src="tracelist.GoodsLogistics.cover_img" alt="" />
        </div>
        <div class="goods-right">
          <div class="goodsName">{{ tracelist.GoodsLogistics.goods_name }}</div>
          <!-- 经营者 -->
          <div class="operator">
            商场经营者名称：{{ tracelist.TraceCompany.companyName }}
          </div>
        </div>
      </div>
    </div>

    <div class="goodstype" v-if="code == 1">
      <div v-for="(item, index) in tracelist.TraceField" :key="index">
        {{ item.fieldName }}：{{ item.filedVal }}
      </div>
    </div>

    <div class="logistics" v-if="code == 1">
      <div class="logistics-left">
        <p>
          物流公司：<span>{{
            tracelist.GoodsLogistics.logistics_com_name
          }}</span>
        </p>
        <p>
          物流单号：<span>{{ tracelist.GoodsLogistics.logistics_com_no }}</span>
        </p>
      </div>
      <div class="logisticsbtn">查看物流</div>
    </div>

    <div class="tishi" v-if="code == -1">暂无相关溯源码信息,请检查溯源码</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addVisible: false,
      tracelist: {},
      traceCode: "",
      code: 1,
      logisticsUrl: "",
    };
  },
  methods: {
    traceCodeInfo() {
      this.$api.post({
        path: "/goods/traceCodeInfo",
        params: {
          traceCode: this.$route.params.text,
        },
        success: (data) => {
          console.log(data);
          if (data.code == -1) {
            this.code = -1;
          } else {
            this.code = 1;
            this.tracelist = data.data;
            // this.logisticsUrl = this.tracelist.GoodsLogistics.logisticsUrl
          }
        },
      });
    },

    gettraceCodeInfo() {
      this.$api.post({
        path: "/goods/traceCodeInfo",
        params: {
          traceCode: this.traceCode,
        },
        success: (data) => {
          console.log(data);
          if (data.code == -1) {
            this.code = -1;
          } else {
            this.code = 1;
            this.tracelist = data.data;
            // this.tracelist = "";
            // this.logisticsUrl = this.tracelist.GoodsLogistics.logisticsUrl
          }
          // this.tracelist = data.data
          // this.tracelist = ""
        },
      });
    },
    getlogistics() {
      window.location.href = this.logisticsUrl;
    },
  },
  created() {
    console.log(this.$route.params.text);
    this.traceCodeInfo();
  },
};
</script>

<style lang="scss">
/* 版心 */
.trace {
  margin: 130px auto;
  width: 1200px;
}

/* 导航栏/面包屑 */
.navigation {
  width: 100%;
  height: 20px;
  font-size: 14px;
  font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
  font-weight: 500;
  display: flex;
  margin-bottom: 17px;
  margin-left: 5px;
}

.home {
  width: 16px;
  height: 16px;
  /* background-color: thistle; */
  background-image: url(../../assets/home.png);
  background-size: 100%;
  margin-right: 10px;
}

.current {
  color: #666666;
  margin-right: 10px;
}

.navigation div:last-child {
  color: #2f69d2;
}

/* 标题 */
.title {
  width: 60px;
  height: 26px;
  font-size: 20px;
  font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
  font-weight: bold;
  text-align: right;
  color: #1a1a1a;
  border-left: 6px solid #2f69d2;
  margin-bottom: 20px;
}

/* 搜索 */
.query {
  width: 320px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #2f69d2;
  display: flex;

  margin-bottom: 30px;
}

.querytext {
  width: 240px;
  height: 36px;
  background: none;
  outline: none;
  border: 0px;
}

.querybtn {
  width: 80px;
  height: 34px;
  background: #2f69d2;
  border: 1px solid #2f69d2;
  font-size: 16px;
  font-family: PingFang SC Medium, PingFang SC Medium-Medium;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
}

.querytext::-webkit-input-placeholder {
  /* placeholder颜色  */
  color: #cccccc;
  /* placeholder字体大小  */
  font-size: 12px;
  /* placeholder位置  */
  /* text-align: right; */
}

/* 追溯详情 */
.inquiry-details {
  width: 100%;
  /* background-color: thistle; */
}

.tracenum {
  /* width: 240px; */
  height: 20px;
  font-size: 18px;
  font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
  font-weight: 600;
  text-align: left;
  color: #2f69d2;
  /* line-height: 40px; */

  margin-bottom: 10px;
}

.goods-news {
  width: 570px;
  height: 160px;
  display: flex;
  margin-bottom: 20px;
}

.goodsimg {
  width: 160px;
  height: 160px;
  /* background-color: skyblue; */
  margin-right: 17px;
}

.goodsimg img {
  width: 100%;
  height: 100%;
}

.goods-right {
  /* background-color: white; */
  height: 100%;
  width: 400px;
}

.goodsName {
  width: 388px;
  height: 18px;
  font-size: 18px;
  font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
  font-weight: bold;
  text-align: left;
  color: #333;
  line-height: 95px;
  margin-bottom: 60px;
}

.operator {
  width: 375px;
  height: 36px;
  background: #f2f2f2;
  border-radius: 4px;
  padding-left: 20px;
  font-size: 14px;
  font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
  font-weight: 400;
  line-height: 36px;
  color: #666666;
}

.goodstype {
  width: 620px;
  height: 230px;
  font-size: 14px;
  font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
  font-weight: 400;
  text-align: left;
  color: #666666;
  line-height: 36px;
  padding: 20px;
  background: #f6f6f6;
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
  /* // flex-direction: column; */
}

.goodstype > div {
  width: 50%;
  width: 100%;
  font-size: 14px;
  font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
  font-weight: 400;
  /* text-align: left; */
  color: #666666;
  line-height: 36px;
}

.goodstype-left {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.goodstype-left div {
  width: 100%;
  font-size: 14px;
  font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
  font-weight: 400;
  /* text-align: left; */
  color: #666666;
  /* line-height: 36px; */
}

/* 物流 */
.logistics {
  width: 650px;
  height: 46px;
  padding: 20px;
  background: #f6f6f6;
  border-radius: 10px;
  display: flex;
}

.logistics-left {
  width: 50%;
  height: 100%;
  font-size: 16px;
  font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
  font-weight: 400;
  text-align: left;
  color: #1a1a1a;
}

.logistics-left p:first-child {
  margin-bottom: 10px;
}

.logisticsbtn {
  margin-left: auto;
  width: 68px;
  height: 26px;
  background: white;
  border: 1px solid #1a1a1a;
  border-radius: 4px;
  font-size: 12px;
  font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
  font-weight: bold;
  text-align: center;
  line-height: 26px;
  color: #1a1a1a;
  margin-top: 10px;
}

.tishi {
  width: 100%;
  height: 500px;
  text-align: center;
  line-height: 500px;
}
</style>