<template>
  <div class="buycart">
    <div class="navigation">
      <div class="home"></div>
      <div class="current">当前页面:</div>
      <router-link tag="div" to="/trace">购物车</router-link>
    </div>

    <table width="1200px" class="table">
      <tr style="
          height: 48px;
          background-color: rgb(252, 252, 252);
          font-size: 16px;
        ">
        <th style="width: 60px"></th>
        <th style="width: 169px">图片</th>
        <th style="width: 409px">商品名称</th>
        <th style="width: 190px">单价</th>
        <th style="width: 190px">数量</th>
        <th style="width: 177px">小计</th>
      </tr>
      <tr>
        <td colspan="6" v-if="cartList.length == 0" style="height: 50px">
          {{ noData }}
        </td>
      </tr>
      <tr style="height: 100px" v-for="(item, index) in cartList" :key="index">
        <td>
          <div class="check" style="width: 20px; height: 20px; margin: 0 auto" @click="check(item)">
            <img v-if="item.isChecked" src="../../assets/check.png" alt="" style="width: 20px; height: 20px" />
            <img v-else src="../../assets/checkno.png" alt="" style="width: 20px; height: 20px" />
          </div>
        </td>
        <td>
          <div class="goodsImage">
            <img :src="item.coverImg" alt="" />
          </div>
        </td>
        <td style="font-weight: 600">{{ item.goodsName }}</td>
        <td style="color: red; width: 200px; height: 100px">
          <div style="width: 100%; height: 100%">
            <p style="line-height: 100px">
              {{ getPrice(item) }}元
            </p>
          </div>
          <!-- {{pice}} -->
          <!-- {{item.priceMin}} -->
        </td>
        <td>
          <el-input-number v-model="item.cartNum" size="small" :min="1"></el-input-number>
        </td>
        <td style="color: red">{{ item.cartNum * item.sprice | numFilter }}元</td>
      </tr>
    </table>
    <div class="settlement">
      <div class="settlementL">
        <div class="allcheck" @click="checkAll">
          <img v-if="isSelectAll" src="../../assets/check.png" alt="" />
          <img v-else src="../../assets/checkno.png" alt="" />
        </div>
        <div class="alltext">全选</div>
        <div class="delete" @click="getsetSignOrder">删除</div>

        <div class="allnum">
          <div class="checknum">
            共<span>{{ count }}</span>件商品
          </div>
          <div class="checktotal">
            合计：<span>{{ totalPrice | numFilter}}元</span><span>（不含运费）</span>
          </div>
        </div>
      </div>
      <!-- <router-link tag="div" class="settlementbtn" to="/confirmorder">结算</router-link> -->
      <div class="settlementbtn" @click="getMyOrde(1)">结算</div>
    </div>

    <!-- 删除 -->
    <el-dialog :visible.sync="delVisible" class="del">
      <div class="delbox">
        <div class="deltext">确定要删除吗？</div>
        <div class="delbtn">
          <span @click="delVisible = false">取消</span>
          <span @click="delGoodsCart">删除</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        // 删除
        delVisible: false,
        flag: false,
        //全选
        isSelectAll: false,
        uid: localStorage.getItem("uid"),
        cartList: [], //购物车列表
        num: 0,
        goodsPriceList: [], //价格区间
        cartIdlist: [],
        noData: "",
        total: 0,
        cartNum: 0,
        pice: 0,
        pices: 0,
        goods: [
          {
            goods_id: 8,
            begin_num: 1,
          },
        ],
        is_cart:""
      };
    },

    methods: {
      // 购物车列表
      getcartList() {
        this.$api.post({
          path: "/myorder/cartList",
          params: {
            uid: this.uid,
          },
          success: (data) => {
            console.log(data);
            if (data.data.length == 0) {
              this.noData = "暂无数据";
              this.cartList = data.data;
            } else {
              this.cartList = data.data;
              this.goodsPriceList = data.data.goodsPriceList;
            }
          },
        });
      },
      // 单选
      check(item) {
        if (item.isChecked) {
          item.isChecked = 0;
        } else {
          item.isChecked = 1;
        }
      },

      //全部选中
      checkAll() {
        console.log(555);
        this.isSelectAll = !this.isSelectAll;
        this.cartList.forEach((item) => {
          item.isChecked = this.isSelectAll ? 1 : 0;
        });
      },

      getsetSignOrder() {
        let count = [];
        count = this.cartList.filter((e) => {
          return e.isChecked;
        });
        count.forEach(e => {
          this.cartIdlist.push(e.cartId)
        })
        console.log(this.cartIdlist);
        this.delVisible = true;
      },

      // 删除商品
      delGoodsCart() {
        this.$api.post({
          path: "/myorder/delGoodsCart",
          params: {
            cart_ids: this.cartIdlist.join(","),
          },
          success: (data) => {
            console.log(data);
            this.delVisible = false;

            if (data.code == 1) {
              this.$message({
                message: data.msg,
                type: "success",
              });
              this.getcartList()

            } else {
              this.$message.error("删除失败");
            }
          },
        });

        this.getcartList();
      },

      // 获取商品信息
      getMyOrde(v) {
        let count = [];
        count = this.cartList.filter((e) => {
          return e.isChecked;
        });
        console.log(count.length)
        if (count.length > 0) {
          let params = {};
          params.uid = localStorage.getItem("uid")
          params.goods = []
          params.is_cart = v
          count.forEach((e) => {
            params.goods.push({
              "goods_id": e.goodsId,
              "begin_num": e.cartNum
            })
          });
          console.log(params);
          sessionStorage.setItem("myOrder", JSON.stringify(params))
          this.$router.push("/confirmorder")
        } else {
          this.$message.error('请选择商品');
        }
      },

    },
    computed: {
      getPrice() {
        return (v) => {
          v.sprice = 0;
          v.goodsPriceList.forEach((e) => {
            if (v.cartNum >= e.beginNum && v.cartNum <= e.endNum) {
              v.sprice = e.price;
            }
          });
          return v.sprice;
        };
      },
      count() {
        let count = [];
        let itemcont = 0;
        count = this.cartList.filter((e) => {
          return e.isChecked;
        });
        count.forEach((e) => {
          itemcont += e.cartNum;
        });
        // itemcont = count.length

        return itemcont;
      },
      selectAll() {
        let count = [];
        count = this.cartList.filter((e) => {
          return e.isChecked;
        });
        if (count.length == this.cartList.length && count.length != 0) {
          return true;
        } else {
          return false;
        }
      },
      totalPrice() {
        let count = [];
        let totalPrice = 0;
        count = this.cartList.filter((e) => {
          return e.isChecked;
        });
        count.forEach((e) => {
          totalPrice += e.cartNum * e.sprice;
          Math.round(totalPrice * 100) / 100
        });
        return totalPrice;
      },
    },
    filters: {
      numFilter(value) {
        // 截取当前数据到小数点后两位
        let realVal = parseFloat(value).toFixed(2)
        return realVal
      }
    },
    watch: {
      selectAll(v) {
        if (v) {
          this.isSelectAll = true;
        } else {
          this.isSelectAll = false;
        }
      },
    },
    mounted() {
      this.getcartList();
    },
    created() { },
  };
</script>

<style>
  .buycart {
    width: 1200px;
    margin: 120px auto;
    margin-bottom: 0;
  }

  /* 导航栏/面包屑 */
  .navigation {
    width: 100%;
    height: 20px;
    font-size: 14px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    font-weight: 500;
    display: flex;
    margin-bottom: 20px;
    margin-left: 5px;
  }

  .home {
    width: 16px;
    height: 16px;
    /* background-color: thistle; */
    background-image: url(../../assets/home.png);
    background-size: 100%;
    margin-right: 10px;
  }

  .current {
    color: #666666;
    margin-right: 10px;
  }

  .navigation div:last-child {
    color: #2f69d2;
  }

  .table {
    /* width: 1480px; */
    border: 1px solid rgba(204, 204, 204, 0.3);
    /* margin-top: 10px; */
    border-spacing: 0;
    margin-bottom: 28px;
    /*去掉单元格间隙*/
  }

  .table th {
    font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
    font-weight: 500;
    text-align: center;
    color: #1a1a1a;
    border-bottom: 1px solid rgba(204, 204, 204, 0.3);
  }

  .table td {
    border-right: 1px solid rgba(204, 204, 204, 0.3);
    font-size: 14px;
    text-align: center;
    border-bottom: 1px solid rgba(204, 204, 204, 0.3);
  }

  .table .el-input__inner{
    border-radius: 20px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .goodsImage {
    width: 68px;
    height: 68px;
    margin: 0 auto;
    background-color: thistle;
  }

  .goodsImage img {
    width: 68px;
    height: 68px;
  }

  .settlement {
    width: 1200px;
    height: 62px;
    background: #ffffff;

    display: flex;
  }

  .settlementL {
    width: 1020px;
    height: 20px;
    padding: 20px;
    display: flex;
    border: 1px solid #cccccc;
    border-right: 0px;
  }

  .settlementbtn {
    width: 140px;
    height: 62px;
    background-color: #ff0000;
    font-size: 18px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    line-height: 62px;
    margin-left: auto;
  }

  .allcheck {
    width: 20px;
    height: 20px;
    margin-right: 11px;
  }

  .allcheck img {
    width: 20px;
    height: 20px;
  }

  .alltext {
    font-size: 18px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    font-weight: 600;
    /* text-align: left; */
    color: #1a1a1a;
    line-height: 20px;
    margin-right: 36px;
  }

  .delete {
    font-size: 18px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    font-weight: 600;
    /* text-align: left; */
    color: red;
    line-height: 20px;
    margin-right: 36px;
  }

  .allnum {
    margin-left: auto;
    display: flex;
  }

  .checknum {
    height: 100%;
    font-size: 16px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    font-weight: 500;
    /* text-align: right; */
    color: #1a1a1a;
    margin-right: 10px;
  }

  .checktotal {
    height: 100%;
    font-size: 16px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    font-weight: 500;
    /* text-align: right; */
    color: #1a1a1a;
  }

  .checktotal span:first-child {
    display: inline-block;
    /* margin-left: 10px; */
    color: red;
  }

  .checktotal span:last-child {
    font-size: 14px;
    color: #999999;
  }

  /* ====================删除弹框==================== */
  .el-dialog {
    background-color: transparent;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }

  .delbox {
    width: 300px;
    height: 130px;
    background: #ffffff;
    border-radius: 10px;
    padding-top: 30px;
    margin: 100px auto;
  }

  .deltext {
    width: 100%;
    font-size: 16px;
    font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
    font-weight: bold;
    text-align: center;
    color: #1a1a1a;
    margin-bottom: 62px;
  }

  .delbtn {
    width: 300px;
    height: 48px;
  }

  .delbtn span {
    display: inline-block;
    width: 50%;
    height: 100%;
    font-size: 16px;
    text-align: center;
    line-height: 48px;
  }

  .delbtn span:first-child {
    color: #999999;
    border-radius: 0px 0px 0px 10px;
    background-color: #f2f2f2;
  }

  .delbtn span:last-child {
    color: white;
    background: #2f69d2;
    border-radius: 0px 0px 10px 0px;
  }

  .table .el-input-number__decrease{
    border-radius: 20px 0px 0px 20px;
  }

  .table  .el-input-number__increase{
    border-radius: 0px 20px 20px 0px;
  }
</style>