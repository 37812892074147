<!-- 订单详情 -->
<template>
    <div class="waiting">
        <!-- 面包屑 -->
        <div class="navigation">
            <div class="home"></div>
            <div class="current">当前页面:</div>
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item to="/mine">个人中心</el-breadcrumb-item>
                <el-breadcrumb-item to='/myorder'>我的订单</el-breadcrumb-item>
                <el-breadcrumb-item>{{type}}详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="waitingorder">
            <div class="waitingl">
                <div>{{type}}</div>
                <div>您的订单{{type}}</div>
            </div>
            <div class="waitingr">
                <img src="../../assets/ordercar.png" alt="">
            </div>
        </div>

        <div class="order-address">
            <div class="ordername"><span>{{orderAddress.receiver}}</span><span>{{orderAddress.receiverPhone}}</span>
            </div>
            <div class="oaddress">{{orderAddress.areaName}}{{orderAddress.addressDetail}}</div>
        </div>

        <div class="orderNo">
            <p>订单编号：<span>{{orderDetails.orderNo}}</span></p>
            <p>下单时间：<span>{{orderDetails.createTime}}</span></p>
            <p v-show="type == '待收货'|| type == '已完成' || type == '待发货'|| type == '待评价'">
                付款时间：<span>{{orderDetails.payTime}}</span></p>
            <p v-show="type == '待收货'">发货地区：<span>{{orderDetails.sendAddress}}</span></p>
            <p v-show="orderDetails.refundStatus == 4">退款编号：<span>2020.09.22 17:33</span></p>
            <p v-show="orderDetails.refundStatus == 4">申请时间：<span>2020.09.22 17:33</span></p>
        </div>

        <!-- <div class="orderbill">
            <p>发票类型：<span>增值税电子普通发票</span></p>
            <p>发票抬头：<span>武汉梦飞翔科技有限公司</span></p>
        </div> -->

        <div v-show="type == '待收货'|| type == '已完成' || type == '待评价' " class="log">
            <div class="logl">
                <p>物流公司：<span>{{orderDetails.logisticsComName}}</span></p>
                <p>物流单号：<span>{{orderDetails.logisticsNo}}</span></p>
            </div>
            <div class="logbtn" @click="getlogistics">查看物流</div>
        </div>

        <div class="otitl">订单信息</div>
        <div class="oList">
            <table class="otable">
                <tr style="height: 48px;
                                background-color: #f6f6f6;
                                font-size: 16px;">
                    <th style="width:140px;">订单类型</th>
                    <th style="width: 130px;">图片</th>
                    <th style="width: 315px;">商品名称</th>
                    <th style="width: 140px;">单价</th>
                    <th style="width: 140px;">数量</th>
                    <th style="width: 140px;">总价</th>
                    <!-- <th style="width: 120px;" v-show="orderDetails.status == '待发货'">退款金额</th> -->
                    <th style="width: 145px;">状态</th>
                    <th style="width: 140px;">操作</th>
                </tr>

                <tr style="height: 80px;" v-for='(item,i) in orderGoodsList' :key="item.oid">
                    <td v-show="orderDetails.orderType == 1 ||  orderDetails.orderType == 4">普通</td>
                    <td v-show="orderDetails.orderType == 2">拼团</td>
                    <td>
                        <div style="width: 70px; height: 70px; background-color: thistle; margin-left: 20px;">
                            <img :src="item.coverImg" alt="" style="width: 100%; height: 100%;">
                        </div>
                    </td>
                    <td>{{item.goodsName}}</td>
                    <td style="color: red;">{{item.price}}</td>
                    <td>{{item.num}}</td>
                    <td style="color: red;">{{item.total}}</td>
                    <!-- <td style="color: #2f69d2" v-show="orderDetails.status == '待发货'"></td> -->
                    <td>
                        <div style="color: #2f69d2">{{ type }}</div>
                        <p v-show='item.refundStatus == 1' @click="getrefundId(item.refundId),shouhou=true">申请中</p>
                        <p v-show="item.refundStatus == 2 && type !== '待发货'" @click="getrefundId(item.refundId)">退货中</p>
                        <p v-show='item.refundStatus == 3' @click="getrefundId(item.refundId),shouhous=true">退款完成</p>
                        <p v-show='item.refundStatus == 4' @click="getrefundId(item.refundId),shouhout=true">退款失败</p>
                    </td>
                    <!-- <td v-show="type == '待评价'" :rowspan='orderGoodsList.length'><p style="color:  #2f69d2;">去评价</p></td> -->
                    <td v-show="type != '待支付' &&  type != '待评价'">
                        <div v-show="type == '待成团'"></div>

                        <div v-show="type == '待发货'">
                            <p v-if="item.refundStatus == 0  || item.refundStatus == 4" @click="getshouh(item.orderGoodsId,orderDetails.isEditPrice)">售后退款</p>
                        </div>
                        <div v-show="type == '待收货'">
                            <p style="color:  #2f69d2;" @click="receiving=true">确认收货</p>
                            <div v-if="item.refundStatus == 0  || item.refundStatus == 4" @click="getshouh(item.orderGoodsId,orderDetails.isEditPrice)">售后退款</div>
                        </div>
                        <div v-show="type == '已完成'">
                            <p @click='delorder = true'>删除订单</p>
                        </div>
                        <div v-show="type == '已取消'">
                            <p @click='delorder = true'>删除订单</p>
                        </div>

                    </td>
                    <td :rowspan="orderGoodsList.length" v-show="(type == '待支付' || type == '待评价') && i==0">
                        <div v-show="type == '待支付'">
                            <p style="color:  #2f69d2;" @click="pay(orderDetails.payAmount,orderDetails.changePrice)">
                                立即支付</p>
                            <p @click="cancel = true">取消订单</p>
                        </div>
                        <div v-show="type == '待评价'">
                            <p style="color:  #2f69d2;" @click="pinglun=true">去评价</p>
                        </div>
                    </td>
                </tr>
            </table>
            <div class="osum">
                <ul>
                    <li>合计：<span>{{orderDetails.orderAmount}}</span>元</li>
                    <li>积分抵扣：-<span>{{orderDetails.pointAmount}}</span>元</li>
                    <li>优惠券：-<span>{{orderDetails.couponAmount}}</span>元</li>
                    <li>运费：<span>{{orderDetails.orderFreight}}</span>元</li>
                    <li>实付款：<span>{{orderDetails.payAmount}}</span>元
                    <li v-if='orderDetails.isEditPrice==1'>改价金额：<span>{{orderDetails.changePrice}}</span>元
                    </li>
                </ul>
            </div>
        </div>
        <!-- 订单删除 -->
        <el-dialog :visible.sync="delorder" class="del">
            <div class="delbox">
                <div class="deltext">确定要删除吗？</div>
                <div class="delbtn">
                    <span @click="delorder = false">取消</span>
                    <span @click="getdelete">删除</span>
                </div>
            </div>
        </el-dialog>

        <!-- 订单取消 -->
        <el-dialog :visible.sync="cancel" class="del">
            <div class="delbox">
                <div class="deltext">确定要取消吗？</div>
                <div class="delbtn">
                    <span @click="cancel = false">取消</span>
                    <span @click="(cancel = false), getcancel()">确定</span>
                </div>
            </div>
        </el-dialog>

        <!-- 确认收货 -->
        <el-dialog :visible.sync="receiving" class="del">
            <div class="delbox">
                <div class="deltext">确定收货？</div>
                <div class="delbtn">
                    <span @click="receiving = false">取消</span>
                    <span @click="(receiving = false), getconfirm()">确定</span>
                </div>
            </div>
        </el-dialog>


        <!-- 售后退款 -->
        <el-dialog :visible.sync="refund" class="refundbox">
            <div class="refund" style="width:370px">
                <div class="refundclose" @click="refund = false"></div>
                <div class="reject-title">售后退款</div>
                <div class="reject-line"></div>
                <textarea name="" id="" cols="50" rows="7" v-model="refund_reason" placeholder="请输入退款原因"
                    style="margin-bottom: 5px"></textarea>
                <div class="img_box">
                    <div class="img_size" v-for="(item, index) of imgurl" :key="index" v-show="imgurl.length != 0">
                        <img v-if="imgurl.length != 0" :src="item.src" />
                        <div class="remove_logo" @click="fileDel">×</div>
                    </div>
                    <el-upload action="" list-type="picture-card" :on-preview="handlePictureCardPreview"
                        :on-remove="handleRemove" accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG" :http-request="uploadImgs"
                        :show-file-list="false">
                        <i class="el-icon-camera-solid"></i>
                    </el-upload>
                </div>
                <div class="refundbtn" @click=" applyRefund(),refund = false">提交</div>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="pinglun" class="refundbox">
            <div class="refund" style="width: 365px;height: 320px;">
                <div class="refundclose" @click="pinglun = false"></div>
                <div class="reject-title">评价</div>
                <div class="reject-line"></div>
                <textarea name="" id="" cols="50" rows="7" v-model="content" placeholder="请输入商品评价"
                    style="margin-bottom: 5px"></textarea>
                <div class="img_box">
                    <div class="img_size" v-for="(item, index) of imgurl" :key="index" v-show="imgurl.length != 0">
                        <img v-if="imgurl.length != 0" :src="item.src" />
                        <div class="remove_logo" @click="fileDel">×</div>
                    </div>
                    <el-upload action="" list-type="picture-card" :on-preview="handlePictureCardPreview"
                        :on-remove="handleRemove" accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG" :http-request="uploadImgs"
                        :show-file-list="false">
                        <i class="el-icon-camera-solid"></i>
                    </el-upload>
                </div>
                <div class="refundbtn" @click=" addEvaluation()">提交</div>
            </div>
        </el-dialog>

        <!-- 售后中 -->
        <el-dialog :visible.sync="shouhou" class="refundbox">
            <div class="refund" style="width: 600px;">
                <div class="refundclose" @click="shouhou = false"></div>
                <div class="reject-title">查看售后</div>
                <div class="reject-line" style="margin-bottom: 37px;"></div>

                <p style="font-size: 20px;
              font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
              font-weight: bold;
              text-align: left;
              color: #2f69d2;margin-bottom: 24px;">退款审核中</p>

                <div
                    style="width: 100%; height: 14px; background-image: url(../../assets/division.png); background-size: 100%;margin-bottom: 20px;">
                </div>

                <div style="width: 560px; padding: 20px; background: #f6f6f6;
              border-radius: 4px; font-size: 14px;
              font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
              font-weight: 400;
              text-align: left;
              color: #666666;">{{refundReason}}</div>
            </div>
        </el-dialog>

        <!-- 审核成功 -->
        <el-dialog :visible.sync="shouhous" class="refundbox">
            <div class="refund" style="width: 600px;">
                <div class="refundclose" @click="shouhous = false"></div>
                <div class="reject-title">查看售后</div>
                <div class="reject-line" style="margin-bottom: 37px;"></div>

                <p style="font-size: 20px;
              font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
              font-weight: bold;
              text-align: left;
              color: #2f69d2;margin-bottom: 24px;">退款成功</p>

                <div style="width: 100%; height: 14px; margin-bottom: 20px;">
                    <img src="../../assets/division.png" alt="" style="width: 100%; height: 100%;">
                </div>

                <div style="width: 560px; padding: 20px; background: #f6f6f6;
              border-radius: 4px; font-size: 14px;
              font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
              font-weight: 400;
              text-align: left;
              color: #666666;">{{refundReason}}</div>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="shouhout" class="refundbox">
            <div class="refund" style="width: 600px;">
                <div class="refundclose" @click="shouhout = false"></div>
                <div class="reject-title">查看售后</div>
                <div class="reject-line" style="margin-bottom: 37px;"></div>

                <p style="font-size: 20px;
              font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
              font-weight: bold;
              text-align: left;
              color: #fe0000;margin-bottom: 24px;">退款失败</p>

                <div style="width: 100%; font-size: 14px;
              font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
              font-weight: 400;
              text-align: left;
              margin-bottom: 24px;
              color: #666666;">{{rejectReason}}</div>

                <div style="width: 100%; height: 14px; margin-bottom: 20px;">
                    <img src="../../assets/division.png" alt="" style="width: 100%; height: 100%;">
                </div>

                <div style="width: 560px; padding: 20px; background: #f6f6f6;
              border-radius: 4px; font-size: 14px;
              font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
              font-weight: 400;
              text-align: left;
              color: #666666;">{{refundReason}}</div>

            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                orderDetails: {},
                orderGoodsList: [],
                orderAddress: [],
                type: '',
                oid: this.$route.params.oid,
                // 删除订单
                delorder: false,

                // 取消订单
                cancel: false,

                // 确认收货
                receiving: false,

                // 售后退款
                refund: false,

                pinglun: false,

                dialogImageUrl: "",
                dialogVisible: false,
                posterURL: "",
                fileLists: [],
                imgurl: [],
                filename: '',
                imageUrl: '',
                orderGoodsId: '',
                refund_reason: '',

                content: '',
                refundId: '',

                shouhou: false,
                shouhous: false,
                shouhout: false,

                // oid:'',

                refundReason: '',//申请原因
                rejectReason: '',//反馈

                logisticsUrl: '' // 物流跳转
            }
        },
        methods: {
            getOrderDetails() {
                this.$api.post({
                    path: '/myorder/getOrderDetails',
                    params: {
                        oid: this.oid
                        // oid: 468
                    },
                    success: (data) => {
                        console.log(data, 'data1');
                        this.orderDetails = data.data
                        this.orderGoodsList = data.data.orderGoodsList
                        this.orderAddress = data.data.orderAddress
                        this.type = data.data.status
                        console.log(data.data.status, data.data, 'data2');
                        this.logisticsUrl = data.data.logisticsUrl
                        // console.log(this.status1, '状态')

                    }
                })
            },
            getlogistics() {
                window.location.href = this.logisticsUrl
            },
            // 删除订单
            getdelete() {
                this.$api.post({
                    path: "/myorder/operationOrder",
                    params: {
                        type: 2,
                        oid: this.oid,
                    },
                    success: (data) => {
                        console.log(data);
                        this.delorder = false;
                        this.getOrderDetails();
                    },
                });
            },

            // 取消订单
            getcancel() {
                this.$api.post({
                    path: "/myorder/operationOrder",
                    params: {
                        type: 3,
                        oid: this.oid,
                    },
                    success: (data) => {
                        console.log(data);
                        this.cancel = false;
                        this.getOrderDetails();
                    },
                });
                // this.getOrderDetails()
            },

            // 确认收货
            getconfirm() {
                this.$api.post({
                    path: "/myorder/operationOrder",
                    params: {
                        type: 1,
                        oid: this.oid,
                    },
                    success: (data) => {
                        console.log(data);
                        this.receiving = false;
                        this.getOrderDetails();
                    },
                });
            },

            // 删除图片
            handleRemove(file, fileList) {
                this.form.logo = ''
                console.log(file, fileList)
            },
            //查看大图
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.imgDialogVisible = true;
                console.log(file)
            },

            fileDel(index) {
                this.imgurl.splice(index, 1)
            },
            // 上传展示图
            uploadImgs(file) {
                if (this.imgurl.length <= 9) {
                    console.log(file.file, 'file')
                    this.$uploadFiles('http://47.111.70.233:8762/mfxapi/multifileupload', file.file).then(res => {
                        res.data.forEach((e) => {
                            this.imgurl.push({
                                src: e.url
                            })
                        })

                        console.log(this.imgurl)
                        console.log(res.data, 'res2')
                        this.fileLists.push({
                            path: res.data.url,
                        })
                    })
                } else {
                    this.$message.error("不能超过九张哦！")
                }
            },
            getorderGoodsId(v) {
                this.orderGoodsId = v
            },
            pay(payAmount, changePrice) {
                if (this.orderDetails.isEditPrice == 0) {
                    this.$router.push(`/payment/${this.oid}/${payAmount}`);
                } else {
                    this.$router.push(`/payment/${this.oid}/${changePrice}`);
                }

            },
            // 售后退款
            getshouh(v, isEditPrice) {
                if (isEditPrice == 1) {
                    console.log(isEditPrice);
                    this.$message({
                        message: '改价订单不允许退款',
                        type: 'warning'
                    });
                } else {
                    this.refund = true
                    // this.getdelorder(v)
                    this.getorderGoodsId(v)
                }

            },
            applyRefund() {
                this.$api.post({
                    path: '/myorder/applyRefund',
                    params: {
                        oid: this.oid,
                        order_goods_ids: this.orderGoodsId,
                        refund_reason: this.refund_reason,
                        orderRefundImgList: this.imgurl
                    },
                    success: (data) => {
                        console.log(data)
                        this.getOrderDetails()
                    }
                })
            },

            addEvaluation() {
                this.$api.post({
                    path: '/myorder/addEvaluation',
                    params: {
                        oid: this.oid,
                        content: this.content,
                        lstEvalImg: this.imgurl,
                        uid: this.uid
                    },
                    success: (data) => {
                        console.log(data)
                        
                        if (data.code == 1) {
                            this.$message({
                                message: data.msg,
                                type: 'success'
                            });
                            this.pinglun = false
                            this.getOrderList()
                            this.content = '',
                                this.imgurl = []
                        } else {
                            this.$message({
                                message: data.msg,
                                type: 'warning'
                            });
                        }
                    }
                })
            },

            getrefundId(v) {
                this.refundId = v

                this.getRefundDetails()
            },

            getRefundDetails() {
                this.$api.post({
                    path: '/myorder/getRefundDetails',
                    params: {
                        refundId: this.refundId
                    }, success: (data) => {
                        console.log(data)
                        this.refundReason = data.data.refundReason
                        this.rejectReason = data.data.rejectReason
                    }
                })
            }
        },

        mounted() {
            // this.getorder()
            this.getOrderDetails()

        },
    }
</script>


<style scoped>
    .waiting {
        width: 1200px;
        margin: 130px auto;
        margin-bottom: 20px;
    }

    /* 导航栏/面包屑 */
    .navigation {
        width: 100%;
        height: 20px;
        font-size: 14px;
        font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
        font-weight: 500;
        display: flex;
        margin-bottom: 17px;
        margin-left: 5px;
    }

    .home {
        width: 16px;
        height: 16px;
        /* background-color: thistle; */
        background-image: url(../../assets/home.png);
        background-size: 100%;
        margin-right: 10px;
    }

    .current {

        color: #666666;
        margin-right: 10px;
    }

    .navigation div:last-child {
        color: #2F69D2;
    }

    .el-breadcrumb {
        line-height: 20px;
    }

    .el-breadcrumb-item:last-child {
        color: #2F69D2;
    }

    /* =================待成团========================== */
    .waitingorder {
        width: 560px;
        height: 72px;
        padding: 14px 20px;
        background-image: url(../../assets/orderbag.png);
        background-size: 100%;
        display: flex;
        margin-bottom: 10px;
    }

    .waitingl {
        width: 50%;
        height: 100%;
    }

    .waitingl div {
        font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
        /* font-weight: bold; */
        text-align: left;
        color: #ffffff;
    }

    .waitingl div:first-child {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .waitingl div:last-child {
        font-size: 14px;
        font-weight: Normal;
        text-align: left;
        color: #eeeeee;
    }

    .waitingr {
        width: 106px;
        height: 100%;
        margin-left: auto;
    }

    .waitingr img {
        width: 100%;
        height: 100%;
    }

    .order-address {
        width: 556px;
        height: 56px;
        padding: 20px 20px;
        background: #f6f6f6;
        border: 2px dashed #cccccc;
        border-radius: 4px;
        margin-bottom: 10px;
    }

    .ordername {
        width: 50%;
        font-size: 16px;
        font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
        font-weight: 600;
        text-align: left;
        color: #1a1a1a;
        margin-bottom: 17px;
    }

    .ordername span {
        display: inline-block;
        margin-right: 10px;
    }

    .oaddress {
        width: 100%;
        font-size: 14px;
        font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
        font-weight: Normal;
        text-align: left;
        color: #1a1a1a;
    }

    .orderNo {
        width: 560px;
        /* height: 50px; */
        padding: 20px;
        background: #f6f6f6;
        border-radius: 4px;
        font-size: 14px;
        font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
        font-weight: 400;
        text-align: left;
        color: #666666;
        margin-bottom: 10px;
    }

    .orderNo p {
        margin-bottom: 17px;
    }

    .orderNo p:last-child {
        margin-bottom: 0px;
    }

    .orderbill {
        width: 560px;
        height: 50px;
        padding: 20px;
        background: #f6f6f6;
        border-radius: 4px;
        font-size: 14px;
        font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
        font-weight: 400;
        text-align: left;
        color: #666666;
        margin-bottom: 30px;
    }

    .orderbill p:first-child {
        margin-bottom: 17px;
    }

    .otitl {
        width: 80px;
        height: 20px;
        padding-left: 12px;
        border-left: 8px solid #2f69d2;
        font-size: 20px;
        font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
        font-weight: bold;
        text-align: left;
        color: #1a1a1a;
        line-height: 20px;
        margin-bottom: 13px;

    }

    /* ========================订单列表======================== */
    .oList {
        width: 1200px;
    }

    .otable {
        width: 1200px;
    }

    .otable {
        /* width: 1480px; */
        border: 1px solid rgba(204, 204, 204, 0.3);
        /* margin-top: 10px; */
        border-spacing: 0;
        margin-bottom: 28px;
        /*去掉单元格间隙*/
        margin-bottom: 10px;
    }

    .otable th {
        font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
        font-weight: 500;
        text-align: center;
        color: #1a1a1a;
        border-bottom: 1px solid rgba(204, 204, 204, 0.3);
    }

    .otable td {
        border-right: 1px solid rgba(204, 204, 204, 0.3);
        border-bottom: 1px solid rgba(204, 204, 204, 0.3);
        font-size: 14px;
        text-align: center;
    }

    .otable tr:last-child td {
        border-bottom: 0px
    }

    /* 订单合计 */
    .osum {
        width: 1160px;
        height: 60px;
        padding: 0px 20px;
        background: #f6f6f6;
        border: 1px solid #dddddd;
    }

    .osum ul {
        width: 100%;
        height: 100%;
    }

    .osum li {
        list-style: none;
        float: left;
        height: 100%;
        font-size: 14px;
        font-family: Source Han Sans CN Regular, Source Han Sans CN Regular-Regular;
        font-weight: 400;
        text-align: left;
        color: #1a1a1a;
        line-height: 60px;
        margin-right: 100px;
    }

    .osum li:nth-child(4) {
        margin-right: 220px;
    }

    .osum li:nth-child(6) {
        float: right;
    }

    .osum li:nth-child(5) {
        margin-right: 10px;
    }

    .osum li:nth-child(5) span {
        font-size: 18px;
        color: #FF0000;
        font-weight: bold;
    }

    .img_box {
        width: 100%;
        padding: 0 2%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
    }

    .img_size {
        position: relative;
        width: 64px;
        height: 64px;
        padding: 5px;
        margin-right: 10px;
    }

    .img_size img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }

    .remove_logo {
        position: absolute;
        width: 15px;
        height: 15px;
        background: red;
        border-radius: 25px;
        top: 5px;
        right: 5px;
        text-align: center;
        line-height: 15px;
        font-size: 12px;
        color: white;
    }

    .add_img {
        width: 64px;
        height: 64px;
        margin-top: 5px;
        text-align: center;
        line-height: 64px;
        font-size: 30px;
        color: #777777;
        border: 1px solid #cccccc;
        border-radius: 5px;
    }
</style>