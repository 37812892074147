<template>
  <div class="Pbox">
    <div class="navigation">
      <div class="home"></div>
      <div class="current">当前位置:</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/pmessage">个人中心</el-breadcrumb-item>
        <el-breadcrumb-item>我的订单</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="pmain">
      <div class="pmainL">
        <ul>
          <router-link to="pmessage" tag="li">个人信息</router-link>
          <!-- <router-link to="myorder" tag="li">我的订单</router-link> -->
          <router-link
            to="myorder"
            class="liactive"
            style="color: white"
            tag="li"
            >我的订单</router-link
          >
          <router-link to="advanceorder" tag="li">预付订单</router-link>
          <router-link to="mycollection" tag="li">我的收藏</router-link>
          <router-link to="myaddress" tag="li">我的地址</router-link>
          <router-link to="platform" tag="li">平台介绍</router-link>
          <router-link to="install" tag="li">设置</router-link>
        </ul>
      </div>
      <div class="myorderbox">
        <div class="myorder">
          <div class="order-type">
            <div
              :class="['order-type-item', order.status == '' ? 'active' : '']"
              @click="getType('')"
            >
              全部
            </div>
            <div
              :class="[
                'order-type-item',
                order.status == '待支付' ? 'active' : '',
              ]"
              @click="getType('待支付')"
            >
              待支付
            </div>
            <div
              :class="[
                'order-type-item',
                order.status == '待成团' ? 'active' : '',
              ]"
              @click="getType('待成团')"
            >
              待成团
            </div>
            <div
              :class="[
                'order-type-item',
                order.status == '待发货' ? 'active' : '',
              ]"
              @click="getType('待发货')"
            >
              待发货
            </div>
            <div
              :class="[
                'order-type-item',
                order.status == '待收货' ? 'active' : '',
              ]"
              @click="getType('待收货')"
            >
              待收货
            </div>
            <div
              :class="[
                'order-type-item',
                order.status == '待评价' ? 'active' : '',
              ]"
              @click="getType('待评价')"
            >
              待评价
            </div>
            <div
              :class="[
                'order-type-item',
                order.status == '已完成' ? 'active' : '',
              ]"
              @click="getType('已完成')"
            >
              已完成
            </div>
            <div
              :class="[
                'order-type-item',
                order.status == '已取消' ? 'active' : '',
              ]"
              @click="getType('已取消')"
            >
              已取消
            </div>
          </div>
          <table class="orderList">
            <tr
              style="height: 48px; background-color: #f6f6f6; font-size: 16px"
            >
              <th style="width: 134px">订单编号</th>
              <th style="width: 98px">订单类型</th>
              <th style="width: 285px">商品信息</th>
              <th style="width: 90px">运费</th>

              <th style="width: 90px">总价</th>
              <th style="width: 90px">改价金额</th>
              <th style="width: 90px">状态</th>
              <th style="width: 90px">操作</th>
            </tr>
            <tr>
              <td
                colspan="8"
                v-if="orderList.length == 0"
                style="border-right: 1px solid #ccc; height: 40px"
              >
                {{ noData }}
              </td>
            </tr>
            <tr style="height: 80px" v-for="item in orderList" :key="item.oid">
              <td>{{ item.orderNo }}</td>
              <!-- 拼团商品直接购买也是普通商品 -->
              <td v-if="item.orderType == 1 || item.orderType == 4">普通</td>
              <td v-if="item.orderType == 2">拼团</td>
              <td style="display: flex; padding-left: 20px">
                <div class="goodslogo">
                  <div v-for="(it, index) in item.orderGoodsList" :key="index">
                    <img :src="it.coverImg" alt="" />
                  </div>
                </div>
                <div class="goodsnums" style="height: 100%; line-height: 80px">
                  共{{ item.orderGoodsList.length }}件
                </div>
              </td>
              <td>{{ item.orderFreight }}</td>

              <td>{{ item.payAmount }}</td>
              <td>{{ item.changePrice }}</td>
              <td>
                <div style="color: #2f69d2">{{ item.status }}</div>
                <p
                  v-show="item.refundStatus == 1"
                  @click="getrefundId(item.refundId), (shouhou = true)"
                >
                  申请中
                </p>
                <p
                  v-show="item.refundStatus == 2 && item.status !== '待发货'"
                  @click="getrefundId(item.refundId)"
                >
                  退货中
                </p>
                <p
                  v-show="item.refundStatus == 3"
                  @click="getrefundId(item.refundId), (shouhous = true)"
                >
                  退款完成
                </p>
                <p
                  v-show="item.refundStatus == 4"
                  @click="getrefundId(item.refundId), (shouhout = true)"
                >
                  退款失败
                </p>
              </td>

              <td>
                <div
                  v-if="item.status == '待成团'"
                  @click="getOrderid(item.oid)"
                >
                  查看详情
                </div>
                <div v-if="item.status == '待支付'">
                  <p
                    style="color: #2f69d2"
                    @click="
                      pay(
                        item.oid,
                        item.payAmount,
                        item.isEditPrice,
                        item.changePrice
                      )
                    "
                  >
                    立即支付
                  </p>
                  <p @click="(cancel = true), getdelorder(item.oid)">
                    取消订单
                  </p>
                  <div @click="getOrderid(item.oid)">查看详情</div>
                </div>
                <div v-if="item.status == '待发货'">
                  <!-- v-if="item.refundStatus == 0 || item.refundStatus == 4" -->
                  <p
                    v-if="item.refundStatus == 0 || item.refundStatus == 4"
                    @click="getshouh(item.oid, item.isEditPrice)"
                  >
                    售后退款
                  </p>
                  <div @click="getOrderid(item.oid)">查看详情</div>
                </div>
                <div v-if="item.status == '待收货'">
                  <p
                    style="color: #2f69d2"
                    @click="getdelorder(item.oid), (receiving = true)"
                  >
                    确认收货
                  </p>
                  <div
                    v-if="item.refundStatus == 0 || item.refundStatus == 4"
                    @click="getshouh(item.oid, item.isEditPrice)"
                  >
                    售后退款
                  </div>
                  <p @click="getOrderid(item.oid)">查看详情</p>
                </div>
                <div v-if="item.status == '待评价'">
                  <p
                    style="color: #2f69d2"
                    @click="getdelorder(item.oid), (pinglun = true)"
                  >
                    去评价
                  </p>
                  <p @click="getOrderid(item.oid)">查看详情</p>
                </div>
                <div v-if="item.status == '已完成'">
                  <p @click="getdelorder(item.oid), (delorder = true)">
                    删除订单
                  </p>
                  <p @click="getOrderid(item.oid)">查看详情</p>
                </div>
                <div v-if="item.status == '已取消'">
                  <p @click="getdelorder(item.oid), (delorder = true)">
                    删除订单
                  </p>
                  <p @click="getOrderid(item.oid)">查看详情</p>
                </div>
                <div v-if="item.status == '已关闭'">
                  <p @click="getdelorder(item.oid), (delorder = true)">
                    删除订单
                  </p>
                  <p @click="getOrderid(item.oid)">查看详情</p>
                </div>
              </td>
            </tr>
          </table>
          <div class="orderpage" style="width: 100%">
            <div style="margin: 0 auto; display: flex">
              <div class="home-orderpage" @click="goFirst">首页</div>
              <el-pagination
                background
                layout="prev, pager, next"
                @current-change="handlePageChange"
                @size-change="handleSizeChange"
                :current-page.sync="list.pageNum"
                :page-size="10"
                :total="pagetotal"
              >
              </el-pagination>
              <div class="last-orderpage" @click="goLast">尾页</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 订单删除 -->
    <el-dialog :visible.sync="delorder" class="del">
      <div class="delbox">
        <div class="deltext">确定要删除吗？</div>
        <div class="delbtn">
          <span @click="delorder = false">取消</span>
          <span @click="getdelete">删除</span>
        </div>
      </div>
    </el-dialog>

    <!-- 订单取消 -->
    <el-dialog :visible.sync="cancel" class="del">
      <div class="delbox">
        <div class="deltext">确定要取消吗？</div>
        <div class="delbtn">
          <span @click="cancel = false">取消</span>
          <span @click="(cancel = false), getcancel()">确定</span>
        </div>
      </div>
    </el-dialog>

    <!-- 确认收货 -->
    <el-dialog :visible.sync="receiving" class="del">
      <div class="delbox">
        <div class="deltext">确定收货？</div>
        <div class="delbtn">
          <span @click="receiving = false">取消</span>
          <span @click="(receiving = false), getconfirm()">确定</span>
        </div>
      </div>
    </el-dialog>

    <!-- 售后退款 -->
    <el-dialog
      :visible.sync="refund"
      v-loading="refundLoading"
      class="refundbox"
    >
      <div class="refund">
        <div class="refundclose" @click="refund = false"></div>
        <div class="reject-title">售后退款</div>
        <div class="reject-line"></div>
        <table class="ttable">
          <tr style="height: 70px; background-color: #f6f6f6; font-size: 16px">
            <th style="width: 150px">图片</th>
            <th style="width: 150px">商品名称</th>
            <th style="width: 120px">单价</th>
            <th style="width: 120px">数量</th>
          </tr>
          <tr
            style="height: 80px"
            v-for="item in orderGoodsList"
            :key="item.oid"
          >
            <td>
              <div
                style="
                  width: 70px;
                  height: 70px;
                  background-color: thistle;
                  margin: 0 auto;
                  box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.3);
                "
              >
                <img
                  :src="item.coverImg"
                  alt=""
                  style="width: 100%; height: 100%"
                />
              </div>
            </td>
            <td>{{ item.goodsName }}</td>
            <td style="color: red">{{ item.price }}</td>
            <td>{{ item.num }}</td>
          </tr>
        </table>
        <textarea
          name=""
          id=""
          cols="74"
          rows="7"
          v-model="refund_reason"
          placeholder="请输入退款原因"
          style="margin-bottom: 5px"
        ></textarea>
        <div class="img_box">
          <div
            class="img_size"
            v-for="(item, index) of imgurl"
            :key="index"
            v-show="imgurl.length != 0"
          >
            <img v-if="imgurl.length != 0" :src="item.src" />
            <div class="remove_logo" @click="fileDel">×</div>
          </div>
          <el-upload
            action=""
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG"
            :http-request="uploadImgs"
            :show-file-list="false"
          >
            <i class="el-icon-camera-solid"></i>
          </el-upload>
        </div>
        <div class="refundbtn" @click="applyRefund(), (refund = false)">
          提交
        </div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="pinglun" class="refundbox">
      <div class="refund" style="width: 365px; height: 320px">
        <div class="refundclose" @click="pinglun = false"></div>
        <div class="reject-title">评价</div>
        <div class="reject-line"></div>
        <textarea
          name=""
          id=""
          cols="50"
          rows="7"
          v-model="content"
          placeholder="请输入商品评价"
          style="margin-bottom: 5px"
        ></textarea>
        <div class="img_box">
          <div
            class="img_size"
            v-for="(item, index) of imgurl"
            :key="index"
            v-show="imgurl.length != 0"
          >
            <img v-if="imgurl.length != 0" :src="item.src" />
            <div class="remove_logo" @click="fileDel">×</div>
          </div>
          <el-upload
            action=""
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG"
            :http-request="uploadImgs"
            :show-file-list="false"
          >
            <i class="el-icon-camera-solid"></i>
          </el-upload>
        </div>
        <div class="refundbtn" @click="addEvaluation()">提交</div>
      </div>
    </el-dialog>

    <!-- 售后中 -->
    <el-dialog :visible.sync="shouhou" class="refundbox">
      <div class="refund" style="width: 600px">
        <div class="refundclose" @click="shouhou = false"></div>
        <div class="reject-title">查看售后</div>
        <div class="reject-line" style="margin-bottom: 37px"></div>

        <p
          style="
            font-size: 20px;
            font-family: Source Han Sans CN Normal,
              Source Han Sans CN Normal-Normal;
            font-weight: bold;
            text-align: left;
            color: #2f69d2;
            margin-bottom: 24px;
          "
        >
          退款审核中
        </p>

        <div style="width: 100%; height: 14px; margin-bottom: 20px">
          <img
            src="../../assets/division.png"
            alt=""
            style="width: 100%; height: 100%"
          />
        </div>

        <div
          style="
            width: 560px;
            padding: 20px;
            background: #f6f6f6;
            border-radius: 4px;
            font-size: 14px;
            font-family: Source Han Sans CN Regular,
              Source Han Sans CN Regular-Regular;
            font-weight: 400;
            text-align: left;
            color: #666666;
          "
        >
          {{ refundReason }}
        </div>
      </div>
    </el-dialog>

    <!-- 审核成功 -->
    <el-dialog :visible.sync="shouhous" class="refundbox">
      <div class="refund" style="width: 600px">
        <div class="refundclose" @click="shouhous = false"></div>
        <div class="reject-title">查看售后</div>
        <div class="reject-line" style="margin-bottom: 37px"></div>

        <p
          style="
            font-size: 20px;
            font-family: Source Han Sans CN Normal,
              Source Han Sans CN Normal-Normal;
            font-weight: bold;
            text-align: left;
            color: #2f69d2;
            margin-bottom: 24px;
          "
        >
          退款成功
        </p>

        <div style="width: 100%; height: 14px; margin-bottom: 20px">
          <img
            src="../../assets/division.png"
            alt=""
            style="width: 100%; height: 100%"
          />
        </div>

        <div
          style="
            width: 560px;
            padding: 20px;
            background: #f6f6f6;
            border-radius: 4px;
            font-size: 14px;
            font-family: Source Han Sans CN Regular,
              Source Han Sans CN Regular-Regular;
            font-weight: 400;
            text-align: left;
            color: #666666;
          "
        >
          {{ refundReason }}
        </div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="shouhout" class="refundbox">
      <div class="refund" style="width: 600px">
        <div class="refundclose" @click="shouhout = false"></div>
        <div class="reject-title">查看售后</div>
        <div class="reject-line" style="margin-bottom: 37px"></div>

        <p
          style="
            font-size: 20px;
            font-family: Source Han Sans CN Normal,
              Source Han Sans CN Normal-Normal;
            font-weight: bold;
            text-align: left;
            color: #fe0000;
            margin-bottom: 24px;
          "
        >
          退款失败
        </p>

        <div
          style="
            width: 100%;
            font-size: 14px;
            font-family: Source Han Sans CN Regular,
              Source Han Sans CN Regular-Regular;
            font-weight: 400;
            text-align: left;
            margin-bottom: 24px;
            color: #666666;
          "
        >
          {{ rejectReason }}
        </div>

        <div style="width: 100%; height: 14px; margin-bottom: 20px">
          <img
            src="../../assets/division.png"
            alt=""
            style="width: 100%; height: 100%"
          />
        </div>

        <div
          style="
            width: 560px;
            padding: 20px;
            background: #f6f6f6;
            border-radius: 4px;
            font-size: 14px;
            font-family: Source Han Sans CN Regular,
              Source Han Sans CN Regular-Regular;
            font-weight: 400;
            text-align: left;
            color: #666666;
          "
        >
          {{ refundReason }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      uid: localStorage.getItem("uid"),
      nickName: localStorage.getItem("nickName"),
      address: localStorage.getItem("address"),
      avatar: localStorage.getItem("avatar"),

      // 分销推广
      extension: false,

      // 添加新地址
      addition: false,

      // 删除订单
      delorder: false,

      // 取消订单
      cancel: false,

      // 确认收货
      receiving: false,

      // 售后退款
      refund: false,

      pinglun: false,

      order: {
        page: 1,
        pageCount: 12,
        status: "",
      },
      form: {
        desc: "",
      },

      // 我的订单
      orderList: [],
      ordertotal: 0, //订单中个数
      pageCount: 0,
      oid: "",

      atotal: 0,
      noData: "",
      aData: "",

      dialogImageUrl: "",
      dialogVisible: false,
      posterURL: "",
      fileLists: [],
      imgurl: [],
      filename: "",

      imageUrl: "",

      orderGoodsList: [],
      orderGoodsId: [],
      refund_reason: "",
      content: "",
      refundId: "",

      shouhou: false,
      shouhous: false,
      shouhout: false,

      refundReason: "", //申请原因
      rejectReason: "", //反馈

      list: {
        pageSize: 10,
        pageNum: 1,
      },
      pagetotal: 0,
      refundLoading: false,
    };
  },
  created() {
    // this.getCityCode();
    // this.getCollectList()
  },
  methods: {
    deleteorder() {
      this.delorder = true;
    },
    isdefaultaddress() {
      this.isdefault = !this.isdefault;
    },

    // 删除图片
    handleRemove(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },

    //图片上传成功
    success(response, file, fileList) {
      this.fileLists = [];
      this.fileLists = [file];
      // this.form.logo = file.response.result;

      console.log(file, "11");
      console.log(fileList, "11");
      this.imgurl = fileList;
      console.log(this.imgurl);
      // this.getup()
      // let filename = []
      // this.imgurl.forEach((e) => {
      //   filename.push(
      //     '/uploadFiles/' + e.name
      //   )
      // })
      // console.log(filename);
      // this.filename = filename.join(",")

      // console.log(this.filename)

      // this.$api.post({
      //   path: '/multifileupload',
      //   params: {
      //     filename: this.filename
      //   },
      //   success: (data) => {
      //     console.log(data)
      //   }
      // })
    },
    fileDel(index) {
      this.imgurl.splice(index, 1);
    },
    // 上传展示图
    uploadImgs(file) {
      if (this.imgurl.length <= 9) {
        console.log(file.file, "file");
        this.$uploadFiles(
          "http://47.111.70.233:8762/mfxapi/multifileupload",
          file.file
        ).then((res) => {
          res.data.forEach((e) => {
            this.imgurl.push({
              src: e.url,
            });
          });

          console.log(this.imgurl);
          console.log(res.data, "res2");
          this.fileLists.push({
            path: res.data.url,
          });
        });
      } else {
        this.$message.error("不能超过九张哦！");
      }
    },

    handleAvatarSuccess(res, file) {
      console.log(res);
      console.log(file);
      this.imageUrl = URL.createObjectURL(file.raw);
      console.log(this.imageUrl);
    },
    //验证图片格式
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    /*====================订单列表=========================*/
    getOrderList() {
      this.$api.post({
        path: "/myorder/getOrderList",
        params: {
          uid: this.uid,
          order_type: 2,
          status: this.order.status,
          pageSize: 10,
          pageNum: this.list.pageNum,
        },
        success: (data) => {
          console.log(data);
          if (data.data.list.length == 0) {
            this.noData = "暂无数据";
            this.orderList = data.data.list;
            this.ordertotal = 0;
            this.pageCount = 1;
          } else {
            this.orderList = data.data.list;
            this.pagetotal = data.data.total;
            console.log(data);
            // this.orderList.forEach((e) => {
            //   this.orderGoodsList = e.orderGoodsList
            // })
            // this.orderGoodsList = data.data.list.orderGoodsList

            // this.orderList.forEach((item) => {
            //   let arr = item.orderGoodsList
            //   let newArr = []

            //   if (arr.length > 6) {
            //     newArr = arr
            //     newArr.length = 5

            //     item.orderGoodList = arr
            //   } else {
            //     item.orderGoodList = arr
            //   }
            // })
          }
        },
      });
    },

    goFirst() {
      this.list.pageNum = 1;
      this.getOrderList();
    },
    goLast() {
      this.list.pageNum = Math.ceil(this.pagetotal / 10);
      this.getOrderList();
    },

    handlePageChange(val) {
      this.list.pageNum = val;
      this.getOrderList();
    },
    handleSizeChange(val) {
      this.list.pageSize = val;
      this.getOrderList();
    },

    getType(v) {
      this.order.status = v;
      this.order.page = 1;
      console.log(v);
      this.getOrderList();
    },
    handleCurrentChange(val) {
      this.order.page = val;
      this.getOrderList();
    },
    getOrderid(oid) {
      this.$router.push(`/waiting/${oid}`);
    },

    getdelorder(v) {
      this.oid = v;
      this.getOrderDetails();
    },

    pay(oid, actualtotal, isEditPrice, changePrice) {
      if (isEditPrice == 0) {
        this.$router.push(`/payment/${oid}/${actualtotal}`);
      } else {
        this.$router.push(`/payment/${oid}/${changePrice}`);
      }
    },

    // 删除订单
    getdelete() {
      this.$api.post({
        path: "/myorder/operationOrder",
        params: {
          type: 2,
          oid: this.oid,
        },
        success: (data) => {
          console.log(data);
          this.delorder = false;
          this.getOrderList();
        },
      });
    },

    // 取消订单
    getcancel() {
      this.$api.post({
        path: "/myorder/operationOrder",
        params: {
          type: 3,
          oid: this.oid,
        },
        success: (data) => {
          console.log(data);
          this.cancel = false;
          this.getOrderList();
        },
      });
      // this.getOrderList()
    },

    // 确认收货
    getconfirm() {
      this.$api.post({
        path: "/myorder/operationOrder",
        params: {
          type: 1,
          oid: this.oid,
        },
        success: (data) => {
          console.log(data);
          this.receiving = false;
          this.getOrderList();
        },
      });
    },

    // 商品详情
    getOrderDetails() {
      this.$api.post({
        path: "/myorder/getOrderDetails",
        params: {
          oid: this.oid,
        },
        success: (data) => {
          console.log(data, "data1");
          this.orderDetails = data.data;
          this.orderGoodsList = data.data.orderGoodsList;
          this.orderAddress = data.data.orderAddress;
          this.type = data.data.status;
          console.log(data.data.status, data.data, "data2");
          this.orderGoodsList.forEach((e) => {
            this.orderGoodsId.push(e.orderGoodsId);
          });

          // console.log(this.status1, '状态')
        },
      });
    },

    // 售后退款
    getshouh(val, isEditPrice) {
      if (isEditPrice == 1) {
        this.$message({
          message: "改价订单不允许退款",
          type: "warning",
        });
      } else {
        this.refund = true;
        this.getdelorder(val);
      }
    },
    applyRefund() {
      // console.log(v)
      // this.refundLoading = true
      this.$api.post({
        path: "/myorder/applyRefund",
        params: {
          oid: this.oid,
          order_goods_ids: this.orderGoodsId.join(","),
          refund_reason: this.refund_reason,
          orderRefundImgList: this.imgurl,
        },
        success: (data) => {
          console.log(data);
          // this.refundLoading = false
          this.getOrderList();
        },
      });
    },

    addEvaluation() {
      this.$api.post({
        path: "/myorder/addEvaluation",
        params: {
          oid: this.oid,
          content: this.content,
          lstEvalImg: this.imgurl,
          uid: this.uid,
        },
        success: (data) => {
          console.log(data);
          if (data.code == 1) {
            this.$message({
              message: data.msg,
              type: "success",
            });
            this.pinglun = false;
            this.getOrderList();
            (this.content = ""), (this.imgurl = []);
          } else {
            this.$message({
              message: data.msg,
              type: "warning",
            });
          }
        },
      });
    },

    getrefundId(v) {
      this.refundId = v;

      this.getRefundDetails();
    },

    // 退款详情
    getRefundDetails() {
      this.$api.post({
        path: "/myorder/getRefundDetails",
        params: {
          refundId: this.refundId,
        },
        success: (data) => {
          console.log(data);
          this.refundReason = data.data.refundReason;
          this.rejectReason = data.data.rejectReason;
        },
      });
    },
  },

  mounted() {
    this.getOrderList();
  },
};
</script>

<style>
/* ===================我的顶单===================== */
.Pbox {
  width: 100%;
  margin: 130px auto;
}

.pmain {
  width: 100%;
  display: flex;
}

.pmainL {
  width: 180px;
  margin-right: 10px;
}

.el-breadcrumb__item:last-child .el-breadcrumb__inner {
  color: #2f69d2;
}

.pmainL ul {
  width: 180px;
}

.pmainL li {
  list-style: none;
  height: 48px;
  width: 65px;
  padding: 0px 57px;
  font-size: 16px;
  font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
  /* font-weight: bold; */
  text-align: left;
  color: #1a1a1a;
  line-height: 48px;
}

.liactive {
  background-color: #2f69d2;
  font-weight: bold;
  color: #ffffff;
}

.myorderbox {
  width: 1010px;
}

.myorder {
  width: 1010px;
}

.order-type {
  width: 100%;
  height: 48px;
  border: 1px solid #ccc;
  background-color: #f6f6f6;
  box-sizing: border-box;
  padding: 15px 0;
  display: flex;
  margin-bottom: 10px;
}

.order-type-item {
  width: 87px;
  height: 19px;
  font-size: 16px;
  color: #1a1a1a;
  border-right: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
  line-height: 19px;
  font-weight: 500;
  /* margin-bottom: 15px; */
}

.order-type-item:last-child {
  border-right: 0px;
}

.active {
  color: #2f69d2;
  /* height: 19px; */
}

.orderList {
  width: 100%;
}

.orderList {
  /* width: 1480px; */
  border: 1px solid rgba(204, 204, 204, 0.3);
  /* margin-top: 10px; */
  border-spacing: 0;
  margin-bottom: 28px;
  /*去掉单元格间隙*/
}

.orderList th {
  font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
  font-weight: 500;
  text-align: center;
  color: #1a1a1a;
  border-bottom: 1px solid rgba(204, 204, 204, 0.3);
}

.orderList td {
  border-right: 1px solid rgba(204, 204, 204, 0.3);
  border-bottom: 1px solid rgba(204, 204, 204, 0.3);
  font-size: 14px;
  text-align: center;
}

.goodslogo {
  width: 180px;
  height: 70px;
  margin-top: 5px;
  margin-right: 35px;
  position: relative;
  display: flex;
  flex-direction: columnl;
  overflow: hidden;
  /* justify-content: flex-start; */
  /* padding: 0 10rpx; */
  /* box-sizing: border-box; */
}

.goodslogo div {
  width: 70px;
  height: 70px;
  background-color: thistle;
  /* border-radius: 50px; */
  /* border: 1px solid black; */

  box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.3);
}

.goodslogo div:nth-child(1) {
  /* transform:translateX(-20px); */
  z-index: 10;
}

.goodslogo div:nth-child(2) {
  transform: translateX(-50px);
  z-index: 9;
}

.goodslogo div:nth-child(3) {
  transform: translateX(-100px);
  z-index: 8;
}

.goodslogo div:nth-child(4) {
  transform: translateX(-150px);
  z-index: 7;
}

.goodslogo div:nth-child(5) {
  transform: translateX(-200px);
  z-index: 6;
}

.goodslogo div:nth-child(6) {
  transform: translateX(-250px);
  z-index: 5;
}

.goodslogo div img {
  width: 70px;
  height: 70px;
  /* border-radius: 50px; */
  display: flex;
}

.orderpage {
  width: 700px;
  height: 40px;
  /* margin: 0 auto; */
  /* background-color: thistle; */
  display: flex;
}

.home-orderpage {
  width: 60px;
  height: 36px;
  background: white;
  border: 1px solid #cccccc;
  font-size: 14px;
  font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
  font-weight: 400;
  text-align: center;
  color: #1a1a1a;
  line-height: 36px;
  margin-right: 10px;
}

.last-orderpage {
  width: 60px;
  height: 36px;
  background: white;
  border: 1px solid #cccccc;
  font-size: 14px;
  font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
  font-weight: 400;
  text-align: center;
  color: #1a1a1a;
  line-height: 36px;
  margin-left: 10px;
}

/* 删除订单弹框 */
.delbox {
  width: 300px;
  height: 130px;
  background: #ffffff;
  border-radius: 10px;
  padding-top: 30px;
  margin: 100px auto;
}

.deltext {
  width: 100%;
  font-size: 16px;
  font-family: Source Han Sans CN Medium, Source Han Sans CN Medium-Medium;
  font-weight: bold;
  text-align: center;
  color: #1a1a1a;
  margin-bottom: 62px;
}

.delbtn {
  width: 300px;
  height: 48px;
}

.delbtn span {
  display: inline-block;
  width: 50%;
  height: 100%;
  font-size: 16px;
  text-align: center;
  line-height: 48px;
}

.delbtn span:first-child {
  color: #999999;
  border-radius: 0px 0px 0px 10px;
  background-color: #f2f2f2;
}

.delbtn span:last-child {
  color: white;
  background: #2f69d2;
  border-radius: 0px 0px 10px 0px;
}

/* 售后退款 */
.refund {
  width: 535px;
  /* height: 320px; */
  padding: 30px 15px 25px 15px;
  background: #ffffff;
  border-radius: 10px;
  margin: 100px auto;
  position: relative;
}

.refundclose {
  width: 34px;
  height: 34px;
  background-image: url(../../assets/close.png);
  background-size: 100%;
  position: absolute;
  top: -55px;
  right: 0px;
}

.refundbox .el-dialog__headerbtn {
  top: 114px;
  right: 275px;
  z-index: 999;
  color: white;
}

/* .refund input{
        width: 100%;
        height: 100px;
    } */
/* .refund textarea:-moz-placeholder {
            font-size:14px;
            font-family:PingFang SC;
            padding:10px;
            font-weight:300;
            color:rgba(255,255,255,0.4);
        } */

.refundbox .el-upload-dragger {
  width: 64px;
  height: 64px;
  border: 1px solid #cccccc;
  line-height: 64px;
  margin-bottom: 40px;
}

.refundbtn {
  width: 60px;
  height: 30px;
  background: #2f69d2;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC Medium, PingFang SC Medium-Medium;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  line-height: 30px;
}

.el-upload--picture-card {
  width: 64px;
  height: 64px;
  line-height: 64px;
}

.el-upload--picture-card i {
  font-size: 20px;
}

.el-upload-list--picture-card .el-upload-list__item {
  width: 64px;
  height: 64px;
}

.img_box {
  width: 100%;
  padding: 0 2%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.img_size {
  position: relative;
  width: 64px;
  height: 64px;
  padding: 5px;
  margin-right: 10px;
}

.img_size img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.remove_logo {
  position: absolute;
  width: 15px;
  height: 15px;
  background: red;
  border-radius: 25px;
  top: 5px;
  right: 5px;
  text-align: center;
  line-height: 15px;
  font-size: 12px;
  color: white;
}

.add_img {
  width: 64px;
  height: 64px;
  margin-top: 5px;
  text-align: center;
  line-height: 64px;
  font-size: 30px;
  color: #777777;
  border: 1px solid #cccccc;
  border-radius: 5px;
}

.ttable {
  margin: 0 auto;
  /* width: 1480px; */
  border: 1px solid rgba(204, 204, 204, 0.3);
  /* margin-top: 10px; */
  border-spacing: 0;
  margin-bottom: 28px;
  /*去掉单元格间隙*/
  margin-bottom: 10px;
}

.ttable th {
  font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
  font-weight: 500;
  text-align: center;
  color: #1a1a1a;
  border-bottom: 1px solid rgba(204, 204, 204, 0.3);
}

.ttable td {
  border-right: 1px solid rgba(204, 204, 204, 0.3);
  border-bottom: 1px solid rgba(204, 204, 204, 0.3);
  font-size: 14px;
  text-align: center;
}
</style>