<template>
    <div class="adv" v-html="html">

    </div>
</template>

<script>
export default {
    data() {
        return {
            html:"",
            
        }
    },
    methods: {
        getdata(){
            this.$api.post({
                path:"/goods/advHtmlContent",
                params:{
                    advId:this.$route.params.id
                },
                success:(data) =>{
                    console.log(data);
                    this.html = data.data.advContent
                }
            })
        }
    },
    created() {
        console.log(this.$route.params.id)
        this.getdata()
    },
}
</script>

<style>
    .adv{
        width: 1200px;
        margin: 140px auto;
        text-align: left;
    }
</style>