import Vue from 'vue'
import App from './App.vue'

// import VueRouter from 'vue-router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// Vue.use(VueRouter)
Vue.use(ElementUI);

import router from "./router"
import api from './utils/http.js'

// import stote from './store.js'
let axios = require("axios");


Vue.config.productionTip = false
Vue.prototype.$api = api


// axios.defaults.headers['token'] = stote.state.token;
// 路由守卫监听 每次跳转回到顶部
router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
})


// 文件上传
Vue.prototype.$uploadFiles = function (url,file) {
  let formData = new FormData()
  formData.append('filename', file)
  return new Promise(function (resolve, reject) {
      axios.post(url, formData, {
          headers: {
              'Content-Type': 'multipart/form-data',
          },
          transformRequest: []
      }).then(res => {
          resolve(res.data);
      }).catch(err => {
        reject(err);
      });
  })
}

new Vue({
  render: h => h(App),
  router,
  // stote
}).$mount('#app')
